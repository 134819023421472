import { Flex, type FlexProps, Typography } from 'antd';

import styled from '@emotion/styled';

type ItemProps = {
  isSelected: boolean;
};

export const Item = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<FlexProps & ItemProps>`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.bgDarkGrey : theme.colors.white};
  border-radius: ${({ isSelected }) => `${isSelected ? 8 : 0}px`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  cursor: pointer;
  padding: 20px 16px;
`;

export const Title = styled(Typography.Paragraph)`
  && {
    font-weight: 600;
    flex: 1;
    margin-bottom: 0;
  }
`;
