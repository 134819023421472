import type { UploadProps } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, PropsWithChildren } from 'react';

import { useDocumentController_create } from '@api-client/generated/DocumentController/create';
import { Schemas } from '@api-client/generated/types';
import {
  PAYMENT_DOCUMENT_VALID_EXTENSIONS,
  PaymentDocumentUnit,
} from '@constants';
import { useAccount } from '@hooks';
import { GTMEventName, sendGTMEvent } from '@utils';
import { checkDocumentFormatBeforeUpload } from '@utils/checkDocumentFormatBeforeUpload';

import * as S from './styled';

type PaymentDocumentImportInvoiceProps = {
  documentType: PaymentDocumentUnit;
  onBeforeUpdate?: () => void;
  onUpdate?: (file: Schemas.Document) => void;
} & UploadProps;

const PaymentDocumentImportInvoice: FC<
  PropsWithChildren<PaymentDocumentImportInvoiceProps>
> = ({ documentType, children, onBeforeUpdate, onUpdate, ...props }) => {
  const { companyId } = useAccount();

  const { mutateAsync: uploadDocument } = useDocumentController_create();

  const handleImportInvoice = async (options: UploadRequestOption) => {
    const formData = new FormData();
    onBeforeUpdate?.();

    formData.append('file', options.file);
    if (typeof options.file === 'object' && 'name' in options.file) {
      formData.append('name', options.file.name);
    }
    formData.append(
      'type',
      documentType === 'income' ? 'income_document' : 'expence_document'
    );

    const response = await uploadDocument({
      parameter: {
        companyId: companyId!,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      requestBody: formData as any,
    });

    if (documentType === 'expenses') {
      sendGTMEvent(GTMEventName.UploadedExpence);
    }

    onUpdate?.(response);
  };

  return (
    <S.Upload
      accept={PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(',')}
      beforeUpload={(file) =>
        checkDocumentFormatBeforeUpload(file, documentType)
      }
      customRequest={handleImportInvoice}
      showUploadList={false}
      multiple
      {...props}
    >
      {children}
    </S.Upload>
  );
};

export default PaymentDocumentImportInvoice;
