import { FC } from 'react';
import { useDrop } from 'react-dnd';

import { Schemas } from '@api-client/generated/types';
import { categoriesGroups } from '@constants';
import { useAccount, useTranslate } from '@hooks';
import { useUpdateCategoryGroupById } from '@hooks-api';
import { GTMEventName, sendGTMEvent } from '@utils';

import * as S from './styled';

type MainCategoryBlockEmptyItemProps = {
  variant: 'in' | 'out';
  title: string;
  group: 'operational' | 'financial' | 'investments';
  refetchCategories?: () => void;
  openMessage: (type: 'success' | 'error', content: string) => void;
};

const MainCategoryBlockEmptyItem: FC<MainCategoryBlockEmptyItemProps> = ({
  variant,
  title,
  group,
  refetchCategories,
  openMessage,
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();
  const { mutate: updateCategoryGroup } = useUpdateCategoryGroupById();

  const [, dropRef] = useDrop<Schemas.CategoryGroup>({
    accept: categoriesGroups,
    drop: (data) => {
      if (data) {
        updateCategoryGroup(
          {
            parameter: {
              id: data.id,
              companyId: companyId!,
            },
            requestBody: {
              flowType: variant === 'in' ? 'money_in' : 'money_out',
              group,
            },
          },
          {
            onSuccess: () => {
              sendGTMEvent(GTMEventName.MadeChangesInCategories);

              refetchCategories?.();
              openMessage(
                'success',
                `${translate('categoriesPage.actions.categoryGroup')} ${title} ${translate('categoriesPage.actions.updated')}`
              );
            },
            // TODO: translate error message
            onError: (err) => {
              const errorMessage = err.response?.data.message[0];

              if (errorMessage) {
                openMessage('error', errorMessage);
              }
            },
          }
        );
      }
    },
  });

  return (
    <S.EmptyContainer ref={dropRef}>
      <S.EmptyText isempty={true}>
        {translate('categoriesPage.empty')}
      </S.EmptyText>
    </S.EmptyContainer>
  );
};

export default MainCategoryBlockEmptyItem;
