import { Button, type TableColumnType, Tooltip } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useMemo } from 'react';

import { useTransactionController_findAll } from '@api-client/generated/TransactionController/findAll';
import { Schemas } from '@api-client/generated/types';
import { IconMissedFile } from '@assets';
import { Amount, DateText } from '@entities';
import { useAccount } from '@hooks';
import { colors } from '@theme';

import { ContactCell } from './ContactCell';
import * as S from './styled';

type Transaction = Schemas.Transaction;
type TransactionContollerParams = Parameters<
  typeof useTransactionController_findAll
>[0]['params'];
type SortableFields = Array<NonNullable<TransactionContollerParams['sortBy']>>;

type Params = {
  assignContact: (transactionId: string, contact: Schemas.Contact) => void;
  onRulesSubmit: () => void;
}

export function useTransactionsTable(params: Params) {
  const { userAccess } = useAccount();

  const sortableColumns = useMemo(
    () =>
      [
        'amount',
        'contact',
        'category',
        'date',
        'plDate',
      ] as const satisfies SortableFields,
    []
  );

  const columns = useMemo(
    () =>
      [
        userAccess?.incomesExpences
          ? {
              key: 'file',
              title: '',
              width: 40,
              align: 'center',
              render: (row: Transaction) =>
                !row.documents?.length &&
                !row.isFileless && (
                  <Tooltip
                    title={t('transactionsPage.missedPaymentDocument')()}
                    placement="right"
                  >
                    <Button
                      icon={<IconMissedFile color={colors.error} />}
                      type="text"
                      size="small"
                    />
                  </Tooltip>
                ),
              onHeaderCell: () => ({
                style: {
                  paddingInline: 0,
                },
              }),
              onCell: () => ({
                style: {
                  paddingInline: 0,
                },
              }),
            }
          : {},

        {
          key: 'amount',
          dataIndex: 'amount',
          title: t('transactionsPage.label.amount')(),
          align: 'right',
          width: 130,
          render: (amount, record) => (
            <Amount
              amount={amount}
              currencyCode={record.currencyCode}
              withColors
            />
          ),
        },
        {
          key: 'contact',
          dataIndex: 'contact',
          title: t('transactionsPage.label.contact')(),
          width: 150,
          ellipsis: true,
          render: (contact, record) => ({
            children: (
              <ContactCell
                contact={contact}
                rawContactName={record.rawContactName}
                assignContact={(contact) =>
                  params.assignContact(record.id, contact)
                }
                onRulesSubmit={params.onRulesSubmit}
              />
            ),
          }),
        },
        {
          key: 'details',
          dataIndex: 'details',
          title: t('transactionsPage.label.details')(),
          ellipsis: true,
        },
        {
          key: 'category',
          dataIndex: 'category',
          title: t('transactionsPage.label.category')(),
          width: 200,
          render: (category) => category?.name,
          ellipsis: true,
        },
        {
          key: 'account',
          dataIndex: 'account',
          title: t('transactionsPage.label.accountName')(),
          render: (account: Schemas.Account) =>
            account?.connection?.bank?.name || account?.accountName,
          width: 150,
          ellipsis: true,
        },
        {
          key: 'date',
          dataIndex: 'bookingDate',
          title: t('transactionsPage.label.date')(),
          width: 110,
          align: 'right',
          render: (date) => <DateText date={date} format="date" />,
        },
        {
          key: 'plDate',
          dataIndex: 'plDate',
          title: t('transactionsPage.label.plDate')(),
          width: 110,
          align: 'right',
          render: (date, record) =>
            record.isIgnoredForPL ? (
              <S.ExcludedTransaction>
                {t('transactionsPage.excluded')()}
              </S.ExcludedTransaction>
            ) : (
              date && dayjs(date).format('MMM YYYY')
            ),
        },
      ] as const satisfies TableColumnType<Transaction>[],
    [] // eslint-disable-line
  );

  return {
    columns,
    sortableColumns,
  };
}