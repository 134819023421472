import { Flex } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import {
  DEFAULT_CURRENCY_CODE,
  VAT_DEFAULT_PERCENT,
  VAT_MIDDLE_PERCENT,
} from '@constants';
import { Amount } from '@entities';

import * as S from './styled';

type InvoiceDocumentTotalProps = {
  currency: Schemas.DocumentMetadata['currency'];
  total: number;
  subTotal: number;
  defaultTaxRate: number;
  middleTaxRate: number;
};

const InvoiceDocumentTotal: FC<InvoiceDocumentTotalProps> = ({
  currency,
  total,
  subTotal,
  defaultTaxRate,
  middleTaxRate,
}) => (
  <S.Container justify="flex-end">
    <S.Total gap={12} vertical>
      <Flex align="center" justify="space-between">
        <S.SecondaryLabel>
          {t('invoiceGenerator.document.total.subtotal')()}:
        </S.SecondaryLabel>

        <Amount
          currencyCode={currency || DEFAULT_CURRENCY_CODE}
          amount={subTotal}
        />
      </Flex>

      {middleTaxRate ? (
        <Flex align="center" justify="space-between">
          <S.SecondaryLabel>
            {t('invoiceGenerator.document.total.vat')()} {VAT_MIDDLE_PERCENT}%:
          </S.SecondaryLabel>

          <Amount
            currencyCode={currency || DEFAULT_CURRENCY_CODE}
            amount={middleTaxRate}
          />
        </Flex>
      ) : null}

      {defaultTaxRate ? (
        <Flex align="center" justify="space-between">
          <S.SecondaryLabel>
            {t('invoiceGenerator.document.total.vat')()} {VAT_DEFAULT_PERCENT}%:
          </S.SecondaryLabel>

          <Amount
            currencyCode={currency || DEFAULT_CURRENCY_CODE}
            amount={defaultTaxRate}
          />
        </Flex>
      ) : null}

      <Flex align="center" justify="space-between">
        <S.PrimaryLabel>
          {t('invoiceGenerator.document.total.total')()}:
        </S.PrimaryLabel>

        <S.PrimaryValue>
          <Amount
            currencyCode={currency || DEFAULT_CURRENCY_CODE}
            amount={total}
          />
        </S.PrimaryValue>
      </Flex>
    </S.Total>
  </S.Container>
);

export default InvoiceDocumentTotal;
