import { Button, Typography } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PageMeta,
  TransactionIngestAlertUploaded,
  TransactionsTable,
} from '@entities';
import TransactionsTableFilterForm from '@entities/transactions/TransactionsTable/TransactionsTableFilterForm';
import * as transactionsTableSchema from '@entities/transactions/TransactionsTable/schema';
import { useAccount, useAppState } from '@hooks';
import { useQueryParams } from '@hooks/useQueryParams';
import { getDefaultValues } from '@utils/zod';

const Transactions = () => {
  const navigate = useNavigate();
  const { userAccess } = useAccount();

  const { queryParams, setQueryParams } = useQueryParams({
    schema: transactionsTableSchema.queryParamsSchema,
    defaultValues: getDefaultValues(transactionsTableSchema.queryParamsSchema),
  });

  const { isVisibleContentAfterImport, updateVisibilityContentAfterImport } =
    useAppState();

  const handleShowAllImports = () => {
    updateVisibilityContentAfterImport(false);
    navigate('/transactions');
  };

  useEffect(
    () => () => {
      updateVisibilityContentAfterImport(false);
    },
    [updateVisibilityContentAfterImport]
  );

  useEffect(() => {
    if (userAccess && !userAccess?.transactions) {
      navigate('/documents');
    }
  }, [navigate, userAccess]);

  return (
    <>
      <PageMeta title={t('transactionsPage.title')()} />

      {isVisibleContentAfterImport && (
        <TransactionIngestAlertUploaded
          onManageImports={() => navigate(`/csv-imports`)}
          onShowAll={handleShowAllImports}
        />
      )}

      <Typography.Title>{t('transactionsPage.title')()}</Typography.Title>

      <TransactionsTable
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        filterForm={({onFormUpdate, total}) => (
          <TransactionsTableFilterForm
            queryParams={queryParams}
            onFormUpdate={onFormUpdate}
            total={total}
            right={
              <Button
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => navigate('/accounts')}
              >{t('transactionsPage.button.importCsv')()}</Button>
            }
          />
        )}
      />
    </>
  );
};

export default Transactions;