import { Flex, type FlexProps, Typography } from 'antd';

import styled from '@emotion/styled';

export const Edit = styled.a`
  opacity: 0;
  font-weight: 600;
  margin-top: 8px;
  z-index: 1;
`;

export const Details = styled(Flex, {
  shouldForwardProp: (prop) => prop !== 'isBordered',
})<FlexProps & { isBordered: boolean }>`
  position: relative;

  &:hover {
    &:after {
      content: '';
      width: calc(100% + 32px);
      height: calc(100% + 32px);
      border: 1px dashed ${({ theme }) => theme.colors.text400};
      border-radius: 10px;
      display: ${({ isBordered }) => (isBordered ? 'block' : 'none')};
      position: absolute;
      top: -16px;
      left: -16px;
    }

    ${Edit} {
      opacity: 1;
    }
  }
`;

export const DetailsTitle = styled(Typography.Title)`
  && {
    font-size: 20px;
    margin-bottom: 0;
  }
`;

export const DetailsType = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Add = styled.a`
  display: inline-flex;
  align-items: center;
  font-weight: 600;
`;
