import { Flex } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import { ButtonDashed } from '@components';

import * as S from './styled';

type UnitModalMode = 'add' | 'edit';

type ModalAction = {
  isOpen: boolean;
  mode: UnitModalMode;
};

type InvoiceDocumentFooterProps = {
  bankData: Schemas.BankData;
  contactPerson: Schemas.ContactPerson;
  onOpenModalBank: (params: ModalAction) => void;
  onOpenModalContact: (params: ModalAction) => void;
};

const InvoiceDocumentFooter: FC<InvoiceDocumentFooterProps> = ({
  bankData,
  contactPerson,
  onOpenModalBank,
  onOpenModalContact,
}) => (
  <Flex align="center" justify="space-between" gap={48}>
    {bankData?.iban || bankData?.bic ? (
      <S.Details
        gap={4}
        onClick={() =>
          onOpenModalBank({
            mode: 'edit',
            isOpen: true,
          })
        }
        vertical
      >
        <S.BankText>
          {t('invoiceGenerator.document.iban')()} {bankData?.iban}
        </S.BankText>

        <S.BankText>
          {t('invoiceGenerator.document.bic')()} {bankData?.bic}
        </S.BankText>
      </S.Details>
    ) : (
      <ButtonDashed
        icon={<IconPlus width={20} height={20} />}
        height={70}
        onClick={() =>
          onOpenModalBank({
            mode: 'add',
            isOpen: true,
          })
        }
      >
        {t('invoiceGenerator.document.buttonAddBank')()}
      </ButtonDashed>
    )}

    {contactPerson?.email || contactPerson?.phone ? (
      <S.Details
        align="flex-end"
        gap={4}
        onClick={() =>
          onOpenModalContact({
            mode: 'edit',
            isOpen: true,
          })
        }
        vertical
      >
        <S.ContactText>{contactPerson?.email}</S.ContactText>
        <S.ContactText>{contactPerson?.phone}</S.ContactText>
      </S.Details>
    ) : (
      <ButtonDashed
        icon={<IconPlus width={20} height={20} />}
        height={70}
        onClick={() =>
          onOpenModalContact({
            mode: 'add',
            isOpen: true,
          })
        }
      >
        {t('invoiceGenerator.document.buttonAddContact')()}
      </ButtonDashed>
    )}
  </Flex>
);

export default InvoiceDocumentFooter;
