import { Button, type ButtonProps, Flex, type FlexProps } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

export const Container = styled(Flex)<FlexProps>`
  width: 100%;
  height: calc(100vh - 172px);
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  flex: 1;
  padding: 30px;

  .ui-tabs-nav {
    &:before {
      border-color: ${({ theme }) => theme.colors.textDisabled};
    }
  }
`;

export const ButtonEdit = styled(Button)<ButtonProps>`
  && {
    color: ${({ theme }) => theme.colors.text400};
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.colors.text400};
`;

export const Count = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>`
  min-width: 30px;
  height: 28px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.colors.bgDarkGrey};
  border-radius: 28px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.textBlue400};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  padding: 0 8px;
`;
