import { saveAs } from 'file-saver';

import { STORAGE_TOKEN_KEY } from '@constants';

type FileBlob = {
  method: string;
  companyId: string;
  name?: string;
};

export const downloadFile = (
  fileLink: string,
  downloadFileName: string,
  isTarget: boolean = false
) => {
  const link = document.createElement('a');

  link.href = fileLink;
  link.download = downloadFileName;

  if (isTarget) {
    link.target = '_blank';
  }

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const downloadFileBlob = async ({
  method,
  companyId,
  name = '',
}: FileBlob) => {
  const baseUrl = import.meta.env.VITE_ROUTE_API_BASE_URL;
  const tokenJWT = localStorage.getItem(STORAGE_TOKEN_KEY);

  if (baseUrl && tokenJWT) {
    const response = await fetch(`${baseUrl}/api/${method}`, {
      headers: {
        Authorization: `Bearer ${tokenJWT}`,
        companyId,
      },
    });

    const blob = await response.blob();

    saveAs(blob, name);
  }
};
