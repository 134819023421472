import { Modal as AntdModal, Flex, Typography } from 'antd';

import styled from '@emotion/styled';

const { Paragraph } = Typography;

export const Modal = styled(AntdModal)`
  & .ui-modal-body {
    margin-top: 50px;
    padding: 40px 20px 0px 20px;
  }

  & .ui-modal-title {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.bgGrey};
    border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
    z-index: 1;
    border-radius: 16px 16px 0 0;
  }

  & .ui-modal-close {
    color: ${({ theme }) => theme.colors.grey500};

    &:hover {
      color: ${({ theme }) => theme.colors.blue300};
      background: transparent;
    }
  }
`;

export const Divider = styled.div`
  height: 20px;
  border-left: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 0 10px 0 10px;
`;

export const Count = styled.div`
  width: 100px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.text400};
  font-size: 14px;
  font-weight: 600;
`;

export const LoaderContainer = styled(Flex)`
  height: 80vh;
  justify-content: center;
  align-items: center;
`;

export const CopyButton = styled(Paragraph)`
  && {
    margin-bottom: 0;
  }
`;
