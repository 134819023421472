import { useNavigate } from 'react-router-dom';

import { IconDownload, IconTrash } from '@assets';
import { Scrollbar } from '@components';
import { TransactionsTable } from '@entities';
import TransactionsTableFilterForm from '@entities/transactions/TransactionsTable/TransactionsTableFilterForm';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type TransactionIngestTransactionsProps = {
  ingestId: string;
  onDownload: () => void;
  onRemove: () => void;
};

function TransactionIngestTransactions(
  props: TransactionIngestTransactionsProps
) {
  const navigate = useNavigate();

  return (
    <Scrollbar height="calc(100vh - 385px)">
      <S.Container>
        <TransactionsTable
          hiddenColumns={['contact']}
          onAfterModalClose={() => navigate(`/csv-imports/${props.ingestId}`)}
          filterForm={({onFormUpdate, total}) => (
            <TransactionsTableFilterForm
              isDarkTheme
              hiddenFormFields={new Set(['contactIds'])}
              onFormUpdate={onFormUpdate}
              total={total}
              right={
                <>
                  <Button
                    color="primary"
                    variant="filled"
                    size="small"
                    icon={<IconDownload />}
                    onClick={props.onDownload}
                  />
                  <Button
                    color="primary"
                    variant="filled"
                    size="small"
                    icon={<IconTrash />}
                    onClick={props.onRemove}
                  />
                </>
              }
            />
          )}
        />
      </S.Container>
    </Scrollbar>
  );
}

export default TransactionIngestTransactions;
