import { createContext, useContext } from 'react';

import { AddRulesFlowContext } from './AddRulesFlowProvider';

export const AddRulesFlow = createContext<AddRulesFlowContext>({
  setStep: () => {},
  modalProps: {
    title: '',
  },
  setTransactionsState: () => {},
  setConfig: () => {},
});

export const useGetRules = (): AddRulesFlowContext => useContext(AddRulesFlow);
