import { List, type ListProps } from 'antd';
import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { downloadFile } from '@utils';

import PayslipsListItem from '../PayslipsListItem';

type PayslipDocument = Schemas.PayslipDocument;

type PayslipsListProps = {
  selectedId: string;
  dataSource: PayslipDocument[];
  isLoading: boolean;
} & ListProps<PayslipDocument>;

const PayslipsList: FC<PayslipsListProps> = ({
  selectedId,
  dataSource,
  isLoading,
  ...rest
}) => {
  const navigate = useNavigate();

  const handleSelect = (id: string) => {
    navigate(`/payslips/${id}`);
  };

  const handleDownload = (
    e: MouseEvent<HTMLButtonElement>,
    url: string,
    name: string
  ) => {
    e.stopPropagation();

    downloadFile(url, name, true);
  };

  return (
    <List
      dataSource={dataSource}
      renderItem={(item) => (
        <PayslipsListItem
          isSelected={selectedId === item.id}
          name={item.name}
          onClick={() => handleSelect(item.id)}
          onDownload={(e) => handleDownload(e, item.url, item.name)}
        />
      )}
      locale={{
        emptyText: <div />,
      }}
      loading={isLoading}
      {...rest}
    />
  );
};

export default PayslipsList;
