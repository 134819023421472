import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { Loader } from '@components';
import { FilePreview } from '@entities';

import * as S from './styled';

type PayslipsCardProps = {
  selectedDocument?: Schemas.PayslipDocument;
  isLoading: boolean;
};

const PayslipsCard: FC<PayslipsCardProps> = ({
  selectedDocument,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <S.Container justify="center" align="center">
        <Loader />
      </S.Container>
    );
  }

  return (
    <S.Container gap={24} vertical>
      <FilePreview
        file={selectedDocument}
        pageWidth={780}
        width="810px"
        height="calc(100vh - 234px)"
        withoutContainer
      />
    </S.Container>
  );
};

export default PayslipsCard;
