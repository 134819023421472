import { message } from 'antd';
import pdfMake from 'pdfmake/build/pdfmake';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { useAccount } from '@hooks';
import { Button } from '@ui-kit/Button/Button';

import InvoiceModalConfirmFinalize from '../../modals/InvoiceModalConfirmFinalize';
import { getDocumentDefinition } from './helpers';

type UpdateDocumentDto = Schemas.UpdateDocumentDto;

type InvoicePDFMakeProps = {
  document: Schemas.Document;
  company: Schemas.Company;
};

pdfMake.fonts = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

const InvoicePDFMake: FC<InvoicePDFMakeProps> = ({ company, document }) => {
  const { companyId, account } = useAccount();
  const navigate = useNavigate();

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const { mutate: updateDocument } = useDocumentController_updateOneById();

  const handleConfirmFinalize = () => {
    if (!document.documentMetadata?.number) {
      return message.error(t('invoiceGenerator.errorMessages.emptyNumber')());
    }

    if (
      !document.documentMetadata?.issueDate ||
      !document.documentMetadata?.dueDate ||
      !document.documentMetadata?.deliveryDate
    ) {
      return message.error(t('invoiceGenerator.errorMessages.emptyDates')());
    }

    if (!document?.contact) {
      return message.error(t('invoiceGenerator.errorMessages.emptyTo')());
    }

    if (!company.details?.vatId) {
      return message.error(t('invoiceGenerator.errorMessages.emptyFrom')());
    }

    if (!document.documentMetadata?.items?.length) {
      return message.error(t('invoiceGenerator.errorMessages.emptyItems')());
    }

    setIsOpenConfirm(true);
  };

  const handleFinalizeDocument = (blob: Blob) => {
    const requestParameter = {
      companyId: companyId!,
      id: document.id!,
    };
    const formData = new FormData();
    const documentName = document.name || t('invoiceGenerator.document.name')();

    formData.append('file', blob, `${documentName}.pdf`);
    formData.append('fileName', `${documentName}.pdf`);
    formData.append('name', documentName);

    updateDocument(
      {
        parameter: requestParameter,
        requestBody: formData as UpdateDocumentDto,
      },
      {
        onSuccess: () => {
          updateDocument(
            {
              parameter: requestParameter,
              requestBody: {
                ...document,
                documentMetadata: {
                  ...document.documentMetadata,
                  isReviewed: true,
                  isFinalized: true,
                },
              } as UpdateDocumentDto,
            },
            {
              onSuccess: () => {
                navigate(`/income/${document.id}`);
                setIsOpenConfirm(false);
              },
            }
          );
        },
      }
    );
  };

  const handleGeneratePdf = () => {
    const documentDefinition = getDocumentDefinition({
      locale: account?.locale,
      document,
      company,
    });

    pdfMake
      .createPdf(documentDefinition)
      .getBlob((blob) => handleFinalizeDocument(blob));
  };

  return (
    <>
      <InvoiceModalConfirmFinalize
        open={isOpenConfirm}
        onConfirm={handleGeneratePdf}
        onCancel={() => setIsOpenConfirm(false)}
      />

      <Button type="primary" onClick={handleConfirmFinalize} block>
        {t('invoiceGenerator.draft.buttonFinalize')()}
      </Button>
    </>
  );
};

export default InvoicePDFMake;
