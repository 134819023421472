import { Layout } from 'antd';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useUserController_getCurrentUser } from '@api-client/generated/UserController/getCurrentUser';
import styled from '@emotion/styled';
import { AddRulesFlowProvider } from '@entities/rules/AddRulesFlowProvider';
import { AddRulesFlowModal } from '@entities/rules/modal/AddRulesFlowModal';
import { useAccount } from '@hooks';
import { layout } from '@theme';
import { checkPublicRoute } from '@utils';

import { Header } from './Header/Header';
import { Sidebar } from './Sidebar/Sidebar';

const Container = styled(Layout)`
  min-height: 100vh;
  margin-left: ${`${layout.sidebarWidth}px`};
  padding-top: 72px;
`;

const Content = styled(Layout.Content, {
  shouldForwardProp: (prop) =>
    prop !== 'noContentPadding' && prop !== 'greyBackground',
})<{ noContentPadding?: boolean; greyBackground?: boolean }>`
  padding: ${({ noContentPadding }) => (noContentPadding ? 0 : '50px')};
  background-color: ${({ greyBackground, theme }) =>
    greyBackground ? theme.colors.grey100 : theme.colors.white};
`;

export const LayoutProtected = ({
  noContentPadding,
  greyBackground,
}: {
  noContentPadding?: boolean;
  greyBackground?: boolean;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isLogged, company, setAccount } = useAccount();

  const { data: user, isPending: loading } = useUserController_getCurrentUser();

  useEffect(() => {
    if (user) {
      setAccount(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!isLogged) {
      navigate('/login');
    } else {
      if (company?.status === 'incorporating' && !checkPublicRoute(pathname)) {
        navigate('/start');
      }
    }
  }, [company?.status, isLogged, navigate, pathname]);

  if (!user || loading) {
    return null;
  }

  return (
    <Layout>
      <AddRulesFlowProvider>
        <Header />

        <Container>
          <Sidebar />

          <Content
            noContentPadding={noContentPadding}
            greyBackground={greyBackground}
          >
            <Outlet />
          </Content>
        </Container>
        <AddRulesFlowModal />
      </AddRulesFlowProvider>
    </Layout>
  );
};
