import { Flex, Space, Tooltip } from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import {
  IconDownload,
  IconEditUnderline,
  IconLeft,
  IconRight,
  IconTrash,
} from '@assets';
import { CopyButton } from '@components';
import { PaymentDocumentUnit } from '@constants';
import { PaymentDocumentStatusBarDetails } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import { getDocumentStatus } from './helpers';
import * as S from './styled';

type Document = Schemas.Document;

type PaymentDocumentStatusBarProps = {
  type: PaymentDocumentUnit;
  errorCode: string;
  isRecognitionCompleted: boolean;
  isDraft: boolean;
  documentMetadata: Schemas.DocumentMetadata;
  adminStatus: Document['adminStatus'];
  potentialDuplicate: Document;
  totalRecords: number;
  currentListIndex: number;
  isLoading: boolean;
  onDownload: () => void;
  onDelete: () => void;
  onPrev: () => void;
  onNext: () => void;
};

const PaymentDocumentStatusBar: FC<PaymentDocumentStatusBarProps> = ({
  errorCode,
  type,
  isRecognitionCompleted,
  isDraft,
  documentMetadata,
  adminStatus,
  potentialDuplicate,
  totalRecords,
  currentListIndex,
  isLoading,
  onDownload,
  onDelete,
  onPrev,
  onNext,
}) => {
  const navigate = useNavigate();
  const { id: documentId } = useParams();

  const { status, variant } = getDocumentStatus({
    isDraft,
    isRecognitionCompleted,
    documentMetadata,
    adminStatus,
    potentialDuplicate,
  });

  return (
    <S.Container variant={variant} align="center" justify="space-between">
      <Space split={<S.Divider />} size={16}>
        <Flex align="center" gap={12}>
          <Flex align="center" gap={4}>
            <Button
              type="link"
              icon={<IconLeft />}
              size="middle"
              onClick={onPrev}
              disabled={isLoading || !currentListIndex || totalRecords === 1}
            />

            <Button
              type="link"
              icon={<IconRight />}
              size="middle"
              onClick={onNext}
              disabled={isLoading || currentListIndex + 1 === totalRecords}
            />
          </Flex>

          <S.Count>
            {t('common.countOfTotal', {
              count: currentListIndex + 1,
              total: totalRecords,
            })()}
          </S.Count>
        </Flex>

        <PaymentDocumentStatusBarDetails
          type={type}
          status={status}
          variant={variant}
          errorCode={errorCode}
        />
      </Space>

      {status !== 'isCorruptedFile' && status !== 'isWrongDocument' && (
        <Flex align="center" gap={16}>
          {status === 'isDraft' && (
            <Button
              type="link"
              icon={<IconEditUnderline />}
              size="middle"
              onClick={() => navigate(`/income/new/${documentId}`)}
            />
          )}

          <Button
            type="link"
            icon={<IconDownload />}
            size="middle"
            onClick={onDownload}
          />

          <CopyButton
            value={location.href}
            disabled={status === 'isProcessing'}
          />

          {status === 'isBooked' ? (
            <Tooltip
              title={
                type === 'income'
                  ? t('income.tooltipByDelete')()
                  : t('expenses.tooltipByDelete')()
              }
              placement="left"
            >
              <Button
                type="text"
                icon={<IconTrash />}
                size="small"
                disabled
                danger
              />
            </Tooltip>
          ) : (
            <Button
              type="link"
              icon={<IconTrash />}
              size="middle"
              onClick={onDelete}
              danger
            />
          )}
        </Flex>
      )}
    </S.Container>
  );
};

export default PaymentDocumentStatusBar;
