import { FC } from 'react';

import * as S from './styled';

type NotFoundComponent = {
  title: string;
  text: string;
};

const NotFoundComponent: FC<NotFoundComponent> = ({ text, title }) => (
  <S.Wrapper vertical wrap align="center" justify="center">
    <S.Title>{title}</S.Title>
    <S.Text>{text}</S.Text>
  </S.Wrapper>
);

export default NotFoundComponent;
