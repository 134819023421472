import { message } from 'antd';
import { FC, useState } from 'react';
import { useDrop } from 'react-dnd';

import { Schemas } from '@api-client/generated/types';
import { categoriesGroups } from '@constants';
import {
  MainCategoryActionInput,
  MainCategoryBlockListItem,
  MainCategoryBlockListSubItem,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import {
  useCreateCategory,
  useRemoveCategory,
  useUpdateCategory,
  useUpdateCategoryGroupById,
} from '@hooks-api';
import { GTMEventName, sendGTMEvent } from '@utils';

import {
  ActiveBlockType,
  ActiveGroupType,
} from '../../../pages/Categories/Categories';
import * as S from './styled';

type MainCategoryBlockItemType = {
  item: Schemas.CategoryGroup;
  variant: 'in' | 'out';
  isSubItemDragged: boolean;
  refetchCategories?: () => void;
  onEditGroup?: (value: string, item: Schemas.CategoryGroup) => void;
  onRemoveGroup?: (id: string) => void;
  handleDragSubItem?: (value: boolean) => void;
  setActiveGroup: (item: ActiveGroupType) => void;
  setActiveBlock: (item: ActiveBlockType) => void;
  activeGroup: ActiveGroupType;
};

const MainCategoryBlockItem: FC<MainCategoryBlockItemType> = ({
  item,
  variant,
  isSubItemDragged,
  refetchCategories,
  onEditGroup,
  onRemoveGroup,
  handleDragSubItem,
  setActiveGroup,
  setActiveBlock,
  activeGroup,
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const { mutate: updateCategoryGroup } = useUpdateCategoryGroupById();
  const { mutate: createCategory } = useCreateCategory();
  const { mutate: updateCategory } = useUpdateCategory();
  const { mutate: removeCategory } = useRemoveCategory();

  const [subItemAddOpen, setSubItemAddOpen] = useState(false);
  const [value, setValue] = useState('');
  const [action, setAction] = useState('create');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  const [messageApi, contextHolder] = message.useMessage();

  const [_, dropRef] = useDrop<Schemas.CategoryGroup>({
    accept: categoriesGroups,
    drop: function (data) {
      setActiveBlock(null);
      if (
        data?.group === item?.group &&
        data?.name === item?.name &&
        data?.flowType === item?.flowType
      )
        return;

      if (data?.group) {
        updateCategoryGroup(
          {
            parameter: {
              id: data?.id,
              companyId: companyId!,
            },
            requestBody: {
              flowType: variant === 'in' ? 'money_in' : 'money_out',
              group: item?.group,
            },
          },
          {
            onSuccess: () => {
              sendGTMEvent(GTMEventName.MadeChangesInCategories);

              refetchCategories?.();
              messageApi.open({
                type: 'success',
                content: `${translate('categoriesPage.actions.categoryGroup')} ${item.group} ${translate('categoriesPage.actions.updated')}`,
              });
            },
            onError: (err) => {
              messageApi.open({
                type: 'error',
                content: err.response?.data.message,
              });
            },
          }
        );
      } else {
        updateCategory(
          {
            parameter: {
              companyId: companyId!,
              id: data?.id,
            },
            requestBody: {
              name: data?.name,
              notes: '',
              group: {
                id: item.id,
              },
            },
          },
          {
            onSuccess: () => {
              refetchCategories?.();
              messageApi.open({
                type: 'success',
                content: `${translate('categoriesPage.actions.category')} ${data?.name} ${translate(
                  'categoriesPage.actions.updated'
                )}`,
              });
            },
            onError: (err) => {
              messageApi.open({
                type: 'error',
                content: err.response?.data.message,
              });
            },
          }
        );
      }
    },
  });

  const handleAddCategory = async () => {
    if (action === 'create') {
      await createCategory(
        {
          requestBody: {
            group: {
              id: item.id,
            },
            name: value,
            notes: '',
          },
          parameter: {
            companyId: companyId!,
          },
        },
        {
          onSuccess: () => {
            refetchCategories?.();
            messageApi.open({
              type: 'success',
              content: `${translate('categoriesPage.actions.category')} ${value} ${translate(
                'categoriesPage.actions.created'
              )}`,
            });
          },
          onError: (err) => {
            messageApi.open({
              type: 'error',
              content: err.response?.data.message,
            });
          },
        }
      );
    } else {
      await updateCategory(
        {
          parameter: {
            companyId: companyId!,
            id: selectedCategoryId,
          },
          requestBody: {
            name: value,
          },
        },
        {
          onSuccess: () => {
            refetchCategories?.();
            messageApi.open({
              type: 'success',
              content: `${translate('categoriesPage.actions.category')} ${value} ${translate(
                'categoriesPage.actions.updated'
              )}`,
            });
          },
          onError: (err) => {
            messageApi.open({
              type: 'error',
              content: err.response?.data.message,
            });
          },
        }
      );
    }
    setValue('');
    setSubItemAddOpen(false);
    setSelectedCategoryId('');
  };

  const handleClearValue = () => {
    setValue('');
    setSubItemAddOpen(false);
    setSelectedCategoryId('');
  };

  const handleEditCategory = (id: string, name: string) => {
    setAction('edit');
    setValue(name);
    setSelectedCategoryId(id);
    setSubItemAddOpen(true);
  };

  const handleRemoveCategory = async (id: string) => {
    await removeCategory(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
      },
      {
        onSuccess: (res) => {
          refetchCategories?.();
          messageApi.open({
            type: 'success',
            content: `${translate('categoriesPage.actions.category')} ${res?.name} ${translate(
              'categoriesPage.actions.removed'
            )}`,
          });
        },
        onError: (err) => {
          messageApi.open({
            type: 'error',
            content: err.response?.data.message,
          });
        },
      }
    );
  };

  const renderCategoriesList = () => {
    if (item?.categories?.length) {
      return item?.categories?.map((category) => {
        if (!category) return;

        if (action === 'edit' && selectedCategoryId === category.id) {
          return (
            <MainCategoryActionInput
              isSubItem={true}
              key={category.id}
              inputValue={value}
              setInputValue={setValue}
              handleSubmit={handleAddCategory}
              handleClose={handleClearValue}
            />
          );
        } else {
          return (
            <MainCategoryBlockListSubItem
              key={category.id}
              item={category}
              groupName={item.group}
              title={category.name}
              isEmpty={!category.name}
              onEditCategory={() =>
                handleEditCategory(category.id, category.name)
              }
              onRemoveCategory={() => handleRemoveCategory(category.id)}
              handleDragSubItem={handleDragSubItem}
            />
          );
        }
      });
    }

    return (
      <MainCategoryBlockListSubItem
        title={translate('categoriesPage.empty')}
        isEmpty
      />
    );
  };

  const currentActiveGroup = {
    group: item.group,
    name: item.name,
    flowType: item.flowType,
  };

  const boxShadowCondition =
    activeGroup?.group?.toLowerCase() === item?.group?.toLowerCase() &&
    activeGroup?.flowType === item?.flowType &&
    activeGroup?.name === item?.name &&
    isSubItemDragged;

  return (
    <div ref={dropRef}>
      <S.Wrapper
        onMouseMove={() => setActiveGroup(currentActiveGroup)}
        onMouseLeave={() => setActiveGroup(null)}
        onDragOver={() => setActiveGroup(currentActiveGroup)}
        onDragLeave={() => setActiveGroup(null)}
        style={{
          boxShadow: boxShadowCondition
            ? '0px 3px 8px 0px rgba(0, 0, 0, 0.30)'
            : 'none',
        }}
      >
        {contextHolder}
        <MainCategoryBlockListItem
          item={item}
          onEditGroup={onEditGroup}
          onRemoveGroup={onRemoveGroup}
          onAddSubItem={() => {
            setSubItemAddOpen(true);
            setAction('create');
          }}
        />
        <S.ListItemContent>
          {renderCategoriesList()}
          {subItemAddOpen && action === 'create' ? (
            <MainCategoryActionInput
              isSubItem={true}
              inputValue={value}
              setInputValue={setValue}
              handleSubmit={handleAddCategory}
              handleClose={handleClearValue}
            />
          ) : null}
        </S.ListItemContent>
      </S.Wrapper>
    </div>
  );
};

export default MainCategoryBlockItem;
