import { FlexProps } from 'antd';
import { FC, PropsWithChildren } from 'react';

import * as S from './styled';

export type PaymentDocumentInvoiceProps = {
  isSelected: boolean;
  isToReview?: boolean;
  isDraft?: boolean;
  onClick: () => void;
} & FlexProps;

const PaymentDocumentInvoice: FC<
  PropsWithChildren<PaymentDocumentInvoiceProps>
> = ({ isSelected, isToReview, onClick, children, ...rest }) => (
  <S.Card
    gap={4}
    isSelected={isSelected}
    isToReview={isToReview}
    onClick={onClick}
    justify="space-between"
    {...rest}
  >
    {children}
  </S.Card>
);

export default PaymentDocumentInvoice;
