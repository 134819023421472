import { Typography } from 'antd';
import { rgba } from 'emotion-rgba';

import styled from '@emotion/styled';

const { Title: TitleBase } = Typography;

type ContainerProps = {
  padding?: number;
  minHeight?: number;
  minHeightSuffix?: 'vh' | 'px' | '%';
  isGrey?: boolean;
  noShadow?: boolean;
  transparentBorder?: boolean;
};

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'transparentBorder',
})<ContainerProps>`
  background-color: ${({ theme, isGrey }) =>
    isGrey ? theme.colors.bgGrey : theme.colors.white};
  border-radius: 20px;
  ${({ noShadow, theme }) =>
    noShadow ? '' : `box-shadow: 0 3px 8px 0 ${rgba(theme.colors.dark, 0.1)};`}
  border: ${({ theme, transparentBorder }) =>
    transparentBorder ? 'inherit' : `1px solid ${theme.colors.strokeDarkGrey}`};
  padding: ${({ padding }) =>
    padding || padding === 0 ? `${padding}px` : '24px 32px'};
  min-height: ${({ minHeight, minHeightSuffix }) =>
    minHeight ? `${minHeight}${minHeightSuffix}` : 'unset'};
  width: 100%;
`;

export const Title = styled(TitleBase)`
  && {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

export const Description = styled.div`
  & span {
    color: ${({ theme }) => theme.colors.blue400};
  }
`;
