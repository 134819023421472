import { DropdownSelect } from '@components';
import { useSavedColumns } from '@hooks';

export function useTableColumnsSelect<T>(
  params: Parameters<typeof useSavedColumns<T>>[0]
) {
  const { columns, dropdownOptions, setShownColumns, shownColumns } =
    useSavedColumns<T>(params);

  // временное преобразование к формату дропдауна
  function handleShownTablesSelectChange(value: string[]) {
    setShownColumns(arrayOfTableKeysToObject(value));
  }

  const select = () => (
    <DropdownSelect
      options={dropdownOptions}
      onChange={handleShownTablesSelectChange}
      value={objectToArrayOfKeys(shownColumns)}
    />
  );

  return [select, columns] as const;
}

export function arrayOfTableKeysToObject(value: string[]) {
  return value.reduce(
    (acc, key) => ({
      ...acc,
      [key]: true,
    }),
    {}
  );
}

export function objectToArrayOfKeys(value: Record<string, boolean>) {
  return Object.entries(value)
    .filter(([_key, isShown]) => isShown)
    .map(([key, _isShown]) => key);
}
