import { Typography } from 'antd';

import styled from '@emotion/styled';

export const Container = styled.div`
  max-width: 815px;
  height: 732px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 4px 24px 0 rgba(6, 24, 60, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px;
  flex: 1;
  position: relative;
`;

export const Picture = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 28px 0;
  margin-bottom: 28px;
  position: relative;

  svg {
    height: 264px;
  }

  &:after {
    content: '';
    width: 450px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.bgDarkGrey};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Title = styled(Typography.Title)`
  max-width: 500px;

  &.ui-typography {
    font-size: 24px;
    margin-bottom: 16px;
  }
`;

export const Description = styled.div`
  max-width: 400px;
  margin-bottom: 16px;
`;

export const Caption = styled.div`
  font-weight: 600;
`;

export const Actions = styled.div`
  position: absolute;
  bottom: 32px;
  right: 32px;
`;
