import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Details = styled(Flex)<FlexProps>`
  cursor: pointer;
  position: relative;
  flex: 1;

  &:hover {
    &:after {
      content: '';
      width: calc(100% + 32px);
      height: calc(100% + 32px);
      border: 1px dashed ${({ theme }) => theme.colors.text400};
      border-radius: 10px;
      position: absolute;
      top: -16px;
      left: -16px;
    }
  }
`;

export const BankText = styled.div`
  width: 100%;
`;

export const ContactText = styled.div`
  width: 100%;
  text-align: right;
`;

export const Name = styled.div`
  width: 130px;
`;
