import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { PicturePayslipsEmpty } from '@assets';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

const PayslipsEmpty: FC = () => {
  const navigate = useNavigate();

  return (
    <S.Container>
      <S.Picture>
        <PicturePayslipsEmpty />
      </S.Picture>

      <S.Title level={4}>{t('payslips.empty.title')()}</S.Title>

      <S.Description>{t('payslips.empty.description')()}</S.Description>

      <S.Caption>{t('payslips.empty.caption')()}</S.Caption>

      <S.Actions>
        <Button type="primary" onClick={() => navigate('/chat')}>
          {t('payslips.empty.buttonOpenChat')()}
        </Button>
      </S.Actions>
    </S.Container>
  );
};

export default PayslipsEmpty;
