import { Form, Typography } from 'antd';

import { Loader as CustomLoader } from '@components';
import styled from '@emotion/styled';

export const TableContainer = styled.div`
  .ui-table-row {
    cursor: pointer;
  }
`;

export const Loader = styled(CustomLoader)`
  margin-top: 150px;
`;

export const ExcludedTransaction = styled.span`
  color: ${({ theme }) => theme.colors.text200};
`;

export const FormControl = styled(Form.Item)`
  && {
    margin-bottom: 0;
  }
`;

export const DrawerContainer = styled.div`
  height: calc(100vh - 112px);
`;

export const DrawerTitle = styled(Typography.Title)`
  &.ui-typography {
    font-size: 28px;
    margin-bottom: 0;
  }
`;

export const SwitchLabel = styled.div`
  font-weight: 600;
`;

export const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  margin: 0 8px;
`;