import { Flex, Form, type ModalProps } from 'antd';
import { FC } from 'react';

import { Button } from '@ui-kit/Button/Button';

import InvoiceModalWrapper from '../InvoiceModalWrapper';
import * as S from './styled';

type InvoiceModalBankActionProps = {
  onConfirm: VoidFunction;
  onCancel: VoidFunction;
} & ModalProps;

const InvoiceModalBankAction: FC<InvoiceModalBankActionProps> = ({
  open,
  onConfirm,
  onCancel,
}) => (
  <InvoiceModalWrapper
    open={open}
    title={<S.Title>{t('invoiceGenerator.finalizeConfirm.title')()}</S.Title>}
    onCancel={onCancel}
  >
    <S.Description>
      {t('invoiceGenerator.finalizeConfirm.description')()}
    </S.Description>

    <S.Submit>
      <Form.Item noStyle>
        <Flex justify="flex-end" gap={12}>
          <Button onClick={onCancel}>
            {t('invoiceGenerator.buttonCancel')()}
          </Button>

          <Button type="primary" onClick={onConfirm}>
            {t('invoiceGenerator.finalizeConfirm.buttonConfirm')()}
          </Button>
        </Flex>
      </Form.Item>
    </S.Submit>
  </InvoiceModalWrapper>
);

export default InvoiceModalBankAction;
