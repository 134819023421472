import { Flex, Form, type ModalProps } from 'antd';
import { FC, useEffect } from 'react';

import { Schemas } from '@api-client/generated/types';
import { Button } from '@ui-kit/Button/Button';

import InvoiceFormCard from '../../form/InvoiceFormCard';
import InvoiceFormControl from '../../form/InvoiceFormControl';
import InvoiceModalWrapper from '../InvoiceModalWrapper';
import * as S from './styled';

type DetailsDto = Schemas.CompanyDetailsDto;

type InvoiceModalBankActionProps = {
  mode: 'add' | 'edit';
  details: Schemas.CompanyDetails;
  onSubmit: (values: Schemas.CompanyDto) => void;
  onCancel: VoidFunction;
} & ModalProps;

const InvoiceModalBankAction: FC<InvoiceModalBankActionProps> = ({
  mode,
  details,
  open,
  onSubmit,
  onCancel,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (details) {
      form.setFieldsValue({ details });
    }
  }, [form, details]);

  const handleSubmit = (values: DetailsDto) => {
    onSubmit({
      details: {
        ...details,
        bankData: {
          ...details.bankData,
          ...values,
        },
      },
    } as DetailsDto);
  };

  return (
    <InvoiceModalWrapper
      open={open}
      title={
        <S.Title>
          {mode === 'add'
            ? t('invoiceGenerator.actionBank.title')()
            : t('invoiceGenerator.actionBank.titleEdit')()}
        </S.Title>
      }
      onCancel={onCancel}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <InvoiceFormCard>
          <InvoiceFormControl
            type="input"
            form={{
              label: t('invoiceGenerator.actionBank.fieldIBAN.label')(),
              name: 'iban',
            }}
            control={{
              placeholder: t(
                'invoiceGenerator.actionBank.fieldIBAN.placeholder'
              )(),
            }}
          />

          <InvoiceFormControl
            type="input"
            form={{
              label: t('invoiceGenerator.actionBank.fieldBIC.label')(),
              name: 'bic',
            }}
            control={{
              placeholder: t(
                'invoiceGenerator.actionBank.fieldBIC.placeholder'
              )(),
            }}
          />
        </InvoiceFormCard>

        <S.Submit>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={onCancel}>
                {t('invoiceGenerator.buttonCancel')()}
              </Button>

              <Button type="primary" htmlType="submit">
                {mode === 'add'
                  ? t('invoiceGenerator.buttonAdd')()
                  : t('invoiceGenerator.buttonSave')()}
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      </Form>
    </InvoiceModalWrapper>
  );
};

export default InvoiceModalBankAction;
