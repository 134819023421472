import { Flex } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';

import { IconCalendar } from '@assets';
import { DatePickerWithAction } from '@components';
import { DateText } from '@entities';
import { colors } from '@theme';
import { Button } from '@ui-kit/Button/Button';

type InvoiceDocumentDateEditorProps = {
  label: string;
  date?: Date | string | null;
  onChange: (date: string) => void;
};

const InvoiceDocumentDateEditor: FC<InvoiceDocumentDateEditorProps> = ({
  label,
  date,
  onChange,
}) => (
  <Flex align="center" gap={5}>
    <span>{label}</span>
    {date ? <DateText date={date} format="date" /> : <span>-</span>}

    <DatePickerWithAction
      onChange={(date) => onChange(dayjs(date).toISOString())}
    >
      <Button type="link" icon={<IconCalendar color={colors.primary} />} />
    </DatePickerWithAction>
  </Flex>
);

export default InvoiceDocumentDateEditor;
