import { Flex, type FlexProps, Typography } from 'antd';

import styled from '@emotion/styled';

export const Header = styled(Flex)<FlexProps>`
  border-bottom: 2px solid ${({ theme }) => theme.colors.bgDarkGrey};
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 12px;
`;

export const Container = styled(Flex)<FlexProps>`
  width: 100%;
  max-width: 1240px;
`;

export const Title = styled(Typography.Title)`
  && {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

export const List = styled.div`
  width: 340px;
`;
