// import { TabsProps } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTaskController_findAll } from '@api-client/generated/TaskController/findAll';
// import { TasksSidebar } from './sidebar/TasksSidebar';
import { Schemas } from '@api-client/generated/types';
import { Loader } from '@components';
import { PageMeta } from '@entities';
import { useAccount, useAppState } from '@hooks';
import { GTMEventName, sendGTMEvent } from '@utils';

import ChatContent from './content/ChatContent/ChatContent';
import { ChatSidebar } from './sidebar/ChatSidebar';
import * as S from './styled';

/*
const items: TabsProps['items'] = [
  {
    key: 'chat',
    label: 'Chats',
    children: <ChatSidebar />,
  },
  {
    key: 'tasks',
    label: 'Tasks',
    children: <TasksSidebar />,
  },
];
*/

export const Tasks = () => {
  const { id: taskId } = useParams();
  const navigate = useNavigate();

  const { companyId } = useAccount();

  const { isPayrollPlaceholderMessage } = useAppState();

  const [selectedTask, setSelectedTask] = useState<Schemas.Task>();

  if (!companyId) {
    throw new Error('Company ID is not defined');
  }

  const { data: tasks, isPending: loading } = useTaskController_findAll({
    params: {
      companyId,
    },
  });

  useEffect(() => {
    sendGTMEvent(GTMEventName.OpenedChat);
  }, []);

  useEffect(() => {
    if (isPayrollPlaceholderMessage) {
      const generalTask = tasks?.find((task) => task.isGeneral);

      if (generalTask) {
        navigate(`/tasks/${generalTask.id}`);
        setSelectedTask(generalTask);
      }
    } else {
      setSelectedTask(tasks?.find((task) => task.id === taskId));
    }
  }, [tasks, isPayrollPlaceholderMessage, taskId, navigate]);

  if (loading) {
    return <Loader />;
  }

  /*
  const activeKey =
    !selectedTask || selectedTask.isGeneral ? items[0].key : items[1].key;
*/

  return (
    <>
      <PageMeta title={t('tasks.title')()} />
      <S.Wrapper>
        <S.Sidebar>
          {/*<S.Tabs defaultActiveKey={activeKey} items={items} />*/}
          <ChatSidebar />
        </S.Sidebar>

        <S.Content>
          {!taskId || !selectedTask ? (
            <S.EmptyChat>{t('chat.selectChat')()}</S.EmptyChat>
          ) : (
            <ChatContent taskId={taskId} details={selectedTask} />
          )}
        </S.Content>
      </S.Wrapper>
    </>
  );
};
