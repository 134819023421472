import { Flex, Modal, Upload } from 'antd';
import _ from 'lodash';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useDocumentController_create } from '@api-client/generated/DocumentController/create';
import { useDocumentController_delete } from '@api-client/generated/DocumentController/delete';
import { useDocumentController_findAll } from '@api-client/generated/DocumentController/findAll';
import { useDocumentController_findOneById } from '@api-client/generated/DocumentController/findOneById';
import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { Params$DocumentController_findAll } from '@api-client/generated/client';
import { Schemas } from '@api-client/generated/types';
import { IconPlus, IconUpload } from '@assets';
import { Loader } from '@components';
import {
  PAYMENT_DOCUMENT_VALID_EXTENSIONS,
  PaymentDocumentUnit,
} from '@constants';
import {
  FilterConstructor,
  FilterConstructorDrawer,
  type FilterControl,
  PageMeta,
  PaymentDocumentDetailsForm,
  PaymentDocumentDropFile,
  PaymentDocumentDropFileOverlay,
  PaymentDocumentEmpty,
  PaymentDocumentError,
  PaymentDocumentFileView,
  PaymentDocumentInvoiceInfo,
  PaymentDocumentInvoices,
  PaymentDocumentNoInvoices,
  PaymentDocumentPotentialDuplicate,
  PaymentDocumentSkeleton,
  PaymentDocumentStatusBar,
} from '@entities';
import { FilterSearchParams } from '@entities/filters/FilterConstructor/FilterConstructorControls';
import { useAccount } from '@hooks';
import { usePagination } from '@hooks/useInfiniteScroll';
import useSocketClient from '@hooks/useSocketClient';
import { Button } from '@ui-kit/Button/Button';
import {
  DATE_ISO_FORMAT,
  GTMEventName,
  downloadFile,
  getDateDefault,
  sendGTMEvent,
} from '@utils';
import { checkDocumentFormatBeforeUpload } from '@utils/checkDocumentFormatBeforeUpload';

import { getTranslatesByType } from './helpers';
import * as S from './styled';

type FilesWithMeta = Schemas.PaginatedDocumentResponse;
type PaymentDocumentFile = Schemas.Document;
type FilterParameter = Params$DocumentController_findAll['parameter'];

type PaginationData<T> = {
  plainData: T[];
  incrementPage: () => void;
  hasNextPage: boolean;
  isPending: boolean;
};

type PaymentDocumentProps = {
  type: PaymentDocumentUnit;
};

const filterControls = ['contactIds', 'status', 'onlyWithoutTransactions'];

const useAllFiles = (
  commonRequestParams: FilterParameter & { perPage: number }
) => {
  const [isPending, setIsPending] = useState(true);

  const hash = JSON.stringify(commonRequestParams);

  useEffect(() => {
    setIsPending(true);
  }, [hash]);

  const {
    metadata,
    incrementPage,
    plainData,
    appendData,
    setPage,
    hasNextPage,
  } = usePagination<Schemas.Document>(hash);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const { isFetching, refetch, data } = useDocumentController_findAll({
    params: {
      page: metadata.currentPage,
      ...commonRequestParams,
    },
  });

  useEffect(() => {
    if (isFetching) {
      setIsPending(true);
    }
  }, [isFetching]);

  useEffect(() => {
    if (data) {
      appendData(data);
      if (!isFetching) {
        setIsInitialLoading(false);
        setIsPending(false);
      }
    }
  }, [appendData, data, isFetching]);

  const refetchById = useCallback(
    (id: string, flushTail?: boolean) => {
      const index = plainData.findIndex((item) => item.id === id);
      if (index >= 0) {
        const page = Math.ceil((index + 1) / commonRequestParams.perPage);

        setPage(page, flushTail);
        refetch();
      }
    },
    [commonRequestParams.perPage, plainData, refetch, setPage]
  );

  return {
    isPending,
    plainData,
    hasNextPage,
    incrementPage,
    refetchById,
    refetch,
    metadata,
    setPage,
    isInitialLoading,
  };
};

const PaymentDocument: FC<PaymentDocumentProps> = ({ type }) => {
  const paymentDocumentQueryType =
    type === 'income' ? 'income_document' : 'expence_document';

  const translates = getTranslatesByType(type);

  const { id: paymentDocumentId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [modal, contextHolder] = Modal.useModal();

  const { companyId, featuresStatus } = useAccount();

  if (!companyId) {
    throw new Error('Company ID is required');
  }

  const [selectedTabKey, setSelectedTabKey] = useState('all');
  const [selectedFileId, setSelectedFileId] = useState<string | undefined>();
  const [filterOptions, setFilterOptions] = useState<Record<string, unknown>>(
    {}
  );
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [filterDrawerOptions, setFilterDrawerOptions] = useState<
    Record<string, unknown> | string[] | null
  >(null);
  const [currentListIndex, setCurrentListIndex] = useState(0);

  const { socket } = useSocketClient({
    namespace: 'documents',
  });

  const commonRequestParams = {
    companyId,
    perPage: 10,
    withUrl: true,
    type: paymentDocumentQueryType,
    ...filterOptions,
  } satisfies FilterParameter;

  const { setPage: setAllFilesPage, ...allFiles } =
    useAllFiles(commonRequestParams);

  const { setPage: setReviewFilesPage, ...reviewFiles } = useAllFiles({
    ...commonRequestParams,
    toReview: true,
  });

  const { setPage: setErrorFilesPage, ...errorFiles } = useAllFiles({
    ...commonRequestParams,
    withErrors: true,
  });

  const {
    data: fileDetails,
    isPending: isLoadingFile,
    refetch: refetchFile,
  } = useDocumentController_findOneById({
    params: {
      companyId,
      id: selectedFileId!,
    },
    config: {
      enabled: !!selectedFileId,
      refetchOnWindowFocus: false,
    },
  });

  const { mutate: createDocument } = useDocumentController_create();
  const { mutate: deleteDocument } = useDocumentController_delete();
  const { mutate: updateDocument } = useDocumentController_updateOneById();

  const onDocumentProcessed = useCallback(
    (response: unknown) => {
      if (
        response &&
        typeof response === 'object' &&
        'data' in response &&
        response.data &&
        typeof response.data === 'object' &&
        'id' in response.data &&
        typeof response.data.id === 'string'
      ) {
        setSelectedFileId(response.data.id);
      }

      setAllFilesPage(1, true);
      setReviewFilesPage(1, true);
      setErrorFilesPage(1, true);
    },
    [setAllFilesPage, setReviewFilesPage, setErrorFilesPage]
  );

  useEffect(() => {
    socket.on('document:processed', onDocumentProcessed);

    return () => {
      socket.off('document:processed', onDocumentProcessed);
    };
  }, [onDocumentProcessed, socket]);

  useEffect(() => {
    if (paymentDocumentId) {
      setSelectedFileId(paymentDocumentId);
    }
  }, [location, paymentDocumentId]);

  const handleUpdateListIndex = useCallback(
    (id: string) => {
      if (selectedTabKey === 'toReview') {
        const index = reviewFiles.plainData.findIndex(
          (record) => record.id === id
        );

        setCurrentListIndex(index);
      } else if (selectedTabKey === 'error') {
        const index = errorFiles.plainData.findIndex(
          (record) => record.id === id
        );

        setCurrentListIndex(index);
      } else {
        const index = allFiles.plainData.findIndex(
          (record) => record.id === id
        );

        setCurrentListIndex(index);
      }
    },
    [selectedTabKey, reviewFiles, errorFiles, allFiles]
  );

  const handleImportInvoice = (options: UploadRequestOption) => {
    const formData = new FormData();
    setIsUploadingFile(true);

    formData.append('file', options.file);
    if (typeof options.file === 'object' && 'name' in options.file) {
      formData.append('name', options.file.name);
    }
    formData.append('type', paymentDocumentQueryType);

    createDocument(
      {
        parameter: {
          companyId,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestBody: formData as any,
      },
      {
        onSuccess: () => {
          if (type === 'expenses') {
            sendGTMEvent(GTMEventName.UploadedExpence);
          }

          handleUpdateAllFiles();
          setIsUploadingFile(false);
        },
      }
    );
  };

  const handleUpdateAllFiles = () => {
    allFiles.refetch();
    reviewFiles.refetch();
    errorFiles.refetch();
  };

  const handleUpdateSelectedFile = (file: PaymentDocumentFile | null) => {
    if (file) {
      allFiles.refetchById(file.id);
      reviewFiles.refetchById(file.id);
      errorFiles.refetchById(file.id);

      refetchFile();
    }
  };

  const handleDeleteFile = (id?: string) => {
    if (id) {
      modal.confirm({
        title: translates.messageRemove,
        onOk: () => {
          navigate(`/${type}`);
          return deleteDocument(
            {
              parameter: {
                companyId,
                id,
              },
            },
            {
              onSuccess: () => {
                allFiles.refetchById(id, true);
                reviewFiles.refetchById(id, true);
                errorFiles.refetchById(id, true);

                setSelectedFileId(undefined);
              },
            }
          );
        },
        autoFocusButton: null,
        okButtonProps: {
          size: 'small',
        },
        cancelButtonProps: {
          size: 'small',
        },
      });
    }
  };

  const handleUpdateFile = (file: PaymentDocumentFile) => {
    const formData = new FormData();
    const { documentMetadata } = file;

    setIsUploadingFile(true);

    if (documentMetadata) {
      if (documentMetadata.status) {
        formData.append(
          'documentMetadata[status]',
          file.documentMetadata.status!
        );
      }

      if (documentMetadata.number) {
        formData.append(
          'documentMetadata[number]',
          file.documentMetadata.number!
        );
      }

      if (documentMetadata.description) {
        formData.append(
          'documentMetadata[description]',
          file.documentMetadata.description!
        );
      }

      if (documentMetadata.amount) {
        formData.append(
          'documentMetadata[amount]',
          String(file.documentMetadata.amount)
        );
      }

      if (documentMetadata.currency) {
        formData.append(
          'documentMetadata[currency]',
          file.documentMetadata.currency!
        );
      }

      if (documentMetadata.issueDate) {
        formData.append(
          'documentMetadata[issueDate]',
          getDateDefault(file.documentMetadata.issueDate!, DATE_ISO_FORMAT)
        );
      }

      if (documentMetadata.dueDate) {
        formData.append(
          'documentMetadata[dueDate]',
          getDateDefault(file.documentMetadata.dueDate!, DATE_ISO_FORMAT)
        );
      }

      if (
        documentMetadata.isReviewed !== null &&
        documentMetadata.isReviewed !== undefined
      ) {
        formData.append(
          'documentMetadata[isReviewed]',
          String(documentMetadata.isReviewed)
        );
      }
    }

    if (file.contact) {
      formData.append('contactId', file.contact.id);
    }

    formData.append('documentMetadata[ignoreDuplicate]', JSON.stringify(true));

    updateDocument(
      {
        parameter: {
          companyId,
          id: file.id,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestBody: formData as any,
      },
      {
        onSuccess: (updatedFile) => {
          handleUpdateSelectedFile(updatedFile);
          setIsUploadingFile(false);
        },
      }
    );
  };

  const handleChangeTab = (key: string) => {
    setSelectedFileId(undefined);
    setSelectedTabKey(key);
  };

  const handleChangeFilters = (filters: FilterSearchParams) => {
    // Workaround to prevent empty screen from showing when no filters are applied
    setFilterOptions(_.isEmpty(filters) ? { term: '' } : filters);
  };

  const hasNoFilter = () => _.isEmpty(filterOptions);

  const handleCreateInvoice = () => {
    const formData = new FormData();
    setIsUploadingFile(true);

    formData.append('type', 'income_document');

    createDocument(
      {
        parameter: {
          companyId,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestBody: formData as any,
      },
      {
        onSuccess: (document) => {
          navigate(`/income/new/${document.id}`);
          setIsUploadingFile(false);
        },
      }
    );
  };

  const getMetadataByTabKey = (): FilesWithMeta['metadata'] => {
    if (selectedTabKey === 'toReview') {
      return reviewFiles.metadata;
    } else if (selectedTabKey === 'error') {
      return errorFiles.metadata;
    } else {
      return allFiles.metadata;
    }
  };

  const handleNavigateToItem = (id: string) =>
    navigate(type === 'income' ? `/income/${id}` : `/expenses/${id}`);

  const handlePrevItemOfList = () => {
    if (currentListIndex > 0) {
      const index = currentListIndex - 1;

      setCurrentListIndex(index);

      if (selectedTabKey === 'toReview') {
        handleNavigateToItem(reviewFiles.plainData[index].id);
      } else if (selectedTabKey === 'error') {
        handleNavigateToItem(errorFiles.plainData[index].id);
      } else {
        handleNavigateToItem(allFiles.plainData[index].id);
      }
    }
  };

  const handlePaginateByTab = (
    tab: string,
    data: PaginationData<Schemas.Document>
  ) => {
    const index = currentListIndex + 1;

    if (selectedTabKey === tab) {
      const isIndexMatchingLength = data.plainData.length === index;

      if (isIndexMatchingLength && data.hasNextPage) {
        data.incrementPage();
      }

      if (!data.isPending && !isIndexMatchingLength) {
        setCurrentListIndex(index);
        handleNavigateToItem(data.plainData[index].id);
      }
    }
  };

  const handleNextItemOfList = () => {
    const metadata = getMetadataByTabKey();

    if (currentListIndex < metadata.totalRecords - 1) {
      handlePaginateByTab('all', allFiles);
      handlePaginateByTab('toReview', reviewFiles);
      handlePaginateByTab('error', errorFiles);
    }
  };

  const handleSelectInvoice = (id: string) => {
    setSelectedFileId(id);
    handleUpdateListIndex(id);
  };

  const tabItems = [
    {
      key: 'all',
      label: translates.tabAllInvoices,
      children: !allFiles.metadata.totalRecords ? (
        <PaymentDocumentNoInvoices type={type} />
      ) : (
        <PaymentDocumentInvoices
          type={type}
          hasNextPage={allFiles.hasNextPage}
          isLoading={allFiles.isPending}
          selectedId={selectedFileId}
          files={allFiles.plainData}
          onSelectedInvoice={handleSelectInvoice}
          onLoadMore={allFiles.incrementPage}
          onDelete={handleDeleteFile}
        />
      ),
    },
    {
      key: 'toReview',
      label:
        type === 'income'
          ? t('income.tabs.toReview', {
              count: reviewFiles.metadata.totalRecords,
            })()
          : t('expenses.tabs.toReview', {
              count: reviewFiles.metadata.totalRecords,
            })(),
      children: !reviewFiles.metadata.totalRecords ? (
        <PaymentDocumentNoInvoices type={type} />
      ) : (
        <PaymentDocumentInvoices
          type={type}
          hasNextPage={reviewFiles.hasNextPage}
          isLoading={reviewFiles.isPending}
          selectedId={selectedFileId}
          files={reviewFiles.plainData}
          onSelectedInvoice={handleSelectInvoice}
          onLoadMore={reviewFiles.incrementPage}
          onDelete={handleDeleteFile}
        />
      ),
    },
  ];

  if (errorFiles.metadata.totalRecords) {
    tabItems.push({
      key: 'error',
      label:
        type === 'income'
          ? t('income.tabs.error', {
              count: errorFiles.metadata.totalRecords,
            })()
          : t('expenses.tabs.error', {
              count: errorFiles.metadata.totalRecords,
            })(),
      children: (
        <PaymentDocumentInvoices
          type={type}
          hasNextPage={errorFiles.hasNextPage}
          isLoading={errorFiles.isPending}
          selectedId={selectedFileId}
          files={errorFiles.plainData}
          onSelectedInvoice={handleSelectInvoice}
          onLoadMore={errorFiles.incrementPage}
          onDelete={handleDeleteFile}
        />
      ),
    });
  }

  const [{ canDrop }, dropRef] = useDrop(
    {
      accept: [NativeTypes.FILE],
      collect: (monitor: DropTargetMonitor) => ({
        canDrop: monitor.canDrop(),
      }),
    },
    []
  );

  const controls = useMemo<{ left: FilterControl[] }>(
    () => ({
      left: [
        {
          type: 'search',
          formName: 'term',
          params: {
            value: paymentDocumentId,
          },
        },
        { type: 'divider' },
        {
          type: 'range-picker',
          formName: 'date',
        },
      ],
    }),
    [paymentDocumentId]
  );

  const noFilters = hasNoFilter();
  const isInitialLoading =
    allFiles.isInitialLoading ||
    reviewFiles.isInitialLoading ||
    errorFiles.isInitialLoading;

  const areEmptyResults =
    allFiles.plainData.length === 0 &&
    reviewFiles.plainData.length === 0 &&
    errorFiles.plainData.length === 0;

  const isPending =
    allFiles.isPending || reviewFiles.isPending || errorFiles.isPending;

  if (isInitialLoading || (isUploadingFile && areEmptyResults)) {
    return <Loader />;
  }

  if (areEmptyResults && noFilters && !fileDetails && !isPending) {
    return (
      <PaymentDocumentEmpty
        type={type}
        onBeforeUpdate={() => {
          setIsUploadingFile(true);
        }}
        onUpdate={() => {
          handleUpdateAllFiles();
          setIsUploadingFile(false);
        }}
      />
    );
  }

  return (
    <Flex gap={24} vertical>
      <PageMeta title={translates.title} />

      {contextHolder}

      <S.Title isIncome={type === 'income'}>{translates.title}</S.Title>

      <FilterConstructor<PaymentDocumentFile>
        externalParameters={filterDrawerOptions}
        controls={controls}
        filterControls={filterControls}
        actions={
          <Flex align="center" gap={16}>
            <Upload
              accept={PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(',')}
              beforeUpload={(file) =>
                checkDocumentFormatBeforeUpload(file, type)
              }
              customRequest={handleImportInvoice}
              showUploadList={false}
              multiple
            >
              <Button icon={<IconUpload />}>{translates.buttonImport}</Button>
            </Upload>

            {featuresStatus?.invoiceConstructor && type === 'income' && (
              <Button
                type="primary"
                icon={<IconPlus />}
                onClick={handleCreateInvoice}
              >
                {t('income.buttonCreateInvoice')()}
              </Button>
            )}
          </Flex>
        }
        onChange={handleChangeFilters}
        withSearchParams
      />

      <S.Container ref={dropRef}>
        {canDrop && (
          <PaymentDocumentDropFileOverlay
            type={type}
            onUpdate={handleUpdateAllFiles}
          />
        )}

        <S.Content hidden={canDrop} gap={32}>
          <S.WrapperLeft>
            {!allFiles.plainData.length && hasNoFilter() ? (
              <>
                {isPending ? (
                  <S.Loader>
                    <Loader />
                  </S.Loader>
                ) : (
                  <PaymentDocumentDropFile
                    type={type}
                    onUpdate={handleUpdateAllFiles}
                  />
                )}
              </>
            ) : (
              <S.TabsInvoices
                activeKey={selectedTabKey}
                size="large"
                items={tabItems}
                onChange={handleChangeTab}
                tabBarExtraContent={
                  <FilterConstructorDrawer
                    initialParams={filterOptions}
                    controls={[
                      {
                        type: 'list-contacts',
                        formName: 'contactIds',
                        label: translates.filterContacts,
                      },
                      {
                        type: 'switch',
                        formName: 'onlyWithoutTransactions',
                        label: translates.filterShowInvoices,
                      },
                    ]}
                    buttonParams={{
                      type: 'link',
                      variant: 'link',
                      size: 'middle',
                    }}
                    onSubmit={setFilterDrawerOptions}
                  />
                }
              />
            )}
          </S.WrapperLeft>

          <S.WrapperRight vertical>
            {fileDetails && (
              <PaymentDocumentStatusBar
                type={type}
                adminStatus={fileDetails.adminStatus}
                isRecognitionCompleted={fileDetails.isRecognitionCompleted}
                isDraft={fileDetails.isDraft}
                documentMetadata={fileDetails.documentMetadata}
                potentialDuplicate={fileDetails.potentialDuplicate}
                totalRecords={getMetadataByTabKey().totalRecords}
                currentListIndex={currentListIndex}
                errorCode={fileDetails?.errorCode}
                isLoading={
                  allFiles.isPending ||
                  reviewFiles.isPending ||
                  errorFiles.isPending
                }
                onPrev={handlePrevItemOfList}
                onNext={handleNextItemOfList}
                onDownload={() =>
                  downloadFile(fileDetails.url, fileDetails.name, true)
                }
                onDelete={() => handleDeleteFile(fileDetails.id)}
              />
            )}

            <S.Inner gap={30}>
              {fileDetails?.hasError ? (
                <PaymentDocumentError
                  type={type}
                  onDelete={() => handleDeleteFile(fileDetails?.id)}
                />
              ) : (
                <>
                  {fileDetails && !fileDetails?.isRecognitionCompleted ? (
                    <>
                      <PaymentDocumentSkeleton />

                      {fileDetails.isDraft ? (
                        <PaymentDocumentInvoiceInfo
                          title={t('income.detailsIsDraft.title')()}
                          description={t('income.detailsIsDraft.description')()}
                          content={
                            <Button
                              size="small"
                              onClick={() =>
                                navigate(`/income/new/${fileDetails.id}`)
                              }
                            >
                              {t('income.detailsIsDraft.buttonEdit')()}
                            </Button>
                          }
                        />
                      ) : (
                        <PaymentDocumentInvoiceInfo
                          title={t('income.detailsProcessed.title')()}
                          description={t(
                            'income.detailsProcessed.description'
                          )()}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {fileDetails ? (
                        <>
                          <PaymentDocumentFileView file={fileDetails} />

                          {fileDetails?.potentialDuplicate &&
                          !fileDetails?.documentMetadata.ignoreDuplicate ? (
                            <PaymentDocumentPotentialDuplicate
                              type={type}
                              file={fileDetails?.potentialDuplicate}
                              onProceed={() => handleUpdateFile(fileDetails)}
                              onCancel={() => handleDeleteFile(fileDetails?.id)}
                            />
                          ) : (
                            <>
                              <S.FormWrapper>
                                <S.TitleDetails level={3}>
                                  {t(`${type}.details.title`)()}
                                </S.TitleDetails>

                                <PaymentDocumentDetailsForm
                                  type={type}
                                  details={fileDetails}
                                  onUpdate={handleUpdateSelectedFile}
                                  onAttachTransaction={handleUpdateSelectedFile}
                                  onRefreshFile={refetchFile}
                                  hasSwitches={false}
                                />
                              </S.FormWrapper>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {isLoadingFile && selectedFileId ? (
                            <S.Loader>
                              <Loader />
                            </S.Loader>
                          ) : (
                            <PaymentDocumentSkeleton />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </S.Inner>
          </S.WrapperRight>
        </S.Content>
      </S.Container>
    </Flex>
  );
};

export default PaymentDocument;
