import { ReactNode } from 'react';

import * as S from './styled';

type Props = {
  isDarkTheme?: boolean;
  left?: ReactNode | ReactNode[];
  right?: ReactNode | ReactNode[];
}

/** Component to render filters in two block - left and right */
function FilterFormLayout(props: Props) {
  return (
    <S.Container inversionTheme={props.isDarkTheme}>
      <S.ColumnsGrid>
        <S.ControlFlexGroup>{props.left}</S.ControlFlexGroup>
        <S.ControlFlexGroup>{props.right}</S.ControlFlexGroup>
      </S.ColumnsGrid>
    </S.Container>
  );
}

export default FilterFormLayout;
