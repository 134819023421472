import { Flex, Typography } from 'antd';

import styled from '@emotion/styled';

export const Wrapper = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.strokeGrey};
  height: 234px;
`;

export const Title = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey400};
  line-height: 22px;
  font-weight: 600;
`;

export const Text = styled(Typography)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey400};
  line-height: 20px;
  font-weight: 400;
  margin-top: 8px;
`;
