import { Flex, type FlexProps, Typography } from 'antd';

import styled from '@emotion/styled';

export const ExtraLabel = styled.span`
  color: ${({ theme }) => theme.colors.grey400};
  font-weight: 600;
`;

export const Name = styled.div`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Description = styled(Typography.Text)`
  font-size: 12px;
  line-height: 20px;
  width: 215px;
  height: 30px;
`;

export const Amount = styled.div`
  .ui-typography {
    font-weight: 600;
  }
`;

export const Date = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-size: 12px;
`;

export const Booked = styled(Flex)<FlexProps>`
  color: ${({ theme }) => theme.colors.green500};
  font-weight: 600;
`;
