import { Modal, type ModalProps } from 'antd';
import { FC, PropsWithChildren } from 'react';

import * as S from './styled';

const InvoiceModalWrapper: FC<PropsWithChildren<ModalProps>> = ({
  children,
  ...rest
}) => (
  <Modal width={580} footer={null} destroyOnClose centered {...rest}>
    <S.Inner gap={24} vertical>
      {children}
    </S.Inner>
  </Modal>
);

export default InvoiceModalWrapper;
