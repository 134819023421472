import { useCallback, useState } from 'react'
import { z } from 'zod'
import _ from 'lodash'

import { ObjectWithOtherKeys, parseSearchParams, stringifySearchParams } from '@utils'
import { removeDefaultOrEmptyValues } from '@utils/zod'

interface UseQueryParamsConfig<T extends z.AnyZodObject> {
  schema: T
  defaultValues: z.infer<T>
}

/**
 * Hook that allows you to work with query parameters with schema validation.
 * 
 * Supposed to be used on the top level component (probably a page).
 */
export function useQueryParams<T extends z.AnyZodObject>(
  config: UseQueryParamsConfig<T>
) {
  function getMergedQueryParams() {
    const fromQuery = parseSearchParams<T>(window.location.search, config.schema);
    return {...config.defaultValues, ...fromQuery};
  }

  function validateOrDefault(merged: ObjectWithOtherKeys<T>) {
    const parseResult = config.schema.safeParse(merged);

    if (parseResult.success) {
      return removeDefaultOrEmptyValues(config.schema, parseResult.data);
    } else {
      console.error(parseResult.error);
      return config.defaultValues;
    }
  }

  const [queryParams, setQueryParamsState] = useState<typeof config.defaultValues>(validateOrDefault(getMergedQueryParams()))

  const setQueryParams = useCallback((newParams: Partial<z.infer<T>>): void => {
    const parseResult = config.schema.safeParse(newParams);
    if (!parseResult.success) {
      console.error(
        'Error writing to query params! Please, check the provided schema'
      );
      console.error(parseResult.error);
    }

    const nonDefaultOrEmpty = removeDefaultOrEmptyValues(config.schema, newParams);
    setQueryParamsState(nonDefaultOrEmpty);

    if (Object.keys(nonDefaultOrEmpty).length > 0) {
      const stringParams = stringifySearchParams(nonDefaultOrEmpty)
      window.history.replaceState(null, '', `${window.location.pathname}?${stringParams}`)
    } else {
      window.history.replaceState(null, '', `${window.location.pathname}`)
    }
  }, [config])  

  const resetQueryParams = useCallback(() => {
    setQueryParams(config.defaultValues);
  }, []) // eslint-disable-line

  return {
    /** query params according to passed schema */
    queryParams,
    /** set query params according to passed schema */
    setQueryParams,
    /** resets to default state according to passed schema */
    resetQueryParams,
  }
}