import { Form, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { ChangeEvent, ReactNode, useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';



import { DEFAULT_TIMEOUT_FOR_SEARCH } from '@constants';
import RangePicker from '@entities/common/RangePicker';
import SearchControl from '@entities/common/SearchControl';
import { FilterConstructorDrawer, FilterControl } from '@entities/filters/FilterConstructor';
import FilterFormLayout from '@entities/filters/FilterConstructor/FilterFormLayout';
import { GTMEventName, sendGTMEvent } from '@utils';



import { FilterForm, type QueryParams, filterFormFromQuery, filterFormToQuery, queryParamsSchema } from './schema';
import * as S from './styled';


type ModuleFilterKeys = Pick<
  QueryParams,
  | 'accountIds'
  | 'categoryIds'
  | 'contactIds'
  | 'onlyWithoutDocuments'
  | 'showByPlDate'
>;

type Props<T = QueryParams> = {
  /** Disabling/enabling specific form fields */
  hiddenFormFields?: Set<
    | 'categoryIds'
    | 'contactIds'
    | 'accountIds'
    | 'projectIds'
    | 'onlyWithoutDocuments'
    | 'showByPlDate'
  >;
  /** Invert theme color for controls */
  isDarkTheme?: boolean;
  /** Default form values/query params  */
  queryParams?: Partial<T>;
  /** Slot for custom action buttons */
  right?: ReactNode | ReactNode[];
  /** Total amount of transactions to display hint */
  total?: number;
  /** Callback to receive form values */
  onFormUpdate?: (values: Partial<QueryParams>) => void;
};

const moduleFilterSchema = queryParamsSchema.pick({
  categoryIds: true,
  contactIds: true,
  accountIds: true,
  projectIds: true,
  onlyWithoutDocuments: true,
  showByPlDate: true,
});

function TransactionsTableFilterForm(props: Props) {
  const [form] = Form.useForm<FilterForm>();

  const moduleFilterControls = useMemo(
    () =>
      [
        {
          label: t('filter.category.label')(),
          type: 'list-categories',
          formName: 'categoryIds',
        },
        {
          label: t('filter.contacts.label')(),
          type: 'list-contacts',
          formName: 'contactIds',
          hidden: props.hiddenFormFields?.has('contactIds'),
        },
        {
          label: t('filter.bank.label')(),
          type: 'list-banks',
          formName: 'accountIds',
        },
        {
          label: t('filter.project.label')(),
          type: 'list-projects',
          formName: 'projectIds',
        },
        {
          label: t('transactionsPage.label.withoutDocuments')(),
          type: 'switch',
          formName: 'onlyWithoutDocuments',
        },
        {
          label: t('transactionsPage.label.byPlDate')(),
          type: 'switch',
          formName: 'showByPlDate',
          isBoolean: true,
        },
      ] as const satisfies FilterControl[],
    [props.hiddenFormFields],
  );

  useEffect(() => {
    const formValues = filterFormFromQuery.parse(props.queryParams || {});
    // @ts-expect-error i will fix it later
    form.setFieldsValue(formValues);
  }, []); // eslint-disable-line

  function onFormChange() {
    const values = form.getFieldsValue();

    if (props.onFormUpdate) {
      props.onFormUpdate(filterFormToQuery.parse(values));
    }

    sendGTMEvent(GTMEventName.AppliedTransactionFilters);
  }

  function handleModuleFilterChange(values: ModuleFilterKeys | null) {
    const schemaValues = moduleFilterSchema.parse(values);
    if (schemaValues) {
      form.setFieldsValue({ ...schemaValues });
    }
    onFormChange();
  }

  const handleInputChange = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue('term', event.target.value);
      onFormChange();
    },
    DEFAULT_TIMEOUT_FOR_SEARCH,
  );

  function handleDatePickerChange(values: (Dayjs | null)[] | null) {
    form.setFieldValue('date', values || null);
    onFormChange();
  }

  function getFormValuesForModuleFilter() {
    return moduleFilterSchema.parse(form.getFieldsValue());
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      requiredMark={false}
      onFieldsChange={onFormChange}
    >
      <FilterFormLayout
        isDarkTheme={props.isDarkTheme}
        left={
          <>
            <S.FormControl name="term">
              <SearchControl onChange={handleInputChange} />
            </S.FormControl>

            <S.Divider />

            <FilterConstructorDrawer
              form={form}
              initialParams={getFormValuesForModuleFilter()}
              controls={moduleFilterControls}
              // @ts-expect-error FilterConstructor is too dynamic for strict schema
              onSubmit={handleModuleFilterChange}
            />

            <S.FormControl name="date">
              <RangePicker onChange={handleDatePickerChange} />
            </S.FormControl>

            {props.total && props.total > 0 ? (
              <>
                <S.Divider />

                <Typography.Text type="secondary">
                  <>
                    {props.total} {t('transactionsPage.transactionsFound')()}
                  </>
                </Typography.Text>
              </>
            ) : (
              ''
            )}
          </>
        }
        right={props.right}
      />
    </Form>
  );
}

export default TransactionsTableFilterForm;