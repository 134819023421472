import {
  Button,
  Flex,
  Modal,
  Table,
  type TableColumnType,
  message,
} from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useTransactionController_create } from '@api-client/generated/TransactionController/create';
import { Schemas } from '@api-client/generated/types';
import {
  Card,
  DatePickerHidden,
  InputHidden,
  InputNumberMoney,
} from '@components';
import { FilterCategories, FilterContacts } from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useRemoveTransactionById, useUpdateTransactionById } from '@hooks-api';
import {
  DATE_ISO_FORMAT,
  GTMEventName,
  getCurrencySymbol,
  getDateDefault,
  inverseNumberValue,
  sendGTMEvent,
} from '@utils';

import * as S from './styled';

type Transaction = Schemas.Transaction;

type TransactionSplitProps = {
  transactionId: string;
  transactions: Transaction[];
  fullAmount?: number;
  currencyCode?: string;
  onChangeChildrenCount: (value: number) => void;
};

type TransactionChildType = {
  contactId: string;
  categoryId: string;
  amount?: string;
  plDate: string;
  details: string;
};

type TransactionChange = Pick<
  Transaction,
  'details' | 'amount' | 'contactId' | 'plDate' | 'categoryId'
>;

type FieldHandlers<
  T extends keyof TransactionChange = keyof TransactionChange,
  // eslint-disable-next-line
> = {
  [key in T]: (value: Transaction[T], id: string) => void;
};

type PartialTransaction = Partial<Transaction> & { id: string };
const TransactionSplit: FC<TransactionSplitProps> = ({
  transactionId,
  transactions = [],
  fullAmount,
  currencyCode,
  onChangeChildrenCount,
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const { translate } = useTranslate();
  const { companyId } = useAccount();

  const [key, setKey] = useState(Date.now());
  const [transactionsList, setTransactionsList] =
    useState<PartialTransaction[]>();
  const [isTransactionSplit, setIsTransactionSplit] = useState(false);
  const [newTransactionChild, setNewTransactionChild] = useState<
    TransactionChildType | object
  >({});
  const [leftToAssignAmount, setLeftToAssignAmount] = useState(0);

  useEffect(() => {
    setKey(Date.now());
    setTransactionsList(transactions);
  }, [transactions]);

  const { mutate: updateTransaction, isPending: updateLoading } =
    useUpdateTransactionById();
  const { mutate: createChildTransaction, isPending: createLoading } =
    useTransactionController_create();
  const { mutate: removeTransaction, isPending: removeLoading } =
    useRemoveTransactionById();

  const handleUpdateDetails = useDebouncedCallback(
    (value, id) =>
      handleUpdateTransaction(id, {
        details: value,
      }),
    1000,
  );

  const handleUpdateAmount = useDebouncedCallback((value, id) => {
    if (value !== null)
      handleUpdateTransaction(id, {
        amount: value,
      });
  }, 1000);

  const handleChangeValues = <T extends keyof TransactionChange>(
    field: T,
    value: TransactionChange[T],
    id: string,
  ) => {
    const fieldHandlers: FieldHandlers = {
      details: handleUpdateDetails,
      amount: handleUpdateAmount,
      contactId: () => handleUpdateTransaction(id, { [field]: value }),
      plDate: () => handleUpdateTransaction(id, { [field]: value }),
      categoryId: () => {
        sendGTMEvent(GTMEventName.AssignedCategoryToTransaction);
        handleUpdateTransaction(id, { [field]: value });
      },
    };

    if (id) {
      const handler = fieldHandlers[field];
      handler(value, id);
    }

    return setNewTransactionChild((prev) => ({
      ...prev,
      [field]:
        field === 'amount'
          ? inverseNumberValue(String(value), fullAmount)
          : value,
    }));
  };

  const columns: TableColumnType<PartialTransaction>[] = [
    {
      key: 'beneficary',
      dataIndex: 'contactId',
      title: translate('transaction.split.columns.beneficary'),
      width: 120,
      ellipsis: true,
      align: 'center',
      render: (contactId, { id }) => (
        <FilterContacts
          defaultValue={contactId}
          placeholder={translate('transaction.form.beneficary.placeholder')}
          visualType="fill-rounded"
          onChange={(value: string) => {
            handleChangeValues('contactId', value, id);
          }}
        />
      ),
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: translate('transaction.split.columns.amount'),
      width: 120,
      align: 'center',
      ellipsis: true,
      render: (amount, { currencyCode, id }) => (
        <InputNumberMoney
          fullAmount={fullAmount}
          currencyCode={currencyCode}
          defaultValue={inverseNumberValue(amount, fullAmount)}
          onChange={(value) =>
            value !== null && handleChangeValues('amount', value, id)
          }
          size="large"
        />
      ),
    },
    {
      key: 'plDate',
      dataIndex: 'plDate',
      title: translate('transaction.split.columns.plDate'),
      width: 120,
      align: 'center',
      ellipsis: true,
      render: (date, { id }) => (
        <DatePickerHidden
          defaultValue={dayjs(date)}
          size="large"
          format="MMM YYYY"
          picker="month"
          onChange={(value) =>
            handleChangeValues(
              'plDate',
              getDateDefault(value!, DATE_ISO_FORMAT),
              id,
            )
          }
        />
      ),
    },
    {
      key: 'category',
      dataIndex: 'categoryId',
      title: translate('transaction.split.columns.category'),
      width: 120,
      align: 'center',
      ellipsis: true,
      render: (categoryId, { id }) => (
        <FilterCategories
          placeholder={translate('transaction.form.category.placeholder')}
          defaultValue={categoryId}
          visualType="fill-rounded"
          onChange={(value) => handleChangeValues('categoryId', value, id)}
        />
      ),
    },
    {
      key: 'details',
      dataIndex: 'details',
      title: translate('transaction.split.columns.details'),
      width: 100,
      align: 'center',
      ellipsis: true,
      render: (details, { id }) => (
        <InputHidden
          defaultValue={details}
          size="large"
          onChange={(e) => handleChangeValues('details', e.target.value, id)}
        />
      ),
    },
    {
      key: 'actions',
      dataIndex: 'id',
      render: (id) => (
        <Flex gap={8} justify="flex-end" align="center">
          {!id ? (
            <Button
              type="primary"
              size="small"
              onClick={handleCreateChildTransaction}
            >
              {translate('accounts.accountAdd.buttonAdd')}
            </Button>
          ) : null}
          <Button
            type="text"
            size="small"
            icon={<CloseOutlined />}
            onClick={() =>
              id ? handleDeleteTransaction(id) : handleCloseSplit(id)
            }
          />
        </Flex>
      ),
    },
  ];

  const handleCloseSplit = (id: string | null) => {
    setIsTransactionSplit(false);
    setTransactionsList((prev) =>
      prev?.filter((transaction) => transaction.id !== id),
    );
  };

  const handleDeleteTransaction = (id: string) => {
    if (!id) {
      setIsTransactionSplit(false);
      setTransactionsList((prevState) =>
        prevState?.filter((transaction) => transaction.id),
      );

      return;
    }

    modal.confirm({
      title: translate('transaction.split.messageRemove'),
      onOk: () =>
        removeTransaction(
          {
            parameter: {
              companyId: companyId!,
              id: id!,
            },
          },
          {
            onSuccess: () => {
              setTransactionsList((prevState) =>
                prevState?.filter((transaction) => transaction.id !== id),
              );
              onChangeChildrenCount(
                transactionsList?.filter((transaction) => transaction.id !== id)
                  ?.length || 0,
              );
              message.success(translate('transaction.success.remove'));
            },
          },
        ),
      autoFocusButton: null,
      okButtonProps: {
        size: 'small',
      },
      cancelButtonProps: {
        size: 'small',
      },
    });
  };

  const handleUpdateTransaction = (
    id: string,
    values: Schemas.UpdateTransactionDto,
  ) => {
    updateTransaction(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
        requestBody: values?.amount
          ? { amount: inverseNumberValue(values.amount, fullAmount) }
          : values,
      },
      {
        onSuccess: (updatedTransaction) => {
          if (updatedTransaction) {
            setTransactionsList((prevState) =>
              prevState?.map((transaction) => {
                if (transaction.id === updatedTransaction.id) {
                  return updatedTransaction;
                }

                return transaction;
              }),
            );
          }

          message.success(translate('transaction.success.update'));
        },
      },
    );
  };

  const handleCreateChildTransaction = () => {
    setIsTransactionSplit(false);

    createChildTransaction(
      {
        parameter: {
          id: transactionId!,
          companyId: companyId!,
        },
        requestBody: newTransactionChild as Schemas.CreateTransactionDto,
      },
      {
        onSuccess: (response) => {
          if (response) {
            const filteredTransactions = transactionsList?.filter(
              (transaction) => transaction.id,
            );
            setTransactionsList([
              ...filteredTransactions!,
              {
                ...response,
                contactId: response.contactId,
                categoryId: response.categoryId,
              },
            ]);
            setNewTransactionChild({});
            onChangeChildrenCount(filteredTransactions?.length || 0);
          }
        },
        onError: (error) => {
          const errorMessages = error.response?.data?.message || [];
          // TODO: translate error
          if (errorMessages.length) {
            message.error(errorMessages[0]);
          }
        },
      },
    );
  };

  const handleSplit = () => {
    setIsTransactionSplit(true);

    setTransactionsList((prevState) => [
      ...prevState!,
      {
        id: '',
        amount: 0,
      },
    ]);
  };

  useEffect(() => {
    const totalAmount = transactionsList?.reduce(
      (accumulator, currentValue) => accumulator + (currentValue.amount || 0),
      0,
    );

    setLeftToAssignAmount(
      parseInt(String((fullAmount! - totalAmount!) * 100)) / 100,
    );
  }, [transactions, fullAmount, transactionsList]);

  return (
    <Card
      containerId="transactions-split"
      title={
        <Flex align="center" justify="space-between">
          {translate('transaction.split.title')}

          <Flex gap={12}>
            {transactionsList?.length && leftToAssignAmount !== 0 ? (
              <S.AmountSummary>
                <S.AttentionIconWrapper>
                  <S.IconWarning />
                </S.AttentionIconWrapper>
                <S.AmountLeftText>
                  {translate('transaction.split.leftToAssign')}
                </S.AmountLeftText>
                <S.AmountLeftCount>
                  {getCurrencySymbol(currencyCode!)} {leftToAssignAmount}
                </S.AmountLeftCount>
              </S.AmountSummary>
            ) : null}
            <S.AddSplitButton
              type="primary"
              icon={<PlusOutlined />}
              size="small"
              onClick={handleSplit}
              disabled={isTransactionSplit}
            >
              {translate('transaction.split.buttonSplit')}
            </S.AddSplitButton>
          </Flex>
        </Flex>
      }
    >
      {contextHolder}

      <Table
        key={key}
        rowKey={({ id }) => id}
        dataSource={transactionsList}
        columns={columns}
        loading={updateLoading || createLoading || removeLoading}
        pagination={false}
        scroll={{ x: 720 }}
      />
    </Card>
  );
};

export default TransactionSplit;
