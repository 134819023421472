import { useEffect } from 'react';

import { useContactsController_findAll } from '@api-client/generated/ContactsController/findAll';
import { Schemas } from '@api-client/generated/types';
import { useAccount } from '@hooks';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';

type Contact = Schemas.Contact;

const usePaginatedContactsList = ({
  term,
  enabled,
}: {
  term: string;
  enabled?: boolean;
}) => {
  const { companyId } = useAccount();

  if (!companyId) {
    throw new Error('Company ID is required');
  }
  const { metadata, incrementPage, hasNextPage, plainData, appendData } =
    usePagination<Contact>(term);

  const { isFetching, data } = useContactsController_findAll({
    params: {
      companyId,
      term,
      page: metadata.currentPage,
    },
    config: {
      enabled,
    },
  });

  useEffect(() => {
    if (data) {
      appendData(data);
    }
  }, [appendData, data]);

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading: isFetching,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  return { rootRef, plainData, isFetching, hasNextPage, sentryRef };
};

export default usePaginatedContactsList;
