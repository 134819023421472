import { Flex, type FlexProps, Typography } from 'antd';

import styled from '@emotion/styled';

export const Header = styled(Flex)<FlexProps>`
  margin-bottom: 12px;
`;

export const Title = styled(Typography.Title)`
  && {
    font-size: 28px;
    margin-bottom: 0;
  }

  .ui-input {
    width: 100%;
    height: 35px;
    font-size: 28px;
  }
`;

export const Name = styled.div`
  width: 130px;
`;

export const SubTitle = styled(Typography.Title)`
  && {
    &.ui-typography {
      font-size: 18px;
      margin: 0;
    }
  }
`;
