import { Flex } from 'antd';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PageEmptyState } from '@entities';
import { useAppState } from '@hooks';
import { Button } from '@ui-kit/Button/Button';

import Instruction1Image from './assets/instruction1.svg?react';
import Instruction2Image from './assets/instruction2.svg?react';
import Instruction3Image from './assets/instruction3.svg?react';

const PayrollEmpty: FC = () => {
  const navigate = useNavigate();

  const { updateVisibilityPayrollPlaceholderMessage } = useAppState();

  const handleRedirectToChat = () => {
    updateVisibilityPayrollPlaceholderMessage(true);
    navigate('/chat');
  };

  return (
    <PageEmptyState
      title={t('payroll.empty.title')()}
      description={[
        t('payroll.empty.description.1'),
        t('payroll.empty.description.2'),
      ]}
      features={[
        t('payroll.empty.features.calculations'),
        t('payroll.empty.features.overtime'),
        t('payroll.empty.features.benefits'),
      ]}
      action={
        <>
          <Flex align="center" gap={15}>
            <Button type="primary" onClick={handleRedirectToChat}>
              {t('payroll.empty.import')()}
            </Button>
          </Flex>
        </>
      }
      instructions={[
        {
          title: <Trans i18nKey="payroll.empty.instructions.1" />,
          image: <Instruction1Image />,
        },
        {
          title: <Trans i18nKey="payroll.empty.instructions.2" />,
          image: <Instruction2Image />,
        },
        {
          title: <Trans i18nKey="payroll.empty.instructions.3" />,
          image: <Instruction3Image />,
        },
      ]}
    />
  );
};

export default PayrollEmpty;
