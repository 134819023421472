import { Flex, message } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { Loader } from '@components';
import { PaymentDocumentUnit } from '@constants';
import {
  PaymentDocumentDetailsForm,
  PaymentDocumentError,
  PaymentDocumentFileView,
  PaymentDocumentPotentialDuplicate,
  PaymentDocumentSkeleton,
} from '@entities';
import { useAccount, useTranslate } from '@hooks';
import { useGetDocumentById, useUpdateDocumentById } from '@hooks-api';
import { DATE_ISO_FORMAT, getDateDefault } from '@utils';

import * as S from './styled';

type TransactionFilePreviewModalProps = {
  type: PaymentDocumentUnit;
  open: boolean;
  onClose: () => void;
  file: Schemas.Document;
  onRefresh?: () => void;
  onListUpdate?: (file: Schemas.Document) => void;
  onCancelUpload?: (id: string) => void;
};

const TransactionFilePreviewModal: FC<TransactionFilePreviewModalProps> = ({
  type,
  open,
  onClose,
  file,
  onListUpdate,
  onCancelUpload,
}) => {
  const { companyId } = useAccount();
  const { translate } = useTranslate();
  const { mutate: updateFile } = useUpdateDocumentById();

  const {
    data: fileDetails,
    isPending: isLoadingFile,
    refetch: refetchFile,
  } = useGetDocumentById({
    params: {
      companyId: companyId!,
      id: file?.id,
    },
    config: {
      enabled: open,
    },
  });
  const handleDeleteFile = (id?: string) => {
    id && onCancelUpload?.(id);
  };

  const handleUpdateSelectedFile = (
    file: Schemas.Document | null,
    withClose = false
  ) => {
    file && onListUpdate?.(file);
    withClose && onClose();
    refetchFile();
  };

  const handleUpdateFile = (file: Schemas.Document) => {
    const formData = new FormData();
    const { documentMetadata } = file;

    if (documentMetadata) {
      if (documentMetadata.status) {
        formData.append(
          'documentMetadata[status]',
          file.documentMetadata.status!
        );
      }

      if (documentMetadata.number) {
        formData.append(
          'documentMetadata[number]',
          file.documentMetadata.number!
        );
      }

      if (documentMetadata.description) {
        formData.append(
          'documentMetadata[description]',
          file.documentMetadata.description!
        );
      }

      if (documentMetadata.amount) {
        formData.append(
          'documentMetadata[amount]',
          String(file.documentMetadata.amount)
        );
      }

      if (documentMetadata.currency) {
        formData.append(
          'documentMetadata[currency]',
          file.documentMetadata.currency!
        );
      }

      if (documentMetadata.issueDate) {
        formData.append(
          'documentMetadata[issueDate]',
          getDateDefault(file.documentMetadata.issueDate!, DATE_ISO_FORMAT)
        );
      }

      if (documentMetadata.dueDate) {
        formData.append(
          'documentMetadata[dueDate]',
          getDateDefault(file.documentMetadata.dueDate!, DATE_ISO_FORMAT)
        );
      }

      if (
        documentMetadata.isReviewed !== null &&
        documentMetadata.isReviewed !== undefined
      ) {
        formData.append(
          'documentMetadata[isReviewed]',
          String(documentMetadata.isReviewed)
        );
      }
    }

    if (file.contact) {
      formData.append('contactId', file.contact.id);
    }

    formData.append('documentMetadata[ignoreDuplicate]', JSON.stringify(true));

    updateFile(
      {
        parameter: {
          companyId: companyId!,
          id: file.id,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestBody: formData as any,
      },
      {
        onSuccess: (updatedFile) => {
          handleUpdateSelectedFile(updatedFile);
        },
        onError: (error) => {
          onCancelUpload?.(file.id);
          onClose();
          message.error(error.response?.data?.message);
        },
      }
    );
  };

  const handleProceed = () => {
    if (fileDetails) {
      handleUpdateFile(fileDetails);
      onClose();
      message.success(translate('transaction.documents.attachFileSuccess'));
    }
  };

  const handleCancelUpload = () => {
    handleDeleteFile(fileDetails?.id);
    onClose();
  };

  return (
    <S.PreviewModal width={966} open={open} footer={null} onCancel={onClose}>
      <S.Wrapper>
        {fileDetails?.hasError ? (
          <PaymentDocumentError
            type={type}
            onDelete={() => handleDeleteFile(fileDetails?.id)}
          />
        ) : (
          <>
            {fileDetails ? (
              <>
                <PaymentDocumentFileView file={fileDetails} />

                {fileDetails?.potentialDuplicate &&
                !fileDetails?.documentMetadata.ignoreDuplicate ? (
                  <PaymentDocumentPotentialDuplicate
                    type={type}
                    file={fileDetails?.potentialDuplicate}
                    onProceed={handleProceed}
                    onCancel={handleCancelUpload}
                  />
                ) : (
                  <>
                    {isLoadingFile ? (
                      <S.FullWidth gap={24}>
                        <PaymentDocumentSkeleton />
                        <S.FullWidth
                          align="center"
                          justify="center"
                          gap={6}
                          vertical
                        >
                          <S.UploadSkeletonTitle>
                            {translate(
                              'transaction.documents.attachFileProcessing'
                            )}
                          </S.UploadSkeletonTitle>
                          <S.UploadSkeletonDescription>
                            {translate(
                              'transaction.documents.attachFileProcessingDescription'
                            )}
                          </S.UploadSkeletonDescription>
                        </S.FullWidth>
                      </S.FullWidth>
                    ) : (
                      <S.FormWrapper>
                        <S.FormHeader align="center" justify="space-between">
                          <Flex justify="space-between">
                            <S.TitleDetails level={3}>
                              {translate('documentsPage.details')}
                            </S.TitleDetails>
                          </Flex>
                        </S.FormHeader>

                        <PaymentDocumentDetailsForm
                          type={type}
                          details={fileDetails}
                          onUpdate={(file) =>
                            handleUpdateSelectedFile(
                              file,
                              !fileDetails?.documentMetadata?.isReviewed
                            )
                          }
                          onAttachTransaction={handleUpdateSelectedFile}
                          onRefreshFile={refetchFile}
                          hasSwitches={false}
                        />
                      </S.FormWrapper>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {isLoadingFile ? (
                  <S.Loader>
                    <Loader />
                  </S.Loader>
                ) : (
                  <S.FullWidth gap={24}>
                    <PaymentDocumentSkeleton />
                    <S.FullWidth
                      align="center"
                      justify="center"
                      gap={6}
                      vertical
                    >
                      <S.UploadSkeletonTitle>
                        {translate(
                          'transaction.documents.attachFileProcessing'
                        )}
                      </S.UploadSkeletonTitle>
                      <S.UploadSkeletonDescription>
                        {translate(
                          'transaction.documents.attachFileProcessingDescription'
                        )}
                      </S.UploadSkeletonDescription>
                    </S.FullWidth>
                  </S.FullWidth>
                )}
              </>
            )}
          </>
        )}
      </S.Wrapper>
    </S.PreviewModal>
  );
};

export default TransactionFilePreviewModal;
