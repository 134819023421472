import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useTaskController_findAll } from '@api-client/generated/TaskController/findAll';
import { Schemas } from '@api-client/generated/types';
import { useAccount } from '@hooks';
import useSocketClient from '@hooks/useSocketClient';
import { GTMEventName, sendGTMEvent } from '@utils';

type ChatMessage = Schemas.ChatMessage;

type ResponseInData<T> = {
  data: T;
};

type CreatingMessageParams = {
  taskId: string;
  body: string;
  fileIds?: string[];
};

type SocketChatParams = {
  messages: ChatMessage[];
  isLoading: boolean;
  createMessage: (body: string) => void;
  deleteMessage: (id: string) => void;
  markAsSeenMessage: (ids: string[]) => void;
};

export const useSocketChat = ({
  taskId,
}: {
  taskId: string;
}): SocketChatParams => {
  const { account, companyId } = useAccount();

  if (!account || !companyId) {
    throw new Error('Account is required');
  }

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { socket } = useSocketClient({
    namespace: 'chat',
  });

  const { refetch } = useTaskController_findAll({
    params: {
      companyId,
    },
  });

  const debouncedRefetch = useDebouncedCallback(refetch, 500);

  const socketEventResponse = useCallback(
    (response: ResponseInData<ChatMessage[]>) => {
      setMessages(response.data);
      setIsLoading(false);
    },
    []
  );

  const socketEventCreated = useCallback(
    (response: ResponseInData<ChatMessage>) => {
      const message = response.data;

      if (taskId === message.taskId) {
        setMessages((prevState) => [...prevState, message]);
      }

      setIsLoading(false);
    },
    [taskId]
  );

  const socketEventDeleted = useCallback(
    (response: ResponseInData<ChatMessage>) => {
      if (taskId === response.data.taskId) {
        setMessages((prevState) =>
          prevState.map((message) =>
            message.id === response.data.id
              ? { ...message, ...response.data }
              : message
          )
        );
      }

      setIsLoading(false);
    },
    [taskId]
  );

  useEffect(() => {
    if (taskId) {
      setIsLoading(true);
      setMessages([]);

      socket.emit('messages:all', {
        taskId,
      });
    }
  }, [socket, taskId]);

  useEffect(() => {
    socket.on('response', socketEventResponse);
    socket.on('messages:created', socketEventCreated);
    socket.on('messages:deleted', socketEventDeleted);

    return () => {
      socket.off('response', socketEventResponse);
      socket.off('messages:created', socketEventCreated);
      socket.off('messages:deleted', socketEventDeleted);
    };
  }, [socket, socketEventResponse, socketEventCreated, socketEventDeleted]);

  const createMessage = useCallback(
    (body: string, fileIds: string[] = []) => {
      if (!taskId) {
        return;
      }

      const params: CreatingMessageParams = {
        taskId,
        body,
      };

      if (fileIds.length) {
        params['fileIds'] = fileIds;
      }

      setIsLoading(true);

      socket.emit('messages:create', params);

      sendGTMEvent(GTMEventName.WroteToChat);
    },
    [socket, taskId]
  );

  const deleteMessage = useCallback(
    (id: string) => {
      setIsLoading(true);

      socket.emit('messages:delete', {
        id,
      });
    },
    [socket]
  );

  const markAsSeenMessage = useCallback(
    (ids: string[]) => {
      socket.emit('messages:mark_as_seen', {
        ids,
      });

      debouncedRefetch();
    },
    [debouncedRefetch, socket]
  );

  return {
    messages,
    isLoading,
    createMessage,
    deleteMessage,
    markAsSeenMessage,
  };
};
