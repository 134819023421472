import { FC, MouseEvent } from 'react';

import { IconDownload, IconFile } from '@assets';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type PayslipsListItemProps = {
  name: string;
  isSelected: boolean;
  onClick: VoidFunction;
  onDownload: (e: MouseEvent<HTMLButtonElement>) => void;
};

const PayslipsListItem: FC<PayslipsListItemProps> = ({
  isSelected,
  name,
  onClick,
  onDownload,
}) => (
  <S.Item
    gap={16}
    isSelected={isSelected}
    align="center"
    justify="space-between"
    onClick={onClick}
  >
    <IconFile />

    <S.Title ellipsis={{ rows: 1 }}>{name}</S.Title>

    <Button
      type="link"
      variant="link"
      icon={<IconDownload />}
      size="middle"
      onClick={onDownload}
    />
  </S.Item>
);

export default PayslipsListItem;
