import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Control = styled(Flex)<FlexProps>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgBrightBlue};

  .ui-input {
    padding: 0;
    resize: none;
  }
`;

export const ControlLabel = styled(Flex)<FlexProps>`
  font-weight: 600;
`;
