import { Flex, Space } from 'antd';
import { FC, ReactNode } from 'react';

import { IconEye, IconLock } from '@assets';
import { PaymentDocumentUnit } from '@constants';

import { getTranslateByErrorCode, getTranslatesByType } from './helpers';
import * as S from './styled';

export type StatusUnit =
  | 'isDraft'
  | 'isProcessing'
  | 'isBooked'
  | 'isWrongDocument'
  | 'isCorruptedFile'
  | 'isToBeBooked'
  | 'isToReview';

export type VariantUnit =
  | 'default'
  | 'warning'
  | 'success'
  | 'error'
  | 'process'
  | 'gray';

type PaymentDocumentStatusBarDetailsProps = {
  type: PaymentDocumentUnit;
  status: StatusUnit;
  variant: VariantUnit;
  errorCode: string;
};

const getDetailsByStatus = (
  status: StatusUnit,
  type: PaymentDocumentUnit,
  errorCode: string
): {
  title: ReactNode;
  description: ReactNode | null;
} => {
  const translates = getTranslatesByType(type);
  const translateError = getTranslateByErrorCode(errorCode);

  if (status === 'isDraft') {
    return {
      title: translates.draftTitle,
      description: translates.draftDetails,
    };
  } else if (status === 'isProcessing') {
    return {
      title: translates.processingTitle,
      description: translates.processingDetails,
    };
  } else if (status === 'isToReview') {
    return {
      title: (
        <>
          {translates.toReviewTitle} <IconEye />
        </>
      ),
      description: translates.toReviewDetails,
    };
  } else if (status === 'isToBeBooked') {
    return {
      title: translates.toBeBookedTitle,
      description: null,
    };
  } else if (status === 'isBooked') {
    return {
      title: (
        <>
          {translates.bookedTitle} <IconLock />
        </>
      ),
      description: translates.bookedDetails,
    };
  } else if (status === 'isCorruptedFile') {
    return {
      title: translates.corruptedFileTitle,
      description: null,
    };
  } else if (status === 'isWrongDocument') {
    return {
      title: translates.wrongDocumentTitle,
      description: (
        <Flex vertical>
          {translates.documentErrorLabel} {translateError}
          <strong>{translates.wrongDocumentDetails}</strong>
        </Flex>
      ),
    };
  } else {
    return {
      title: translates.processingTitle,
      description: translates.processingDetails,
    };
  }
};

const PaymentDocumentStatusBarDetails: FC<
  PaymentDocumentStatusBarDetailsProps
> = ({ type, status, variant, errorCode }) => {
  const details = getDetailsByStatus(status, type, errorCode);

  return (
    <Space split={<S.Divider />} size={16}>
      <S.Title variant={variant} align="center" gap={8}>
        {details.title}
      </S.Title>

      {details.description && (
        <S.Description>{details.description}</S.Description>
      )}
    </Space>
  );
};
export default PaymentDocumentStatusBarDetails;
