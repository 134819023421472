import {
  FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useState,
} from 'react';

import { Schemas } from '@api-client/generated/types';

type Project = Schemas.Project;

type ProjectsContextProps = {
  projects: Project[];
  isEmptyList: boolean;
  initialProjects: (projects: Project[]) => void;
  filterBySearch: (value: string) => void;
  filterByStatus: (status: string) => void;
  deleteProject: (id: string) => void;
};

// TODO: move this thing out to enable fast refresh
// eslint-disable-next-line
export const ProjectsContext = createContext<ProjectsContextProps>(
  {} as ProjectsContextProps,
);

export const ProjectsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [projectsList, setProjectsList] = useState<Project[]>([]);
  const [projectsSourceList, setProjectsSourceList] = useState<Project[]>([]);

  const initialProjects = useCallback((projects: Project[]) => {
    setProjectsList(projects);
    setProjectsSourceList(projects);
  }, []);

  const filterBySearch = (value: string) =>
    setProjectsList(
      value.length > 0
        ? projectsSourceList.filter((project) =>
          project.name.toLowerCase().match(value),
        )
        : projectsSourceList,
    );

  const filterByStatus = (status: string) =>
    setProjectsList(
      status !== 'all'
        ? projectsSourceList.filter((project) => project.status === status)
        : projectsSourceList,
    );

  const deleteProject = (id: string) => {
    const filteredProjects = projectsList.filter(
      (project) => project.id !== id,
    );

    setProjectsList(filteredProjects);
    setProjectsSourceList(filteredProjects);
  };

  return (
    <ProjectsContext.Provider
      value={{
        projects: projectsList,
        isEmptyList: !projectsSourceList.length,
        initialProjects,
        filterBySearch,
        filterByStatus,
        deleteProject,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
