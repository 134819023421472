import { Flex, type FlexProps, InputNumber, type InputNumberProps } from 'antd';

import styled from '@emotion/styled';
import { Button } from '@ui-kit/Button/Button';

export const Container = styled(Flex)<FlexProps>`
  .ui-btn {
    &:first-of-type {
      border-radius: 6px 0 0 6px;
    }

    &:last-of-type {
      border-radius: 0 6px 6px 0;
    }
  }
`;

export const InputNumberInline = styled(InputNumber)<InputNumberProps>`
  width: 100%;
  height: 40px;
  border-radius: 0;
  margin: 0 -1px;
  z-index: 1;

  && {
    .ui-input-number-input {
      height: 38px;
      padding-inline: 0;
      text-align: center;
    }
  }
`;

export const Action = styled(Button)`
  && {
    &.ui-btn-icon-only {
      width: 40px;
      height: 40px;
      min-width: 40px;
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.strokeGrey};
      border-width: 1px;

      &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        z-index: 2;
      }
    }
  }
`;
