import { Flex } from 'antd';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useCompanyController_findOneById } from '@api-client/generated/CompanyController/findOneById';
import { useDocumentController_findOneById } from '@api-client/generated/DocumentController/findOneById';
import { Loader } from '@components';
import { InvoiceDocumentCard, InvoiceForm, PageMeta } from '@entities';
import { useAccount } from '@hooks';

import * as S from './styled';

const InvoiceGenerator: FC = () => {
  const { id: documentId } = useParams();
  const { companyId } = useAccount();

  const {
    data: document,
    isPending: isLoadingDocument,
    refetch: refetchDocument,
  } = useDocumentController_findOneById({
    params: {
      companyId: companyId!,
      id: documentId!,
    },
    config: {
      enabled: !!documentId,
      refetchOnWindowFocus: false,
    },
  });

  const {
    data: company,
    isPending: isLoadingCompany,
    refetch: refetchCompanyDetails,
  } = useCompanyController_findOneById({
    params: {
      companyId: companyId!,
    },
  });

  if (!document || isLoadingDocument || !company || isLoadingCompany) {
    return <Loader />;
  }

  return (
    <S.Container>
      <PageMeta title={t('invoiceGenerator.title')()} />

      <Flex align="flex-start" gap={30}>
        <InvoiceDocumentCard
          document={document}
          company={company}
          onRefetchCompany={refetchCompanyDetails}
          onAfterUpdate={refetchDocument}
        />

        <InvoiceForm
          document={document}
          company={company}
          onAfterUpdate={refetchDocument}
        />
      </Flex>
    </S.Container>
  );
};

export default InvoiceGenerator;
