import { Flex } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconCreditCardDanger, IconLock } from '@assets';
import { PaymentDocumentUnit } from '@constants';
import { Amount, PaymentDocumentCard } from '@entities';
import { useAccount } from '@hooks';
import { colors } from '@theme';

import * as S from './styled';

type PaymentDocumentInvoiceProps = {
  type: PaymentDocumentUnit;
  selectedId?: string;
  file: Schemas.Document;
  onSelectedInvoice: (id: string) => void;
};

const PaymentDocumentInvoice: FC<PaymentDocumentInvoiceProps> = ({
  type,
  selectedId,
  file,
  onSelectedInvoice,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { userAccess } = useAccount();

  const handleSelectInvoice = (id: string) => {
    navigate(`/${type}/${id}${search}`);
    onSelectedInvoice(id);
  };

  return (
    <PaymentDocumentCard
      isSelected={selectedId === file.id}
      isDraft={file.isDraft}
      isToReview={
        file.isRecognitionCompleted && !file.documentMetadata?.isReviewed
      }
      onClick={() => handleSelectInvoice(file.id)}
      vertical
    >
      <Flex align="center" justify="space-between" gap={16}>
        <S.Name data-testid="name">
          {file.isDraft && (
            <S.ExtraLabel>{t('income.header.draft.title')()}: </S.ExtraLabel>
          )}

          {file?.contact?.name || file.name}
        </S.Name>

        {file.adminStatus === 'processed' && (
          <S.Booked align="center" gap={8}>
            {t('income.header.booked.title')()} <IconLock />
          </S.Booked>
        )}
      </Flex>

      {file.documentMetadata?.description && (
        <S.Description ellipsis>
          {file.documentMetadata?.description}
        </S.Description>
      )}

      <Flex justify="space-between" align="center">
        <Flex align="center" gap={8}>
          {!file.transactions?.length &&
            userAccess?.transactions &&
            !file.isRecognitionCompleted && (
              <IconCreditCardDanger color={colors.red500} />
            )}

          {file.documentMetadata?.amount && (
            <S.Amount>
              <Amount
                amount={file.documentMetadata.amount!}
                currencyCode={file.documentMetadata.currency!}
              />
            </S.Amount>
          )}
        </Flex>

        {file.documentMetadata?.issueDate && (
          <S.Date data-testid="date">
            {dayjs(file.documentMetadata.issueDate).format('DD.MM.YYYY')}
          </S.Date>
        )}
      </Flex>
    </PaymentDocumentCard>
  );
};

export default PaymentDocumentInvoice;
