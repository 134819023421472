import { Flex, Segmented, type TimeRangePickerProps, Typography } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAccountsController_getAccounts } from '@api-client/generated/AccountsController/getAccounts';
import { Parameter$TransactionController_getStatsByCategory } from '@api-client/generated/client';
import { IconGraph, IconGrid } from '@assets';
import { Loader, Select } from '@components';
import { ReportType } from '@constants';
import {
  PageMeta,
  RangePicker,
  ReportCharts,
  ReportEmpty,
  ReportOverview,
  ReportTable,
} from '@entities';
import { useAccount, useBreakDownPeriods, useTranslate } from '@hooks';
import {
  useGetTransactionsStatsByCategory,
  useGetTransactionsStatsByTime,
} from '@hooks-api';
import {
  DATE_ISO_FORMAT,
  GTMEventName,
  getDateDefault,
  getDateEndOf,
  getDateStartOf,
  getSearchParams,
  sendGTMEvent,
  setSearchParams,
} from '@utils';

import * as S from './styled';

type BreakDown =
  Parameter$TransactionController_getStatsByCategory['breakDown'];

const TAB_TABLE_KEY = 'tab-table';
const TAB_GRAPHS_KEY = 'tab-graphs';
const REPORT_TYPE = ReportType.ProfitsAndLosses;

type TabKey = typeof TAB_TABLE_KEY | typeof TAB_GRAPHS_KEY;

const { Title } = Typography;

const initialDatePeriod = {
  dateFrom: getDateStartOf('', 'year'),
  dateTo: getDateEndOf('', 'year'),
};

const Report: FC = () => {
  const { translate } = useTranslate();
  const { companyId } = useAccount();
  const navigate = useNavigate();

  const { search } = useLocation();

  const { defaultPeriod, periods } = useBreakDownPeriods();

  const { data: accounts, isPending: isAccountsListLoading } =
    useAccountsController_getAccounts({
      params: {
        companyId: companyId!,
      },
    });

  const [currentTabKey, setCurrentTabKey] = useState<TabKey>(TAB_TABLE_KEY);

  const [filterPeriod, setFilterPeriod] = useState<BreakDown>(defaultPeriod);
  const [filterDatePeriod, setFilterDatePeriod] = useState<{
    dateFrom: Dayjs | null;
    dateTo: Dayjs | null;
  }>(initialDatePeriod);

  const isTabTable = currentTabKey === TAB_TABLE_KEY;
  const isTabGraphs = currentTabKey === TAB_GRAPHS_KEY;

  const { data: statsCategoryIn, refetch: refetchStatsIn } =
    useGetTransactionsStatsByCategory({
      params: {
        companyId: companyId!,
        dateFrom: getDateDefault(filterDatePeriod.dateFrom!, DATE_ISO_FORMAT),
        dateTo: getDateDefault(filterDatePeriod.dateTo!, DATE_ISO_FORMAT),
        type: REPORT_TYPE,
        breakDown: filterPeriod,
        flowType: 'money_in',
      },
      config: {
        enabled: !!filterDatePeriod.dateFrom && !!filterDatePeriod.dateTo,
      },
    });

  const { data: statsCategoryOut, refetch: refetchStatsOut } =
    useGetTransactionsStatsByCategory({
      params: {
        companyId: companyId!,
        dateFrom: getDateDefault(filterDatePeriod.dateFrom!, DATE_ISO_FORMAT),
        dateTo: getDateDefault(filterDatePeriod.dateTo!, DATE_ISO_FORMAT),
        type: REPORT_TYPE,
        breakDown: filterPeriod,
        flowType: 'money_out',
      },
      config: {
        enabled: !!filterDatePeriod.dateFrom && !!filterDatePeriod.dateTo,
      },
    });

  const { data: statsTime, refetch: refetchStatsTime } =
    useGetTransactionsStatsByTime({
      params: {
        companyId: companyId!,
        dateFrom: getDateDefault(filterDatePeriod.dateFrom!, DATE_ISO_FORMAT),
        dateTo: getDateDefault(filterDatePeriod.dateTo!, DATE_ISO_FORMAT),
        type: REPORT_TYPE,
        breakDown: filterPeriod,
      },
      config: {
        enabled: !!filterDatePeriod.dateFrom && !!filterDatePeriod.dateTo,
      },
    });

  const { dateFrom, dateTo, expanded } = getSearchParams<{
    expanded: string[] | undefined;
    dateTo?: string;
    dateFrom?: string;
  }>(search);

  const handleChangeTab = (key: TabKey) => {
    setCurrentTabKey(key);

    refetchStatsIn();
    refetchStatsOut();
    refetchStatsTime();
  };

  const handleChangePeriod: TimeRangePickerProps['onChange'] = (dates) => {
    if (dates) {
      const [dateFrom, dateTo] = dates;

      setFilterDatePeriod({
        dateFrom,
        dateTo,
      });

      navigate(
        `/report?${setSearchParams({
          expanded,
          dateTo: dayjs(dateTo).format('DD.MM.YYYY'),
          dateFrom: dayjs(dateFrom).format('DD.MM.YYYY'),
        })}`
      );
    }
  };

  useEffect(() => {
    sendGTMEvent(GTMEventName.OpenedReports);
  }, []);

  useEffect(() => {
    if (dateFrom && dateTo) {
      navigate(`/report?${setSearchParams({ expanded, dateFrom, dateTo })}`);
      setFilterDatePeriod({
        dateFrom: dayjs(dateFrom, 'DD.MM.YYYY'),
        dateTo: dayjs(dateTo, 'DD.MM.YYYY'),
      });
    }
  }, [dateFrom, dateTo, expanded, navigate]);

  if (isAccountsListLoading) {
    return <Loader />;
  }

  if (!accounts?.length) {
    return <ReportEmpty />;
  }

  return (
    <Flex gap={30} vertical>
      <PageMeta title={translate('report.title')} />

      <S.Header>
        <Title>{translate('report.title')}</Title>
      </S.Header>

      {/*TODO comment out when graph design will be ready to introduce*/}
      {/*{isTabGraphs && (*/}
      {/*  <Flex gap={30}>*/}
      {/*    <ReportCardStatistic*/}
      {/*      direction="up"*/}
      {/*      label={translate('report.income.title')}*/}
      {/*      categories={statsCategoryIn}*/}
      {/*    />*/}

      {/*    <ReportCardStatistic*/}
      {/*      direction="down"*/}
      {/*      label={translate('report.expenses.title')}*/}
      {/*      categories={statsCategoryOut}*/}
      {/*    />*/}
      {/*  </Flex>*/}
      {/*)}*/}

      <Flex align="center" justify="space-between">
        <Flex gap={24}>
          <Segmented
            value={currentTabKey}
            onChange={handleChangeTab}
            options={[
              {
                label: (
                  <S.SegmentedItem align="center" gap={8}>
                    <IconGrid />
                    {translate('report.tabs.table')}
                  </S.SegmentedItem>
                ),
                value: TAB_TABLE_KEY,
              },
              {
                label: (
                  <S.SegmentedItem align="center" gap={8}>
                    <IconGraph />
                    {translate('report.tabs.graphs')}
                  </S.SegmentedItem>
                ),
                value: TAB_GRAPHS_KEY,
              },
            ]}
          />

          <S.Divider />

          <RangePicker
            defaultValue={[filterDatePeriod.dateFrom, filterDatePeriod.dateTo]}
            value={[filterDatePeriod.dateFrom, filterDatePeriod.dateTo]}
            onChange={handleChangePeriod}
          />
        </Flex>

        <Select
          width={188}
          value={filterPeriod}
          options={periods}
          size="large"
          onChange={setFilterPeriod}
        />
      </Flex>

      {isTabTable && statsCategoryIn && statsCategoryOut && (
        <ReportTable
          dateFrom={filterDatePeriod.dateFrom}
          dateTo={filterDatePeriod.dateTo}
          datePeriod={filterPeriod}
          categoryIn={statsCategoryIn}
          categoryOut={statsCategoryOut}
          showByPlDate={REPORT_TYPE}
        />
      )}

      {isTabGraphs && (
        <>
          {statsTime && (
            <ReportOverview
              dateFrom={filterDatePeriod.dateFrom}
              dateTo={filterDatePeriod.dateTo}
              filterPeriod={filterPeriod}
              dataSource={statsTime}
            />
          )}

          {statsCategoryOut && (
            <ReportCharts
              dateFrom={filterDatePeriod.dateFrom}
              dateTo={filterDatePeriod.dateTo}
              title={translate('report.expenses.title')}
              dataSource={statsCategoryOut}
              flowType="out"
            />
          )}

          {statsCategoryIn && (
            <ReportCharts
              dateFrom={filterDatePeriod.dateFrom}
              dateTo={filterDatePeriod.dateTo}
              title={translate('report.income.title')}
              dataSource={statsCategoryIn}
              flowType="in"
            />
          )}
        </>
      )}
    </Flex>
  );
};

export default Report;
