import { DATE_ISO_FORMAT, getDateDefault } from '@utils';
import dayjs, { Dayjs } from 'dayjs';
import {z} from 'zod';

const convertDate = (date: Dayjs) => getDateDefault(date, DATE_ISO_FORMAT);

/** query params schema for transations page */
export const queryParamsSchema = z.object({
  term: z.optional(z.union([z.string(), z.number()])).default(''),
  categoryIds: z.optional(z.array(z.string())).default([]),
  contactIds: z.optional(z.array(z.string())).default([]),
  accountIds: z.optional(z.array(z.string())).default([]),
  projectIds: z.optional(z.array(z.string())).default([]),
  onlyWithoutDocuments: z.boolean().default(false),
  showByPlDate: z.boolean().default(false),
  dateFrom: z.optional(z.string()).default(''),
  dateTo: z.optional(z.string()).default(''),
  sortBy: z.optional(z.enum([ 'amount', 'contact', 'category', 'date', 'plDate'])),
  sortDirection: z.optional(z.enum(["ASC", "DESC"])),
  page: z.number().default(1),
})
export type QueryParams = z.infer<typeof queryParamsSchema>;

export const filterFormSchema = queryParamsSchema
  .omit({page: true, sortDirection: true, sortBy: true, dateFrom: true, dateTo: true})
  .extend({ date: z.array(z.custom<Dayjs>(dayjs)).nullable().default(null) });
export type FilterForm = z.infer<typeof filterFormSchema>;

export const filterFormToQuery = filterFormSchema.merge(queryParamsSchema).transform<QueryParams>((schema) => ({
  ...schema,
  dateFrom: schema.date ? convertDate(schema.date[0]) : '',
  dateTo: schema.date ? convertDate(schema.date[1]) : '',
  date: null, // figure out how omit
}));

export const filterFormFromQuery = queryParamsSchema
  .omit({page: true, sortDirection: true, sortBy: true})
  .transform<FilterForm>((schema) => ({
    ...schema,
    dateFrom: null,
    dateTo: null,
    date:
      schema.dateFrom && schema.dateTo
        ? [dayjs(schema.dateFrom), dayjs(schema.dateTo)] as Dayjs[]
        : null,
  }));