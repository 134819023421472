import { TableColumnType } from 'antd';
import { useEffect, useMemo } from 'react';

import { useStorage } from './useStorage';

interface Params<T> {
  columns?: TableColumnType<T>[];
  excludeColumns?: Set<TableColumnType<T>['key']>;
  page: string;
}

function useSavedColumns<T>({ page, columns = [], excludeColumns }: Params<T>) {
  const columnsToHide = useMemo(
    () => columns.filter((col) => !excludeColumns?.has(col.key)),
    [columns, excludeColumns]
  );

  const initiallyShownColumns = useMemo(
    () =>
      columnsToHide.reduce(
        (acc, col) => ({
          ...acc,
          [col.key as string]: true,
        }),
        {} as Record<string, boolean>
      ),
    [columnsToHide]
  );

  const [shownColumns, setShownColumns] = useStorage(
    page,
    initiallyShownColumns
  );

  useEffect(() => {
    // previously it have been saved as an array, so we need to transform array into object
    if (Array.isArray(shownColumns)) {
      setShownColumns(initiallyShownColumns);
    }
  }, []); // eslint-disable-line

  const newColumns = useMemo(
    () =>
      columns.map((item) => ({
        ...item,
        hidden:
          item.key && columnsToHide.some((col) => col.key == item.key)
            ? !shownColumns[item.key as string]
            : false,
      })),
    [columns, shownColumns, columnsToHide]
  );

  const dropdownOptions = useMemo(
    () =>
      columnsToHide.map((col) => ({
        // antd types are very complicated
        label: col.title as string,
        value: col.key as string,
      })),
    [columnsToHide]
  );

  return {
    columns: newColumns,
    shownColumns,
    setShownColumns,
    dropdownOptions,

    /** @deprecated use columns instead */
    newColumns,
    /** @deprecated use shownColumns instead */
    checkedList: shownColumns,
    /** @deprecated use setShownColumns instead */
    setCheckedList: setShownColumns,
    /** @deprecated use dropdownOptions instead */
    options: dropdownOptions,
  };
}

export default useSavedColumns;