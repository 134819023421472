import { Table, TableProps, theme } from 'antd';
import { ColumnType, SortOrder, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { useMemo } from 'react';

export type SortDirection = 'ASC' | 'DESC';
export type TableSort<SortBy> = {
  sortBy?: SortBy;
  sortDirection?: SortDirection;
};

type OwnProps<T, S extends ColumnType<T>['key']> = {
  sortableColumns?: Array<S>;
  hiddenColumns?: Array<keyof T>;
  alwaysDisplayedColumns?: Array<string>;
  initialSort?: TableSort<S> | null;
  storageKey: string;
  onChangeSort?: (sort: TableSort<S> | null) => void;
}
type Props<T, S extends ColumnType<T>['key']> = TableProps<T> & OwnProps<T, S>;

function toAntdSort(direction: SortDirection): SortOrder {
  return direction === 'ASC' ? 'ascend' : 'descend';
}

function fromAntdSort(direction: SortOrder): SortDirection {
  return direction === 'ascend' ? 'ASC' : 'DESC';
}

function TableWrapper<
  Entity extends Record<string, unknown>,
  SortableFields extends string,
>(props: Props<Entity, SortableFields>) {
  function handleChange(
    _pagination: TablePaginationConfig,
    _filters: unknown,
    sorter: SorterResult<Entity> | SorterResult<Entity>[]
  ): void {
    if (!Array.isArray(sorter)) {
      const sort: TableSort<SortableFields> | null =
        sorter.columnKey && sorter.order
          ? {
              sortBy: sorter.columnKey as SortableFields,
              sortDirection: fromAntdSort(sorter.order),
            }
          : null;
      if (props.onChangeSort) {
        props.onChangeSort(sort);
      }
    }
  }

  const {token} = theme.useToken();

  const columns = useMemo(() => {
    if (!props.columns) return [];

    const columnsWithSort = props.columns.map((col) => {
      if (props.initialSort && col.key === props.initialSort.sortBy) {
        col.sortOrder = toAntdSort(props.initialSort.sortDirection!);
      }
      if (props.sortableColumns?.includes(col.key as SortableFields)) {
        col.sorter = true;
        col.sortIcon = getCustomSorterIcon(token)
      }
      if (props.hiddenColumns?.includes(col.key as string)) {
        col.hidden = true 
      }
      return col;
    });

    return columnsWithSort;
  }, [props.columns]); // eslint-disable-line

  return (
    <Table<Entity>
      {...props}
      onChange={handleChange}
      columns={columns}
    >
      {props.children}
    </Table>
  );
}

export default TableWrapper;

type CustomSorterIconsProps = {
  sortOrder: SortOrder; 
}

function getCustomSorterIcon(tokens: ReturnType<typeof theme['useToken']>['token']) {
  return (props: CustomSorterIconsProps) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4150_63342)">
        <path d="M5.625 10.875L9 15.75L12.375 10.875" stroke={props.sortOrder === 'descend' ? tokens.colorPrimary : 'currentColor'} strokeWidth="2" strokeLinejoin="round"/>
        <path d="M5.625 7.125L9 2.25L12.375 7.125" stroke={props.sortOrder === 'ascend' ? tokens.colorPrimary : 'currentColor'} strokeWidth="2" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_4150_63342">
          <rect width="18" height="18" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}