import { DefaultOptionType } from 'antd/es/select';
import { FC } from 'react';
import { IntersectionObserverHookRefCallback } from 'react-intersection-observer-hook';

import { Schemas } from '@api-client/generated/types';
import { IconClose, IconTrash } from '@assets';
import { AttachDropdownItem } from '@entities';
import { useTranslate } from '@hooks';
import { colors } from '@theme';

import * as S from './styled';

type AttachTemplateItemProps = {
  isListLoading: boolean;
  documentsList: Schemas.Transaction[];
  sentryRef: IntersectionObserverHookRefCallback;
  hasNextPage?: boolean;
  onSearch: (value: string) => void;
  onAttach: (transaction: Schemas.Transaction) => void;
  onDelete: () => void;
};

const AttachTemplateItem: FC<AttachTemplateItemProps> = ({
  documentsList,
  isListLoading,
  sentryRef,
  hasNextPage,
  onAttach,
  onSearch,
  onDelete,
}) => {
  const { translate } = useTranslate();
  const options = documentsList.map((document) => ({
    ...document,
    label: document.contact?.name,
    value: document.id,
  }));

  const dropdownRender = (menu: React.ReactNode) => (
    <div className="ui-attach-select-dropdown">{menu}</div>
  );

  const optionRender = (
    oriOption: DefaultOptionType,
    { index }: { index: number }
  ) => (
    <>
      <AttachDropdownItem
        item={oriOption.data as Schemas.Document}
        width="100%"
        height="121px"
        key={oriOption.key}
      />
      {index === options.length - 1 && hasNextPage && (
        <div ref={sentryRef}></div>
      )}
    </>
  );

  const onChange = (
    _: string,
    option?: DefaultOptionType | DefaultOptionType[]
  ) => onAttach?.(option as Schemas.Transaction);

  const clearOptions = {
    clearIcon: <IconClose width={20} height={20} />,
  };

  return (
    <S.Wrapper vertical>
      <S.Content gap={16} vertical>
        <S.Title>{translate('income.selectTransactionText')}</S.Title>

        <S.ListSelect
          options={options}
          onSearch={onSearch}
          className="ui-attach-select"
          placeholder={translate('common.cornerCard.searchPlaceholder')}
          onChange={onChange}
          dropdownRender={dropdownRender}
          optionRender={optionRender}
          allowClear={clearOptions}
          loading={isListLoading}
          filterOption={false}
          defaultOpen
          showSearch
          autoFocus
        />
      </S.Content>

      <S.Footer justify="flex-start" align="center">
        <S.DetachButton type="text" onClick={onDelete}>
          <IconTrash color={colors.error} />
          {translate('common.actions.delete')}
        </S.DetachButton>
      </S.Footer>
    </S.Wrapper>
  );
};

export default AttachTemplateItem;
