import { Flex } from 'antd';
import { FC, KeyboardEvent, useEffect, useState } from 'react';

import { IconCheck, IconEditUnderline } from '@assets';
import { InputBorderless } from '@components';
import { colors } from '@theme';
import { Button } from '@ui-kit/Button/Button';

import * as S from './styled';

type InvoiceDocumentHeaderProps = {
  name: string;
  companyName?: string;
  onChange: (documentName: string, documentNumber: string) => void;
};

const InvoiceDocumentHeader: FC<InvoiceDocumentHeaderProps> = ({
  name,
  companyName,
  onChange,
}) => {
  const [isEditName, setIsEditName] = useState(false);
  const [documentNumber, setDocumentNumber] = useState('');

  const replacedDocumentNumber = name?.replace(
    t('invoiceGenerator.document.name')(),
    ''
  );

  useEffect(() => {
    if (replacedDocumentNumber) {
      setDocumentNumber(replacedDocumentNumber);
    }
  }, [replacedDocumentNumber]);

  const handleUpdateName = () => {
    if (!isEditName) {
      setIsEditName(true);
    } else {
      documentNumber &&
        onChange(
          `${t('invoiceGenerator.document.name')()}${documentNumber}`,
          documentNumber
        );

      setIsEditName(false);
    }
  };

  const handleUpdateByKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleUpdateName();
    }
  };

  return (
    <S.Header align="flex-start" justify="space-between">
      <S.Title level={2}>
        <Flex gap={2} align="center">
          <Flex align="center">
            {t('invoiceGenerator.document.name')()}

            {!isEditName ? (
              replacedDocumentNumber
            ) : (
              <S.Name>
                <InputBorderless
                  value={documentNumber}
                  onKeyDown={handleUpdateByKeyPress}
                  onChange={(e) => setDocumentNumber(e.target.value)}
                />
              </S.Name>
            )}
          </Flex>

          <Button
            type="link"
            icon={
              isEditName ? (
                <IconCheck color={colors.primary} />
              ) : (
                <IconEditUnderline color={colors.primary} />
              )
            }
            onClick={handleUpdateName}
          />
        </Flex>
      </S.Title>

      <S.SubTitle level={5}>{companyName}</S.SubTitle>
    </S.Header>
  );
};

export default InvoiceDocumentHeader;
