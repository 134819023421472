import { Flex, Form, type FormProps, Typography } from 'antd';



import styled from '@emotion/styled';


export const Container = styled.div<{ inversionTheme?: boolean }>`
  .ui-btn {
    &.ui-btn-secondary {
      &:not(:hover) {
        background-color: ${({ theme, inversionTheme }) =>
          inversionTheme ? theme.colors.bgDarkGrey : theme.colors.bgGrey};
      }
    }
  }

  .ui-input-affix-wrapper {
    &.ui-input-filled {
      background-color: ${({ theme, inversionTheme }) =>
        inversionTheme ? theme.colors.white : theme.colors.bgGrey};
      border-color: ${({ theme, inversionTheme }) =>
        inversionTheme ? theme.colors.strokeDarkGrey : 'transparent'};

      &:hover {
        background-color: ${({ theme, inversionTheme }) =>
          inversionTheme ? theme.colors.white : theme.colors.bgGrey};
      }
    }
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  margin: 0 8px;
`;

export const ColumnsGrid = styled.div`
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
`;

export const ControlFlexGroup = styled(Flex)`
  flex-wrap: nowrap;
  gap: 12px;
  align-items: center;
`;

export const FormControl = styled(Form.Item)<FormProps>`
  && {
    margin-bottom: 0;
  }
`;

export const DrawerContainer = styled.div`
  height: calc(100vh - 112px);
`;

export const DrawerTitle = styled(Typography.Title)`
  &.ui-typography {
    font-size: 28px;
    margin-bottom: 0;
  }
`;

export const SwitchLabel = styled.div`
  font-weight: 600;
`;