import { Col, DatePicker, Flex, Form, Row, Tooltip, message } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect } from 'react';

import { Schemas } from '@api-client/generated/types';
import { BankDefaultLogotype } from '@assets';
import { Card } from '@components';
import { FilterCategories, FilterProjects } from '@entities';
import { useAccount } from '@hooks';
import { useUpdateTransactionById } from '@hooks-api';
import { GTMEventName, sendGTMEvent } from '@utils';

import { ContactName } from './ContactName';
import * as S from './styled';

type TransactionCardDetailsProps = {
  icon?: string;
  transaction: Schemas.Transaction;
  childTransactionsCount: number;
  onRefresh: () => void;
  onUpdateTransaction?: VoidFunction;
};

const TransactionCardDetails: FC<TransactionCardDetailsProps> = ({
  icon = BankDefaultLogotype,
  transaction,
  childTransactionsCount,
  onRefresh,
  onUpdateTransaction,
}) => {
  const { companyId } = useAccount();

  if (!companyId) {
    throw new Error('Company ID is not defined');
  }

  const [form] = Form.useForm();

  const { mutate: updateTransaction } = useUpdateTransactionById();

  useEffect(() => {
    form.setFieldsValue({
      plDate: dayjs(transaction.plDate),
      category: transaction.category ? transaction.category.id : null,
      notes: transaction.notes,
      project: transaction.project ? transaction.project.id : null,
    });
  }, [form, transaction]);

  useEffect(() => {
    if (transaction.isIgnoredForPL) {
      form.setFieldValue('plDate', undefined);
    }
  }, [form, transaction]);

  const onFinish = <T extends keyof Schemas.UpdateTransactionDto>(
    key: T,
    value: Schemas.UpdateTransactionDto[T]
  ) => {
    updateTransaction(
      {
        parameter: {
          id: transaction.id,
          companyId,
        },
        requestBody: {
          [key]: value,
        },
      },
      {
        onSuccess: () => {
          if (key === 'category') {
            sendGTMEvent(GTMEventName.AssignedCategoryToTransaction);
          }

          if (key === 'project') {
            sendGTMEvent(GTMEventName.AssignedProjectToTransaction);
          }

          onUpdateTransaction?.();
          message.success(t('transaction.success.update')());
          onRefresh();
        },
      }
    );
  };

  const assignContact = useCallback(
    (contact: Schemas.Contact) => {
      updateTransaction(
        {
          parameter: {
            id: transaction.id,
            companyId,
          },
          requestBody: {
            contact,
          },
        },
        {
          onSuccess: () => {
            onUpdateTransaction?.();
            message.success(t('transactionsPage.contactWasChanged')());
            onRefresh();
          },
        }
      );
    },
    [
      companyId,
      onRefresh,
      onUpdateTransaction,
      transaction.id,
      updateTransaction,
    ]
  );

  const onRulesSubmit = useCallback(() => {
    onUpdateTransaction?.();
    onRefresh();
  }, [onRefresh, onUpdateTransaction]);

  const { amount, currencyCode } = transaction;

  return (
    <Card padding={0} minHeight={1} transparentBorder>
      <S.Details gap={40} vertical>
        <Flex gap={16} align="flex-start">
          <S.FullWidth gap={6} vertical>
            <S.Breadcrumbs>
              {`${t('transaction.transactionCaps')()} • ${transaction.account.name}`}
            </S.Breadcrumbs>
            <div>
              <ContactName
                contact={transaction.contact}
                rawContactName={transaction.rawContactName}
                assignContact={assignContact}
                onRulesSubmit={onRulesSubmit}
              />
            </div>
            <S.Description>{transaction.details}</S.Description>
          </S.FullWidth>
          <S.LogoWrapper align="center" justify="center">
            <S.Logo src={icon} alt={transaction.account.name} />
          </S.LogoWrapper>
        </Flex>
        <Flex justify="space-between">
          <S.Amount amount={amount} currencyCode={currencyCode} />
          <S.DateText date={transaction.bookingDate} format="date" />
        </Flex>
      </S.Details>
      <S.Content>
        <Form layout="vertical" colon={false} requiredMark={false} form={form}>
          <Row gutter={[12, 0]}>
            <Col span={8}>
              <S.Item
                name="category"
                label={t('transaction.form.category.label')()}
              >
                <FilterCategories
                  variant="outlined"
                  placeholder={t('transaction.form.category.placeholder')()}
                  onChange={(value) => onFinish('category', { id: value })}
                  withoutContainer
                  withGroups
                />
              </S.Item>
            </Col>

            <Col span={8}>
              <Tooltip
                placement="bottom"
                title={
                  transaction.isIgnoredForPL ? (
                    <S.TooltipTitleBox>
                      {t('transaction.settings.ecludedPlDate')()}
                    </S.TooltipTitleBox>
                  ) : null
                }
                destroyTooltipOnHide
              >
                <S.Item
                  name="plDate"
                  label={t('transaction.form.plDate.label')()}
                >
                  <DatePicker
                    placeholder={t('transaction.form.plDate.placeholder')()}
                    size="large"
                    picker="month"
                    format="MMM YYYY"
                    onChange={(value) =>
                      onFinish(
                        'plDate',
                        value ? dayjs(value).toISOString() : undefined
                      )
                    }
                    disabled={
                      !!childTransactionsCount || transaction.isIgnoredForPL
                    }
                  />
                </S.Item>
              </Tooltip>
            </Col>

            <Col span={8}>
              <S.Item
                name="project"
                label={t('transaction.form.project.label')()}
              >
                <FilterProjects
                  variant="outlined"
                  placeholder={t('transaction.form.project.placeholder')()}
                  onChange={(value) => onFinish('project', { id: value })}
                  withoutContainer
                />
              </S.Item>
            </Col>
          </Row>
        </Form>
      </S.Content>
    </Card>
  );
};

export default TransactionCardDetails;
