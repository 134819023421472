import { Flex } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import { getAddressLine } from '@utils';

import * as S from './styled';

type InvoiceDocumentDetailsFromProps = {
  name: string;
  details: Schemas.CompanyDetails;
  onAction: VoidFunction;
};

const InvoiceDocumentDetailsFrom: FC<InvoiceDocumentDetailsFromProps> = ({
  name,
  details,
  onAction,
}) => (
  <S.Details gap={8} isBordered={!!name && !!details?.vatId} vertical>
    <S.DetailsTitle>{t('invoiceGenerator.document.from')()}</S.DetailsTitle>

    <Flex align="flex-start" vertical>
      <S.DetailsType>{name}</S.DetailsType>

      {details?.vatId ? (
        <div>
          {t('invoiceGenerator.document.vatId')()}: {details.vatId}
        </div>
      ) : (
        <S.Add onClick={onAction}>
          <IconPlus width={20} height={20} />
          {t('invoiceGenerator.document.buttonAddVatId')()}
        </S.Add>
      )}

      {details?.businessLicenseNumber && (
        <div>
          {t('invoiceGenerator.document.permitNumber')()}:{' '}
          {details.businessLicenseNumber}
        </div>
      )}

      {details?.rscNumber && (
        <div>
          {t('invoiceGenerator.document.registrationNumber')()}:{' '}
          {details.rscNumber}
        </div>
      )}

      <div>{getAddressLine(details?.address)}</div>

      {details?.vatId && (
        <S.Edit onClick={onAction}>
          {t('invoiceGenerator.document.buttonEdit')()}
        </S.Edit>
      )}
    </Flex>
  </S.Details>
);

export default InvoiceDocumentDetailsFrom;
