import { Button, Tooltip } from 'antd';
import { FC } from 'react';

import { IconSettingsSemiBold } from '@assets';
import { Card } from '@components';
import { SettingsCard } from '@entities';
import { useClickOutside } from '@hooks';
import { colors } from '@theme';

import * as S from './styled';

type TransactionSettingsButtonProps = {
  initialFileless: boolean;
  initialPlDate: boolean;
  isFilelessOpen: boolean;
  onToggle: () => void;
  onChange: (key: 'isFileless' | 'isIgnoredForPL', value: boolean) => void;
};

const TransactionSettingsButton: FC<TransactionSettingsButtonProps> = ({
  onChange,
  onToggle,
  isFilelessOpen,
  initialFileless,
  initialPlDate,
}) => {
  const ref = useClickOutside(onToggle);

  return (
    <S.Container align="center">
      <Tooltip
        placement="bottom"
        title={t('transaction.breadcrumbs.settings')()}
      >
        <Button
          icon={<IconSettingsSemiBold color={colors.text400} />}
          size="small"
          type="text"
          onClick={onToggle}
        />
      </Tooltip>
      {isFilelessOpen && (
        <S.CardWrapper ref={ref}>
          <Card padding={24}>
            <SettingsCard
              initialFileless={initialFileless}
              initialPlDate={initialPlDate}
              onChange={onChange}
            />
          </Card>
        </S.CardWrapper>
      )}
    </S.Container>
  );
};

export default TransactionSettingsButton;
