import { FC, type PropsWithChildren, createContext, useState } from 'react';

type AppStateContextProps = {
  isVisibleContentAfterImport: boolean;
  isPayrollPlaceholderMessage: boolean;
  updateVisibilityContentAfterImport: (status: boolean) => void;
  updateVisibilityPayrollPlaceholderMessage: (status: boolean) => void;
};

// TODO: move this thing out to enable fast refresh
// eslint-disable-next-line
export const AppStateContext = createContext<AppStateContextProps>(
  {} as AppStateContextProps,
);

export const AppStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isVisibleContentAfterImport, setIsVisibleContentAfterImport] =
    useState(false);

  const [isPayrollPlaceholderMessage, setIsPayrollPlaceholderMessage] =
    useState(false);

  const updateVisibilityContentAfterImport = (status: boolean) =>
    setIsVisibleContentAfterImport(status);

  const updateVisibilityPayrollPlaceholderMessage = (status: boolean) =>
    setIsPayrollPlaceholderMessage(status);

  return (
    <AppStateContext.Provider
      value={{
        isVisibleContentAfterImport,
        isPayrollPlaceholderMessage,
        updateVisibilityContentAfterImport,
        updateVisibilityPayrollPlaceholderMessage,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};
