export const DEFAULT_TIMEOUT_FOR_SEARCH = 200;
export const DEFAULT_TIMEOUT_FOR_DEBOUNCE = 350;

export const ROUTES_ADDITIONAL_PUBLIC = ['/start'];

export const ROUTES_PUBLIC = ['/start', '/chat', '/tasks'];

export const PROJECT_STATUSES = ['active', 'inactive', 'finished'];

export const STORAGE_TOKEN_KEY = 'accessToken';
export const STORAGE_COMPANY_KEY = 'selectedCompany';

export const PAYMENT_DOCUMENT_VALID_EXTENSIONS = [
  '.pdf',
  '.jpg',
  '.jpeg',
  '.png',
  '.webp',
  '.tiff',
  '.heic',
];

export const DEFAULT_COUNTRY_CODE = 'LU';
export const DEFAULT_PHONE_NUMBER_CODE = '+352';

export const DEFAULT_CURRENCY_CODE = 'EUR';

export type PaymentDocumentUnit = 'income' | 'expenses';

export enum Language {
  En = 'en-GB',
  Fr = 'fr-FR',
}

export const categoriesGroups = ['operational', 'financial', 'investments'];

export const VATPercents = [0, 8, 17];

export const VAT_DEFAULT_PERCENT = 17;
export const VAT_MIDDLE_PERCENT = 8;

export const VATIdRegex =
  /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/;

export enum ReportType {
  CashFlow = 'cf',
  ProfitsAndLosses = 'pl',
}

export enum AccountType {
  Current = 'CACC',
  Cash = 'CASH',
  Other = 'OTHR',
}

export enum InvoiceItemUnit {
  Day = 'day',
  Month = 'month',
  Item = 'item',
}

export enum InvoiceZeroVATReason {
  Exempt = 'exempt',
  IntraCommunityReverseCharge = 'intra_community_reverse_charge',
}

export const monthsList: Record<string, number> = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const vatTypes = ['eligible', 'exempt'] as const;

export const currencyCodes = [
  'USD',
  'EUR',
  'CAD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ARS',
  'AUD',
  'AZN',
  'BAM',
  'BDT',
  'BGN',
  'BHD',
  'BIF',
  'BND',
  'BOB',
  'BRL',
  'BWP',
  'BYN',
  'BZD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EEK',
  'EGP',
  'ERN',
  'ETB',
  'GBP',
  'GEL',
  'GHS',
  'GNF',
  'GTQ',
  'HKD',
  'HNL',
  'HRK',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'IQD',
  'IRR',
  'ISK',
  'JMD',
  'JOD',
  'JPY',
  'KES',
  'KHR',
  'KMF',
  'KRW',
  'KWD',
  'KZT',
  'LBP',
  'LKR',
  'LTL',
  'LVL',
  'LYD',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MOP',
  'MUR',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'OMR',
  'PAB',
  'PEN',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SDG',
  'SEK',
  'SGD',
  'SOS',
  'SYP',
  'THB',
  'TND',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VEF',
  'VND',
  'XAF',
  'XOF',
  'YER',
  'ZAR',
  'ZMK',
  'ZWL',
] as const;

export type Currency = (typeof currencyCodes)[number];
