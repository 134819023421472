import _ from 'lodash';
import {
  FC,
  type PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Schemas } from '@api-client/generated/types';

type Preview = Schemas.CSVPreview;
type Mapping = Schemas.CSVMapping;
type AttributeMapping = Schemas.AttributeMapping;

type StepEntity = {
  name?: string;
  status: string;
  key?: string;
  required?: boolean;
  error: string | null;
};

type Fields = {
  required: StepEntity[];
  optional: StepEntity[];
};

type FieldType = 'required' | 'optional';

type TransactionsImportContextProps = {
  fields: Fields;
  mapping: Mapping;
  reviewMapping: Mapping;
  preview: Preview;
  isFileUploaded: boolean;
  isPreviewVisible: boolean;
  statusGroupRequired: string;
  statusGroupOptional: string;
  setMapping: (
    mapping: Partial<Mapping>,
    key?: string,
    columnNumber?: number
  ) => void;
  setPreview: (preview: Preview) => void;
  setPreviewVisible: (status: boolean) => void;
  setFileUploaded: (status: boolean) => void;
};

// TODO: move this thing out to enable fast refresh
// eslint-disable-next-line
export const TransactionsImportContext =
  createContext<TransactionsImportContextProps>(
    {} as TransactionsImportContextProps,
  );

const amountTypes = ['debit', 'credit', 'debitCredit'];

const fields: Fields = {
  required: [
    {
      key: 'bookingDate',
      name: t('transactionsImport.popup.columns.bookingDate')(),
      status: 'default',
      error: null,
    },
    {
      key: 'amount',
      name: t('transactionsImport.popup.columns.amount')(),
      status: 'default',
      error: null,
    },
    {
      key: 'currencyCode',
      name: t('transactionsImport.popup.columns.currencyCode')(),
      status: 'default',
      error: null,
    },
  ],
  optional: [
    {
      key: 'details',
      name: t('transactionsImport.popup.columns.details')(),
      status: 'default',
      error: null,
    },
    {
      key: 'contactName',
      name: t('transactionsImport.popup.columns.contactName')(),
      status: 'default',
      error: null,
    },
  ],
};

export const TransactionsImportProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [fileMapping, setFileMapping] = useState<Partial<Mapping>>({
    topRowsSkipAmount: 0,
    bottomRowsSkipAmount: 0,
  });

  const [lastChangedKey, setLastChangedKey] = useState<{
    key: string;
    columnNumber: number;
  }>();

  const [filePreview, setFilePreview] = useState<Preview>();
  const [fieldsList, setFieldsList] = useState<Fields>(fields);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  useEffect(
    () => {
      updateFieldByMapping('bookingDate', 'required');
      updateFieldByMapping('debit', 'required');
      updateFieldByMapping('credit', 'required');
      updateFieldByMapping('debitCredit', 'required');
      updateFieldByMapping('currencyCode', 'required');

      updateFieldByMapping('details', 'optional');
      updateFieldByMapping('contactName', 'optional');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileMapping],
  );

  const updateFieldByMapping = (key: string, type: FieldType) => {
    const anySkipped = (
      fileMapping[key as keyof typeof fileMapping] as AttributeMapping
    )?.isSkipped;
    const debitSkipped = fileMapping.debit?.isSkipped;
    const creditSkipped = fileMapping.credit?.isSkipped;
    const debitCreditSkipped = fileMapping.debitCredit?.isSkipped;

    const hasAny = _.has(fileMapping, key) && !anySkipped;
    const hasDebit = _.has(fileMapping, 'debit') && !debitSkipped;
    const hasCredit = _.has(fileMapping, 'credit') && !creditSkipped;
    const debitCredit =
      _.has(fileMapping, 'debitCredit') && !debitCreditSkipped;

    if ((debitCredit || (hasDebit && hasCredit)) && amountTypes.includes(key)) {
      return setFieldsList((prevState) => ({
        ...prevState,
        [type]: prevState[type].map((field) =>
          field.key === 'amount'
            ? { ...field, status: 'success', error: null }
            : field,
        ),
      }));
    }

    if ((hasDebit && !hasCredit) || (!hasDebit && hasCredit)) {
      return setFieldsList((prevState) => ({
        ...prevState,
        [type]: prevState[type].map((field) =>
          field.key === 'amount'
            ? {
              ...field,
              status: 'error',
              error: t('transactionsImport.popup.errors.amount'),
            }
            : field,
        ),
      }));
    }

    if (hasAny) {
      return setFieldsList((prevState) => ({
        ...prevState,
        [type]: prevState[type].map((field) =>
          field.key === key
            ? { ...field, status: 'success', error: null }
            : field,
        ),
      }));
    }
  };

  const setMapping = useCallback(
    (mapping: Partial<Mapping>, key?: string, columnNumber?: number) => {
      const hasDebit = _.has(mapping, 'debit');
      const hasCredit = _.has(mapping, 'credit');
      const hasDebitCredit = _.has(mapping, 'debitCredit');

      if (key && columnNumber) {
        setLastChangedKey({ key, columnNumber });
      }

      setFileMapping((fileMapping) => {
        if (hasDebitCredit) {
          delete fileMapping['debit'];
          delete fileMapping['credit'];
        }

        if (hasDebit || hasCredit) {
          delete fileMapping['debitCredit'];
        }

        if (
          hasDebit &&
          mapping['debit']?.columnNumber === fileMapping['credit']?.columnNumber
        ) {
          delete fileMapping['credit'];
        }

        if (
          hasCredit &&
          mapping['credit']?.columnNumber === fileMapping['debit']?.columnNumber
        ) {
          delete fileMapping['debit'];
        }

        return {
          ...fileMapping,
          ...(typeof mapping === 'string' ? {} : mapping),
        };
      });
    },
    [],
  );

  useEffect(
    () => {
      const updatedMapping = { ...fileMapping };
      for (const existingKey of Object.keys(updatedMapping)) {
        if (
          // @ts-expect-error-next-line
          updatedMapping[existingKey].columnNumber ===
            lastChangedKey?.columnNumber &&
          existingKey !== lastChangedKey?.key
        ) {
          // @ts-expect-error-next-line
          delete updatedMapping[existingKey]; // eslint-disable-line
        }

        setFileMapping(updatedMapping);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastChangedKey],
  );

  const validateGroupFields = (type: FieldType) => {
    if (fieldsList[type].every((field) => field.status === 'success')) {
      return 'success';
    }

    if (fieldsList[type].map((field) => field.status).includes('error')) {
      return 'error';
    }

    return 'default';
  };

  const reviewFields = () => {
    const mapping = { ...fileMapping };

    if (!fileMapping.debitCredit) {
      mapping['debitCredit'] = { isSkipped: true } as AttributeMapping;
    }

    if (!fileMapping.credit) {
      mapping['credit'] = { isSkipped: true } as AttributeMapping;
    }

    if (!fileMapping.debit) {
      mapping['debit'] = { isSkipped: true } as AttributeMapping;
    }

    if (!fileMapping.details) {
      mapping['details'] = { isSkipped: true } as AttributeMapping;
    }

    if (!fileMapping.contactName) {
      mapping['contactName'] = { isSkipped: true } as AttributeMapping;
    }

    return mapping;
  };

  return (
    <TransactionsImportContext.Provider
      value={{
        fields: fieldsList,
        mapping: fileMapping as Mapping,
        reviewMapping: reviewFields() as Mapping,
        preview: filePreview as Preview,
        statusGroupRequired: validateGroupFields('required'),
        statusGroupOptional: validateGroupFields('optional'),
        isFileUploaded,
        isPreviewVisible,
        setMapping,
        setPreview: setFilePreview,
        setPreviewVisible: setIsPreviewVisible,
        setFileUploaded: setIsFileUploaded,
      }}
    >
      {children}
    </TransactionsImportContext.Provider>
  );
};
