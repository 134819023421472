import { Flex } from 'antd';
import _ from 'lodash';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageMeta, PayrollEmpty } from '@entities';
import { useAccount } from '@hooks';
import { GTMEventName, sendGTMEvent } from '@utils';

const Payroll: FC = () => {
  const navigate = useNavigate();
  const { company } = useAccount();

  useEffect(() => {
    sendGTMEvent(GTMEventName.OpenedPayroll);
  }, []);

  useEffect(() => {
    if (company?.isPayrollEnabled) {
      navigate('/payslips');
    }
  }, [company, navigate]);

  return (
    <Flex gap={24}>
      <PageMeta title={t('payroll.title')()} />

      <PayrollEmpty />
    </Flex>
  );
};

export default Payroll;
