import { ReactNode } from 'react';

import { Schemas } from '@api-client/generated/types';
import { VAT_DEFAULT_PERCENT, VAT_MIDDLE_PERCENT } from '@constants';

type InvoiceItem = Schemas.InvoiceItem;

export const defaultPercent = VAT_DEFAULT_PERCENT / 100;
export const middlePercent = VAT_MIDDLE_PERCENT / 100;

export const getUnitPriceWithTaxRate = (
  unitPrice: number = 0,
  taxRate: number
) => getPriceWithRounding(unitPrice * (1 + taxRate));

export const getPriceWithRounding = (price: number) => +price.toFixed(2);

export const getUnitPriceWithoutTaxRate = (
  unitPrice: number = 0,
  taxRate: number
) => getPriceWithRounding(unitPrice / (1 + taxRate));

export const getTotalWithTaxInluded = (
  unitPrice: number = 0,
  quantity: number
) => getPriceWithRounding(unitPrice * quantity);

export const getTotalWithoutTaxInluded = (
  unitPrice: number = 0,
  quantity: number,
  taxRate: number
) => getPriceWithRounding(unitPrice * quantity * (1 + taxRate));

export const getTotalAmount = (values: InvoiceItem | null): number => {
  if (!values) {
    return 0;
  }

  const { unitPrice, quantity, taxRate, settings } = values;

  if (settings?.isUnitPriceWithTaxInluded) {
    return getTotalWithTaxInluded(unitPrice, quantity);
  } else {
    return getTotalWithoutTaxInluded(unitPrice, quantity, taxRate);
  }
};

export const getTranslatesOptionByZeroVATReason = (label: ReactNode) =>
  label === 'exempt'
    ? {
        title: t(
          'invoiceGenerator.addItem.fieldZeroVATReason.options.exempt.title'
        )(),
        description: t(
          'invoiceGenerator.addItem.fieldZeroVATReason.options.exempt.description'
        )(),
      }
    : {
        title: t(
          'invoiceGenerator.addItem.fieldZeroVATReason.options.intraCommunityReverseCharge.title'
        )(),
        description: t(
          'invoiceGenerator.addItem.fieldZeroVATReason.options.intraCommunityReverseCharge.description'
        )(),
      };
