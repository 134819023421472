import { PaymentDocumentUnit } from '@constants';

const getTranslatesByType = (type: PaymentDocumentUnit) =>
  type === 'income'
    ? {
        draftTitle: t('income.header.draft.title')(),
        draftDetails: t('income.header.draft.details')(),
        processingTitle: t('income.header.processing.title')(),
        processingDetails: t('income.header.processing.details')(),
        toReviewTitle: t('income.header.toReview.title')(),
        toReviewDetails: t('income.header.toReview.details')(),
        toBeBookedTitle: t('income.header.toBeBooked.title')(),
        bookedTitle: t('income.header.booked.title')(),
        bookedDetails: t('income.header.booked.details')(),
        corruptedFileTitle: t('income.header.corruptedFile.title')(),
        wrongDocumentTitle: t('income.header.wrongDocument.title')(),
        wrongDocumentDetails: t('income.header.wrongDocument.details')(),
        documentErrorLabel: t('income.documentErrorLabel')(),
      }
    : {
        draftTitle: t('expenses.header.draft.title')(),
        draftDetails: t('expenses.header.draft.details')(),
        processingTitle: t('expenses.header.processing.title')(),
        processingDetails: t('expenses.header.processing.details')(),
        toReviewTitle: t('expenses.header.toReview.title')(),
        toReviewDetails: t('expenses.header.toReview.details')(),
        toBeBookedTitle: t('expenses.header.toBeBooked.title')(),
        bookedTitle: t('expenses.header.booked.title')(),
        bookedDetails: t('expenses.header.booked.details')(),
        corruptedFileTitle: t('expenses.header.corruptedFile.title')(),
        wrongDocumentTitle: t('expenses.header.wrongDocument.title')(),
        wrongDocumentDetails: t('expenses.header.wrongDocument.details')(),
        documentErrorLabel: t('expenses.documentErrorLabel')(),
      };

export default getTranslatesByType;
