import { Trans } from 'react-i18next';

import { PageEmptyState } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import Instruction1Image from './images/instruction1.svg?react';
import Instruction2Image from './images/instruction2.svg?react';
import Instruction3Image from './images/instruction3.svg?react';
import { IconPlus } from '@assets';

type ProjectsEmptyProps = {
  handleAddProject: () => void;
};

function ProjectsEmpty(props: ProjectsEmptyProps) {
  const features = [
    t('projects.empty.features.clearTracking'),
    t('projects.empty.features.insights'),
    t('projects.empty.features.totals'),
    t('projects.empty.features.management'),
  ];

  const instructions = [
    {
      title: <Trans i18nKey="projects.empty.instructions.1" />,
      image: <Instruction1Image />,
    },
    {
      title: <Trans i18nKey="projects.empty.instructions.2" />,
      image: <Instruction2Image />,
    },
    {
      title: <Trans i18nKey="projects.empty.instructions.3" />,
      image: <Instruction3Image />,
    },
  ];

  const description = [
    t('projects.empty.description.1'),
    t('projects.empty.description.2'),
  ];

  return (
    <PageEmptyState
      title={t('projects.empty.title')()}
      description={description}
      features={features}
      action={
        <Button type="primary" size="small" icon={<IconPlus />} onClick={props.handleAddProject}>
          {t('projects.empty.add')()}
        </Button>
      }
      instructions={instructions}
    />
  );
}

export default ProjectsEmpty;
