import { Flex, Typography } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconPlus } from '@assets';
import { Loader, Select } from '@components';
import { PROJECT_STATUSES } from '@constants';
import { PageMeta, ProjectsList, SearchControl } from '@entities';
import { useAccount, useProjects, useTranslate } from '@hooks';
import { useGetProjectsList } from '@hooks-api';
import { useStorage } from '@hooks/useStorage';
import { LocalePaths } from '@locales';
import { Button } from '@ui-kit/Button/Button';
import { GTMEventName, sendGTMEvent } from '@utils';

import ProjectsEmpty from './ProjectsEmpty';

const { Title } = Typography;

const Projects: FC = () => {
  const navigate = useNavigate();
  const { companyId } = useAccount();
  const { translate } = useTranslate();

  const {
    projects: projectsList,
    initialProjects,
    filterBySearch,
    filterByStatus,
  } = useProjects();

  const [wasEmptyStateShown, setEmptyStateShown] = useStorage(
    'project-welcome-screen',
    false
  );

  const [filterStatus, setFilterStatus] = useState('all');
  const [filterSearch, setFilterSearch] = useState('');

  const { data: projects, isPending: loading } = useGetProjectsList({
    params: {
      companyId: companyId!,
    },
  });

  useEffect(() => {
    sendGTMEvent(GTMEventName.OpenedProjects);
  }, []);

  useEffect(() => {
    if (projects?.length) {
      initialProjects(projects);
      setEmptyStateShown(true);
    }

    return () => {
      initialProjects([]);
    };
  }, [initialProjects, projects]); // eslint-disable-line

  const handleFilterBySearch = (value: string) => {
    setFilterSearch(value);
    filterBySearch(value);
  };

  const handleFilterByStatus = (status: string) => {
    setFilterStatus(status);
    filterByStatus(status);
  };

  if (loading) {
    return <Loader />;
  }

  if (!loading && projects?.length === 0 && !wasEmptyStateShown) {
    return <ProjectsEmpty handleAddProject={() => setEmptyStateShown(true)} />;
  }

  return (
    <Flex gap={24} vertical>
      <PageMeta title={translate('projects.title')} />

      <Title>{translate('projects.title')}</Title>

      <Flex align="center" justify="space-between">
        <Select
          width={152}
          value={filterStatus}
          options={['all', ...PROJECT_STATUSES].map((status) => ({
            label: translate(
              (status === 'all'
                ? 'common.all'
                : `common.statuses.${status}`) as LocalePaths
            ),
            value: status,
          }))}
          onChange={handleFilterByStatus}
          visualType="default"
          size="large"
        />

        <Flex align="center" gap={12}>
          <SearchControl
            value={filterSearch}
            onChange={(e) => handleFilterBySearch(e.target.value)}
          />

          <Button
            type="primary"
            icon={<IconPlus />}
            onClick={() => navigate('/projects/create')}
            size="small"
          >
            {translate('projects.buttonAdd')}
          </Button>
        </Flex>
      </Flex>

      <ProjectsList
        projects={projectsList}
        isFiltered={!!(filterSearch || filterStatus !== 'all')}
      />
    </Flex>
  );
};

export default Projects;
