/* eslint-disable @typescript-eslint/no-namespace */

/* eslint-disable @typescript-eslint/no-explicit-any */
//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import { Schemas } from './types';

export interface RequestBody$AuthController_acceptInvite {
  'application/json': Schemas.AcceptCompanyInviteDto;
}
export interface Parameter$AuthController_confirmEmail {
  token: string;
}
export interface RequestBody$AuthController_login {
  'application/json': Schemas.LoginDto;
}
export interface Response$AuthController_login$Status$200 {
  'application/json': Schemas.UserJwtResponse;
}
export interface RequestBody$AuthController_sendResetPasswordInstructions {
  'application/json': Schemas.ResetPasswordDto;
}
export interface Parameter$AuthController_resetPassword {
  reset_token: string;
}
export interface RequestBody$AuthController_resetPassword {
  'application/json': Schemas.UpdatePasswordDto;
}
export interface Parameter$CompanyController_findOneById {
  companyId: string;
}
export interface Response$CompanyController_findOneById$Status$200 {
  'application/json': Schemas.Company;
}
export interface Parameter$CompanyController_updateOneById {
  companyId: string;
}
export interface RequestBody$CompanyController_updateOneById {
  'application/json': Schemas.CompanyDto;
}
export interface Response$CompanyController_updateOneById$Status$200 {
  'application/json': Schemas.Company;
}
export interface Response$UserController_getCurrentUser$Status$200 {
  'application/json': Schemas.User;
}
export interface RequestBody$UserController_updateCurrentUser {
  'application/json': Schemas.UpdateUserDto;
}
export interface Response$UserController_updateCurrentUser$Status$200 {
  'application/json': Schemas.User;
}
export interface RequestBody$UserController_updateCurrentUserPassword {
  'application/json': Schemas.UpdateUserPasswordDto;
}
export interface Response$UserController_updateCurrentUserPassword$Status$200 {
  'application/json': Schemas.User;
}
export interface Parameter$UserController_getUsers {
  companyId: string;
}
export interface Response$UserController_getUsers$Status$200 {
  'application/json': Schemas.User[];
}
export interface Parameter$UserController_removeFromCompany {
  id: string;
  companyId: string;
}
export interface Response$UserController_removeFromCompany$Status$200 {
  'application/json': Schemas.User[];
}
export interface Parameter$UserController_updateUserPermissions {
  id: string;
  companyId: string;
}
export interface RequestBody$UserController_updateUserPermissions {
  'application/json': Schemas.UpdateAccessRightsDto;
}
export interface Response$UserController_updateUserPermissions$Status$200 {
  'application/json': Schemas.AccessRights;
}
export interface Parameter$TaskController_findAll {
  companyId: string;
}
export interface Response$TaskController_findAll$Status$200 {
  'application/json': Schemas.Task[];
}
export interface Response$ChatMessageController_findAll$Status$200 {
  'application/json': Schemas.ChatMessage[];
}
export interface Parameter$TaskFileController_create {
  companyId: string;
}
export interface RequestBody$TaskFileController_create {
  'multipart/form-data': Schemas.CreateTaskFileDto;
}
export interface Response$TaskFileController_create$Status$201 {
  'application/json': Schemas.TaskFile;
}
export interface Parameter$CompanyInviteController_findAll {
  companyId: string;
}
export interface Response$CompanyInviteController_findAll$Status$200 {
  'application/json': Schemas.CompanyInvite[];
}
export interface Parameter$CompanyInviteController_create {
  companyId: string;
}
export interface RequestBody$CompanyInviteController_create {
  'application/json': Schemas.CompanyInviteDto;
}
export interface Response$CompanyInviteController_create$Status$201 {
  'application/json': Schemas.CompanyInvite;
}
export interface Parameter$CompanyInviteController_acceptByToken {
  token: string;
}
export interface Response$CompanyInviteController_acceptByToken$Status$200 {
  'application/json': Schemas.CompanyInvite;
}
export interface Parameter$CompanyInviteController_delete {
  id: string;
  companyId: string;
}
export interface Response$CompanyInviteController_delete$Status$200 {
  'application/json': Schemas.CompanyInvite;
}
export interface Parameter$CompanyInviteController_updateOneById {
  id: string;
  companyId: string;
}
export interface RequestBody$CompanyInviteController_updateOneById {
  'application/json': Schemas.UpdateCompanyInviteDto;
}
export interface Response$CompanyInviteController_updateOneById$Status$200 {
  'application/json': Schemas.User;
}
export interface Parameter$BankController_getBanks {
  page?: number;
  perPage?: number;
  term?: string;
}
export interface Response$BankController_getBanks$Status$200 {
  'application/json': Schemas.PaginatedBankResponse;
}
export interface Parameter$BankConnectionsController_create {
  companyId: string;
}
export interface RequestBody$BankConnectionsController_create {
  'application/json': Schemas.ConnectBankDto;
}
export interface Response$BankConnectionsController_create$Status$200 {
  'application/json': Schemas.BankConnectionData;
}
export interface Parameter$BankConnectionsController_findByRef {
  companyId: string;
  ref: string;
}
export interface Response$BankConnectionsController_findByRef$Status$200 {
  'application/json': Schemas.BankConnection;
}
export interface Parameter$BankConnectionsController_callback {
  companyId: string;
  ref: string;
}
export interface Parameter$EventController_findAll {
  companyId: string;
  entityType:
    | 'category'
    | 'company'
    | 'contact'
    | 'file'
    | 'transaction'
    | 'project'
    | 'company_file'
    | 'incorporation_kyc';
  entityId: string;
}
export interface Response$EventController_findAll$Status$200 {
  'application/json': Schemas.Event[];
}
export interface Parameter$AccountsController_getAccounts {
  companyId: string;
}
export interface Response$AccountsController_getAccounts$Status$200 {
  'application/json': Schemas.Account[];
}
export interface Parameter$AccountsController_createAccount {
  companyId: string;
}
export interface RequestBody$AccountsController_createAccount {
  'application/json': Schemas.CreateAccountDto;
}
export interface Response$AccountsController_createAccount$Status$201 {
  'application/json': Schemas.Account;
}
export interface Parameter$AccountsController_deleteAccount {
  companyId: string;
}
export interface RequestBody$AccountsController_deleteAccount {
  'application/json': Schemas.DeleteAccountDto;
}
export interface Response$AccountsController_deleteAccount$Status$200 {
  'application/json': Schemas.Account;
}
export interface Parameter$AccountsController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$AccountsController_findOneById$Status$200 {
  'application/json': Schemas.Account;
}
export interface Parameter$AccountsController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$AccountsController_updateOneById {
  'application/json': Schemas.UpdateAccountDto;
}
export interface Response$AccountsController_updateOneById$Status$200 {
  'application/json': Schemas.Account;
}
export interface Parameter$AccountsController_reconnectById {
  companyId: string;
  id: string;
}
export interface Response$AccountsController_reconnectById$Status$200 {
  'application/json': Schemas.BankConnectionData;
}
export interface Parameter$ContactsController_findAll {
  companyId: string;
  page?: number;
  type?: string;
  countryCode?: string;
  term?: string;
  sortBy?: 'name' | 'type';
  sortDirection?: 'DESC' | 'ASC';
  onlyWithoutTransactions?: boolean;
}
export interface Response$ContactsController_findAll$Status$200 {
  'application/json': Schemas.PaginatedContactResponse;
}
export interface Parameter$ContactsController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$ContactsController_findOneById$Status$200 {
  'application/json': Schemas.Contact;
}
export interface Parameter$ContactsController_delete {
  companyId: string;
  id: string;
}
export interface Response$ContactsController_delete$Status$200 {
  'application/json': Schemas.Contact;
}
export interface Parameter$ContactsController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$ContactsController_updateOneById {
  'application/json': Schemas.ContactDto;
}
export interface Response$ContactsController_updateOneById$Status$200 {
  'application/json': Schemas.Contact;
}
export interface Parameter$ContactsController_create {
  companyId: string;
}
export interface RequestBody$ContactsController_create {
  'application/json': Schemas.ContactDto;
}
export interface Response$ContactsController_create$Status$200 {
  'application/json': Schemas.Contact;
}
export interface Parameter$CategoriesController_createCategory {
  companyId: string;
}
export interface RequestBody$CategoriesController_createCategory {
  'application/json': Schemas.CreateCategoryDto;
}
export interface Response$CategoriesController_createCategory$Status$200 {
  'application/json': Schemas.Category[];
}
export interface Parameter$CategoriesController_findAll {
  companyId: string;
}
export interface Response$CategoriesController_findAll$Status$200 {
  'application/json': Schemas.Category[];
}
export interface Parameter$CategoriesController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$CategoriesController_findOneById$Status$200 {
  'application/json': Schemas.Category;
}
export interface Parameter$CategoriesController_delete {
  companyId: string;
  id: string;
}
export interface Response$CategoriesController_delete$Status$200 {
  'application/json': Schemas.Category;
}
export interface Parameter$CategoriesController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$CategoriesController_updateOneById {
  'application/json': Schemas.UpdateCategoryDto;
}
export interface Response$CategoriesController_updateOneById$Status$200 {
  'application/json': Schemas.Category;
}
export interface Parameter$TransactionController_findAll {
  companyId: string;
  page?: number;
  perPage?: number;
  dateFrom?: string;
  dateTo?: string;
  categoryIds?: string;
  contactIds?: string;
  accountIds?: string;
  projectIds?: string;
  term?: string;
  amountFrom?: number;
  amountTo?: number;
  ingestId?: string;
  sortBy?: 'amount' | 'date' | 'category' | 'contact' | 'plDate';
  sortDirection?: 'DESC' | 'ASC';
  reportType?: 'cf' | 'pl';
  onlyWithoutDocuments?: boolean;
  conditions?: string;
}
export interface Response$TransactionController_findAll$Status$200 {
  'application/json': Schemas.PaginatedTrasactionResponse;
}
export interface Parameter$TransactionController_create {
  companyId: string;
  id: string;
}
export interface RequestBody$TransactionController_create {
  'application/json': Schemas.CreateTransactionDto;
}
export interface Response$TransactionController_create$Status$200 {
  'application/json': Schemas.Transaction;
}
export interface Parameter$TransactionController_getStatsByCategory {
  companyId: string;
  dateFrom: string;
  dateTo: string;
  flowType: 'money_in' | 'money_out';
  type: 'cf' | 'pl';
  breakDown: 'month' | 'quarter' | 'year';
}
export interface Response$TransactionController_getStatsByCategory$Status$200 {
  'application/json': Schemas.CategoryReportRow[];
}
export interface Parameter$TransactionController_getStatsByTime {
  companyId: string;
  dateFrom: string;
  dateTo: string;
  type: 'cf' | 'pl';
  breakDown: 'month' | 'quarter' | 'year';
}
export interface Response$TransactionController_getStatsByTime$Status$200 {
  'application/json': Schemas.TimeReportRow[];
}
export interface Parameter$TransactionController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$TransactionController_findOneById$Status$200 {
  'application/json': Schemas.Transaction;
}
export interface Parameter$TransactionController_delete {
  companyId: string;
  id: string;
}
export interface Parameter$TransactionController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$TransactionController_updateOneById {
  'application/json': Schemas.UpdateTransactionDto;
}
export interface Response$TransactionController_updateOneById$Status$200 {
  'application/json': Schemas.Transaction;
}
export interface Parameter$DocumentController_findAll {
  companyId: string;
  page?: number;
  perPage?: number;
  type?: string;
  term?: string;
  withUrl?: boolean;
  dateFrom?: string;
  dateTo?: string;
  toReview?: boolean;
  withErrors?: boolean;
  onlyWithoutTransactions?: boolean;
  withMetadataOnly?: boolean;
  withoutMetadataOnly?: boolean;
  conditions?: string;
  contactIds?: string;
}
export interface Response$DocumentController_findAll$Status$200 {
  'application/json': Schemas.PaginatedDocumentResponse;
}
export interface Parameter$DocumentController_create {
  companyId: string;
}
export interface RequestBody$DocumentController_create {
  'multipart/form-data': Schemas.CreateDocumentDto;
}
export interface Response$DocumentController_create$Status$201 {
  'application/json': Schemas.Document;
}
export interface Parameter$DocumentController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$DocumentController_findOneById$Status$200 {
  'application/json': Schemas.Document;
}
export interface Parameter$DocumentController_delete {
  companyId: string;
  id: string;
}
export interface Response$DocumentController_delete$Status$200 {
  'application/json': Schemas.Document;
}
export interface Parameter$DocumentController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$DocumentController_updateOneById {
  'multipart/form-data': Schemas.UpdateDocumentDto;
}
export interface Response$DocumentController_updateOneById$Status$200 {
  'application/json': Schemas.Document;
}
export interface Parameter$CategoryGroupController_findAll {
  companyId: string;
}
export interface Parameter$CategoryGroupController_create {
  companyId: string;
}
export interface RequestBody$CategoryGroupController_create {
  'application/json': Schemas.CreateCategoryGroupDto;
}
export interface Response$CategoryGroupController_create$Status$201 {
  'application/json': Schemas.CategoryGroup;
}
export interface Parameter$CategoryGroupController_delete {
  companyId: string;
  id: string;
}
export interface Response$CategoryGroupController_delete$Status$200 {
  'application/json': Schemas.CategoryGroup;
}
export interface Parameter$CategoryGroupController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$CategoryGroupController_updateOneById {
  'application/json': Schemas.UpdateCategoryGroupDto;
}
export interface Response$CategoryGroupController_updateOneById$Status$200 {
  'application/json': Schemas.CategoryGroup;
}
export interface Parameter$ProjectController_findAll {
  companyId: string;
}
export interface Response$ProjectController_findAll$Status$200 {
  'application/json': Schemas.Project[];
}
export interface Parameter$ProjectController_create {
  companyId: string;
}
export interface RequestBody$ProjectController_create {
  'application/json': Schemas.ProjectDto;
}
export interface Response$ProjectController_create$Status$201 {
  'application/json': Schemas.Project;
}
export interface Parameter$ProjectController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$ProjectController_findOneById$Status$200 {
  'application/json': Schemas.Project;
}
export interface Parameter$ProjectController_delete {
  companyId: string;
  id: string;
}
export interface Response$ProjectController_delete$Status$200 {
  'application/json': Schemas.Project;
}
export interface Parameter$ProjectController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$ProjectController_updateOneById {
  'application/json': Schemas.ProjectDto;
}
export interface Response$ProjectController_updateOneById$Status$200 {
  'application/json': Schemas.Project;
}
export interface Parameter$FileMappingController_create {
  companyId: string;
}
export interface RequestBody$FileMappingController_create {
  'multipart/form-data': Schemas.FileMappingDto;
}
export interface Response$FileMappingController_create$Status$201 {
  'application/json': Schemas.FileMapping;
}
export interface Parameter$FileMappingController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$FileMappingController_findOneById$Status$200 {
  'application/json': Schemas.FileMapping;
}
export interface Parameter$FileMappingController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$FileMappingController_updateOneById {
  'multipart/form-data': Schemas.FileMappingDto;
}
export interface Response$FileMappingController_updateOneById$Status$200 {
  'application/json': Schemas.FileMapping;
}
export interface Parameter$FileMappingController_validate {
  companyId: string;
  id: string;
}
export interface RequestBody$FileMappingController_validate {
  'multipart/form-data': Schemas.ValidateFileMappingDto;
}
export interface Response$FileMappingController_validate$Status$200 {
  'application/json': Schemas.ValidationResult;
}
export interface Parameter$FileMappingController_import {
  companyId: string;
  id: string;
}
export interface Response$FileMappingController_import$Status$200 {
  'application/json': Schemas.TransactionIngest;
}
export interface Parameter$IncorporationController_findOneById {
  companyId: string;
}
export interface Response$IncorporationController_findOneById$Status$200 {
  'application/json': Schemas.Incorporation;
}
export interface RequestBody$IncorporationController_create {
  'application/json': Schemas.IncorporationDto;
}
export interface Response$IncorporationController_create$Status$201 {
  'application/json': Schemas.Incorporation;
}
export interface Parameter$IncorporationController_updateOneById {
  companyId: string;
}
export interface RequestBody$IncorporationController_updateOneById {
  'application/json': Schemas.IncorporationDto;
}
export interface Response$IncorporationController_updateOneById$Status$200 {
  'application/json': Schemas.Incorporation;
}
export interface Parameter$IncorporationController_updateEmail {
  companyId: string;
}
export interface RequestBody$IncorporationController_updateEmail {
  'application/json': Schemas.UpdateEmailIncorporationDto;
}
export interface Response$IncorporationController_updateEmail$Status$200 {
  'application/json': Schemas.Incorporation;
}
export interface Parameter$IncorporationController_createPaymentIntent {
  companyId: string;
}
export interface Response$IncorporationController_createPaymentIntent$Status$200 {
  'application/json': Schemas.PaymentIntent;
}
export interface Parameter$IncorporationController_getInvoice {
  companyId: string;
}
export interface Response$IncorporationController_getInvoice$Status$200 {
  'application/json': Schemas.Invoice;
}
export interface Parameter$IncorporationController_claimSuccessfulPayment {
  companyId: string;
}
export interface Response$IncorporationController_claimSuccessfulPayment$Status$200 {
  'application/json': Schemas.Incorporation;
}
export interface Parameter$IncorporationController_confirmEmail {
  companyId: string;
}
export interface RequestBody$IncorporationController_confirmEmail {
  'application/json': Schemas.ConfirmEmailIncorporationDto;
}
export interface Response$IncorporationController_confirmEmail$Status$200 {
  'application/json': Schemas.Incorporation;
}
export interface Parameter$CompanyFileController_findAll {
  companyId: string;
  type: string;
  personId: string;
}
export interface Response$CompanyFileController_findAll$Status$200 {
  'application/json': Schemas.CompanyFile[];
}
export interface Parameter$CompanyFileController_create {
  companyId: string;
}
export interface RequestBody$CompanyFileController_create {
  'multipart/form-data': Schemas.CreateCompanyFileDto;
}
export interface Response$CompanyFileController_create$Status$201 {
  'application/json': Schemas.CompanyFile;
}
export interface Parameter$CompanyFileController_delete {
  companyId: string;
  id: string;
}
export interface Response$CompanyFileController_delete$Status$200 {
  'application/json': Schemas.CompanyFile;
}
export interface Parameter$CompanyFileController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$CompanyFileController_updateOneById {
  'multipart/form-data': Schemas.UpdateCompanyFileDto;
}
export interface Response$CompanyFileController_updateOneById$Status$200 {
  'application/json': Schemas.CompanyFile;
}
export interface RequestBody$OnfidoDataController_create {
  'application/json': Schemas.CreateOnfidoDataDto;
}
export interface Response$OnfidoDataController_create$Status$201 {
  'application/json': Schemas.OnfidoData;
}
export interface Parameter$TaxController_findAll {
  companyId: string;
}
export interface Response$TaxController_findAll$Status$200 {
  'application/json': Schemas.Tax[];
}
export interface Parameter$TaxController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$TaxController_findOneById$Status$200 {
  'application/json': Schemas.Tax;
}
export interface Parameter$TaxController_updateOneById {
  companyId: string;
  id: string;
}
export interface RequestBody$TaxController_updateOneById {
  'application/json': Schemas.TaxDto;
}
export interface Response$TaxController_updateOneById$Status$200 {
  'application/json': Schemas.Tax;
}
export interface Parameter$TaxController_markPaid {
  companyId: string;
  id: string;
}
export interface Response$TaxController_markPaid$Status$200 {
  'application/json': Schemas.Tax;
}
export interface Parameter$CompanyInfoController_findOneByVatId {
  vatId: string;
}
export interface Response$CompanyInfoController_findOneByVatId$Status$200 {
  'application/json': Schemas.CompanyInfo;
}
export interface Parameter$RuleController_findAll {
  companyId: string;
  onlyArchived?: boolean;
}
export interface Response$RuleController_findAll$Status$200 {
  'application/json': Schemas.Rule[];
}
export interface Parameter$RuleController_create {
  companyId: string;
}
export interface RequestBody$RuleController_create {
  'application/json': Schemas.RuleDto;
}
export interface Response$RuleController_create$Status$201 {
  'application/json': Schemas.Rule;
}
export interface Parameter$RuleController_delete {
  companyId: string;
  id: string;
}
export interface Response$RuleController_delete$Status$200 {
  'application/json': Schemas.Rule;
}
export interface Parameter$RuleController_restore {
  companyId: string;
  id: string;
}
export interface Response$RuleController_restore$Status$200 {
  'application/json': Schemas.Rule;
}
export interface Parameter$TransactionIngestController_findAll {
  companyId: string;
}
export interface Response$TransactionIngestController_findAll$Status$200 {
  'application/json': Schemas.TransactionIngest[];
}
export interface Parameter$TransactionIngestController_delete {
  companyId: string;
  id: string;
}
export interface Response$TransactionIngestController_delete$Status$200 {
  'application/json': Schemas.TransactionIngest;
}
export interface Parameter$PaymentMethodController_intialize {
  companyId: string;
}
export interface Response$PaymentMethodController_intialize$Status$201 {
  'application/json': string;
}
export interface Parameter$PaymentMethodController_finalize {
  companyId: string;
}
export interface RequestBody$PaymentMethodController_finalize {
  'application/json': Schemas.PaymentMethodFinalizeDto;
}
export interface Response$PaymentMethodController_finalize$Status$201 {
  'application/json': Schemas.PaymentMethodResponseDto;
}
export interface Parameter$PaymentMethodController_findOne {
  companyId: string;
}
export interface Response$PaymentMethodController_findOne$Status$200 {
  'application/json': Schemas.PaymentMethodResponseDto;
}
export interface Parameter$PaymentMethodController_delete {
  companyId: string;
}
export interface Response$PaymentMethodController_delete$Status$200 {
  'application/json': Schemas.PaymentMethodResponseDto;
}
export interface Parameter$PayslipDocumentController_findAll {
  companyId: string;
  page?: number;
  perPage?: number;
  issueDate?: string;
}
export interface Response$PayslipDocumentController_findAll$Status$200 {
  'application/json': Schemas.PaginatedPayslipDocumentResponse;
}
export interface Parameter$PayslipDocumentController_downloadAll {
  companyId: string;
  page?: number;
  perPage?: number;
  issueDate?: string;
}
export interface Parameter$PayslipDocumentController_findOneById {
  companyId: string;
  id: string;
}
export interface Response$PayslipDocumentController_findOneById$Status$200 {
  'application/json': Schemas.PayslipDocument;
}
export interface Parameter$PayslipDocumentController_findPeriodsAll {
  companyId: string;
}
export interface Response$PayslipDocumentController_findPeriodsAll$Status$200 {
  'application/json': string[];
}
export interface Parameter$BillingPeriodController_findAll {
  companyId: string;
}
export interface Response$BillingPeriodController_findAll$Status$200 {
  'application/json': Schemas.BillingPeriod[];
}
export interface Parameter$BillingPeriodController_current {
  companyId: string;
}
export interface Response$BillingPeriodController_current$Status$200 {
  'application/json': Schemas.BillingPeriod;
}
export interface Parameter$InvoiceController_findAll {
  companyId: string;
}
export interface Response$InvoiceController_findAll$Status$200 {
  'application/json': Schemas.InvoiceResponseDto[];
}
export type RequestContentType$AuthController_acceptInvite =
  keyof RequestBody$AuthController_acceptInvite;
export interface Params$AuthController_acceptInvite {
  requestBody: RequestBody$AuthController_acceptInvite['application/json'];
}
export interface Params$AuthController_confirmEmail {
  parameter: Parameter$AuthController_confirmEmail;
}
export type RequestContentType$AuthController_login =
  keyof RequestBody$AuthController_login;
export type ResponseContentType$AuthController_login =
  keyof Response$AuthController_login$Status$200;
export interface Params$AuthController_login {
  requestBody: RequestBody$AuthController_login['application/json'];
}
export type RequestContentType$AuthController_sendResetPasswordInstructions =
  keyof RequestBody$AuthController_sendResetPasswordInstructions;
export interface Params$AuthController_sendResetPasswordInstructions {
  requestBody: RequestBody$AuthController_sendResetPasswordInstructions['application/json'];
}
export type RequestContentType$AuthController_resetPassword =
  keyof RequestBody$AuthController_resetPassword;
export interface Params$AuthController_resetPassword {
  parameter: Parameter$AuthController_resetPassword;
  requestBody: RequestBody$AuthController_resetPassword['application/json'];
}
export type ResponseContentType$CompanyController_findOneById =
  keyof Response$CompanyController_findOneById$Status$200;
export interface Params$CompanyController_findOneById {
  parameter: Parameter$CompanyController_findOneById;
}
export type RequestContentType$CompanyController_updateOneById =
  keyof RequestBody$CompanyController_updateOneById;
export type ResponseContentType$CompanyController_updateOneById =
  keyof Response$CompanyController_updateOneById$Status$200;
export interface Params$CompanyController_updateOneById {
  parameter: Parameter$CompanyController_updateOneById;
  requestBody: RequestBody$CompanyController_updateOneById['application/json'];
}
export type ResponseContentType$UserController_getCurrentUser =
  keyof Response$UserController_getCurrentUser$Status$200;
export type RequestContentType$UserController_updateCurrentUser =
  keyof RequestBody$UserController_updateCurrentUser;
export type ResponseContentType$UserController_updateCurrentUser =
  keyof Response$UserController_updateCurrentUser$Status$200;
export interface Params$UserController_updateCurrentUser {
  requestBody: RequestBody$UserController_updateCurrentUser['application/json'];
}
export type RequestContentType$UserController_updateCurrentUserPassword =
  keyof RequestBody$UserController_updateCurrentUserPassword;
export type ResponseContentType$UserController_updateCurrentUserPassword =
  keyof Response$UserController_updateCurrentUserPassword$Status$200;
export interface Params$UserController_updateCurrentUserPassword {
  requestBody: RequestBody$UserController_updateCurrentUserPassword['application/json'];
}
export type ResponseContentType$UserController_getUsers =
  keyof Response$UserController_getUsers$Status$200;
export interface Params$UserController_getUsers {
  parameter: Parameter$UserController_getUsers;
}
export type ResponseContentType$UserController_removeFromCompany =
  keyof Response$UserController_removeFromCompany$Status$200;
export interface Params$UserController_removeFromCompany {
  parameter: Parameter$UserController_removeFromCompany;
}
export type RequestContentType$UserController_updateUserPermissions =
  keyof RequestBody$UserController_updateUserPermissions;
export type ResponseContentType$UserController_updateUserPermissions =
  keyof Response$UserController_updateUserPermissions$Status$200;
export interface Params$UserController_updateUserPermissions {
  parameter: Parameter$UserController_updateUserPermissions;
  requestBody: RequestBody$UserController_updateUserPermissions['application/json'];
}
export type ResponseContentType$TaskController_findAll =
  keyof Response$TaskController_findAll$Status$200;
export interface Params$TaskController_findAll {
  parameter: Parameter$TaskController_findAll;
}
export type ResponseContentType$ChatMessageController_findAll =
  keyof Response$ChatMessageController_findAll$Status$200;
export type RequestContentType$TaskFileController_create =
  keyof RequestBody$TaskFileController_create;
export type ResponseContentType$TaskFileController_create =
  keyof Response$TaskFileController_create$Status$201;
export interface Params$TaskFileController_create {
  parameter: Parameter$TaskFileController_create;
  requestBody: RequestBody$TaskFileController_create['multipart/form-data'];
}
export type ResponseContentType$CompanyInviteController_findAll =
  keyof Response$CompanyInviteController_findAll$Status$200;
export interface Params$CompanyInviteController_findAll {
  parameter: Parameter$CompanyInviteController_findAll;
}
export type RequestContentType$CompanyInviteController_create =
  keyof RequestBody$CompanyInviteController_create;
export type ResponseContentType$CompanyInviteController_create =
  keyof Response$CompanyInviteController_create$Status$201;
export interface Params$CompanyInviteController_create {
  parameter: Parameter$CompanyInviteController_create;
  requestBody: RequestBody$CompanyInviteController_create['application/json'];
}
export type ResponseContentType$CompanyInviteController_acceptByToken =
  keyof Response$CompanyInviteController_acceptByToken$Status$200;
export interface Params$CompanyInviteController_acceptByToken {
  parameter: Parameter$CompanyInviteController_acceptByToken;
}
export type ResponseContentType$CompanyInviteController_delete =
  keyof Response$CompanyInviteController_delete$Status$200;
export interface Params$CompanyInviteController_delete {
  parameter: Parameter$CompanyInviteController_delete;
}
export type RequestContentType$CompanyInviteController_updateOneById =
  keyof RequestBody$CompanyInviteController_updateOneById;
export type ResponseContentType$CompanyInviteController_updateOneById =
  keyof Response$CompanyInviteController_updateOneById$Status$200;
export interface Params$CompanyInviteController_updateOneById {
  parameter: Parameter$CompanyInviteController_updateOneById;
  requestBody: RequestBody$CompanyInviteController_updateOneById['application/json'];
}
export type ResponseContentType$BankController_getBanks =
  keyof Response$BankController_getBanks$Status$200;
export interface Params$BankController_getBanks {
  parameter: Parameter$BankController_getBanks;
}
export type RequestContentType$BankConnectionsController_create =
  keyof RequestBody$BankConnectionsController_create;
export type ResponseContentType$BankConnectionsController_create =
  keyof Response$BankConnectionsController_create$Status$200;
export interface Params$BankConnectionsController_create {
  parameter: Parameter$BankConnectionsController_create;
  requestBody: RequestBody$BankConnectionsController_create['application/json'];
}
export type ResponseContentType$BankConnectionsController_findByRef =
  keyof Response$BankConnectionsController_findByRef$Status$200;
export interface Params$BankConnectionsController_findByRef {
  parameter: Parameter$BankConnectionsController_findByRef;
}
export interface Params$BankConnectionsController_callback {
  parameter: Parameter$BankConnectionsController_callback;
}
export type ResponseContentType$EventController_findAll =
  keyof Response$EventController_findAll$Status$200;
export interface Params$EventController_findAll {
  parameter: Parameter$EventController_findAll;
}
export type ResponseContentType$AccountsController_getAccounts =
  keyof Response$AccountsController_getAccounts$Status$200;
export interface Params$AccountsController_getAccounts {
  parameter: Parameter$AccountsController_getAccounts;
}
export type RequestContentType$AccountsController_createAccount =
  keyof RequestBody$AccountsController_createAccount;
export type ResponseContentType$AccountsController_createAccount =
  keyof Response$AccountsController_createAccount$Status$201;
export interface Params$AccountsController_createAccount {
  parameter: Parameter$AccountsController_createAccount;
  requestBody: RequestBody$AccountsController_createAccount['application/json'];
}
export type RequestContentType$AccountsController_deleteAccount =
  keyof RequestBody$AccountsController_deleteAccount;
export type ResponseContentType$AccountsController_deleteAccount =
  keyof Response$AccountsController_deleteAccount$Status$200;
export interface Params$AccountsController_deleteAccount {
  parameter: Parameter$AccountsController_deleteAccount;
  requestBody: RequestBody$AccountsController_deleteAccount['application/json'];
}
export type ResponseContentType$AccountsController_findOneById =
  keyof Response$AccountsController_findOneById$Status$200;
export interface Params$AccountsController_findOneById {
  parameter: Parameter$AccountsController_findOneById;
}
export type RequestContentType$AccountsController_updateOneById =
  keyof RequestBody$AccountsController_updateOneById;
export type ResponseContentType$AccountsController_updateOneById =
  keyof Response$AccountsController_updateOneById$Status$200;
export interface Params$AccountsController_updateOneById {
  parameter: Parameter$AccountsController_updateOneById;
  requestBody: RequestBody$AccountsController_updateOneById['application/json'];
}
export type ResponseContentType$AccountsController_reconnectById =
  keyof Response$AccountsController_reconnectById$Status$200;
export interface Params$AccountsController_reconnectById {
  parameter: Parameter$AccountsController_reconnectById;
}
export type ResponseContentType$ContactsController_findAll =
  keyof Response$ContactsController_findAll$Status$200;
export interface Params$ContactsController_findAll {
  parameter: Parameter$ContactsController_findAll;
}
export type ResponseContentType$ContactsController_findOneById =
  keyof Response$ContactsController_findOneById$Status$200;
export interface Params$ContactsController_findOneById {
  parameter: Parameter$ContactsController_findOneById;
}
export type ResponseContentType$ContactsController_delete =
  keyof Response$ContactsController_delete$Status$200;
export interface Params$ContactsController_delete {
  parameter: Parameter$ContactsController_delete;
}
export type RequestContentType$ContactsController_updateOneById =
  keyof RequestBody$ContactsController_updateOneById;
export type ResponseContentType$ContactsController_updateOneById =
  keyof Response$ContactsController_updateOneById$Status$200;
export interface Params$ContactsController_updateOneById {
  parameter: Parameter$ContactsController_updateOneById;
  requestBody: RequestBody$ContactsController_updateOneById['application/json'];
}
export type RequestContentType$ContactsController_create =
  keyof RequestBody$ContactsController_create;
export type ResponseContentType$ContactsController_create =
  keyof Response$ContactsController_create$Status$200;
export interface Params$ContactsController_create {
  parameter: Parameter$ContactsController_create;
  requestBody: RequestBody$ContactsController_create['application/json'];
}
export type RequestContentType$CategoriesController_createCategory =
  keyof RequestBody$CategoriesController_createCategory;
export type ResponseContentType$CategoriesController_createCategory =
  keyof Response$CategoriesController_createCategory$Status$200;
export interface Params$CategoriesController_createCategory {
  parameter: Parameter$CategoriesController_createCategory;
  requestBody: RequestBody$CategoriesController_createCategory['application/json'];
}
export type ResponseContentType$CategoriesController_findAll =
  keyof Response$CategoriesController_findAll$Status$200;
export interface Params$CategoriesController_findAll {
  parameter: Parameter$CategoriesController_findAll;
}
export type ResponseContentType$CategoriesController_findOneById =
  keyof Response$CategoriesController_findOneById$Status$200;
export interface Params$CategoriesController_findOneById {
  parameter: Parameter$CategoriesController_findOneById;
}
export type ResponseContentType$CategoriesController_delete =
  keyof Response$CategoriesController_delete$Status$200;
export interface Params$CategoriesController_delete {
  parameter: Parameter$CategoriesController_delete;
}
export type RequestContentType$CategoriesController_updateOneById =
  keyof RequestBody$CategoriesController_updateOneById;
export type ResponseContentType$CategoriesController_updateOneById =
  keyof Response$CategoriesController_updateOneById$Status$200;
export interface Params$CategoriesController_updateOneById {
  parameter: Parameter$CategoriesController_updateOneById;
  requestBody: RequestBody$CategoriesController_updateOneById['application/json'];
}
export type ResponseContentType$TransactionController_findAll =
  keyof Response$TransactionController_findAll$Status$200;
export interface Params$TransactionController_findAll {
  parameter: Parameter$TransactionController_findAll;
}
export type RequestContentType$TransactionController_create =
  keyof RequestBody$TransactionController_create;
export type ResponseContentType$TransactionController_create =
  keyof Response$TransactionController_create$Status$200;
export interface Params$TransactionController_create {
  parameter: Parameter$TransactionController_create;
  requestBody: RequestBody$TransactionController_create['application/json'];
}
export type ResponseContentType$TransactionController_getStatsByCategory =
  keyof Response$TransactionController_getStatsByCategory$Status$200;
export interface Params$TransactionController_getStatsByCategory {
  parameter: Parameter$TransactionController_getStatsByCategory;
}
export type ResponseContentType$TransactionController_getStatsByTime =
  keyof Response$TransactionController_getStatsByTime$Status$200;
export interface Params$TransactionController_getStatsByTime {
  parameter: Parameter$TransactionController_getStatsByTime;
}
export type ResponseContentType$TransactionController_findOneById =
  keyof Response$TransactionController_findOneById$Status$200;
export interface Params$TransactionController_findOneById {
  parameter: Parameter$TransactionController_findOneById;
}
export interface Params$TransactionController_delete {
  parameter: Parameter$TransactionController_delete;
}
export type RequestContentType$TransactionController_updateOneById =
  keyof RequestBody$TransactionController_updateOneById;
export type ResponseContentType$TransactionController_updateOneById =
  keyof Response$TransactionController_updateOneById$Status$200;
export interface Params$TransactionController_updateOneById {
  parameter: Parameter$TransactionController_updateOneById;
  requestBody: RequestBody$TransactionController_updateOneById['application/json'];
}
export type ResponseContentType$DocumentController_findAll =
  keyof Response$DocumentController_findAll$Status$200;
export interface Params$DocumentController_findAll {
  parameter: Parameter$DocumentController_findAll;
}
export type RequestContentType$DocumentController_create =
  keyof RequestBody$DocumentController_create;
export type ResponseContentType$DocumentController_create =
  keyof Response$DocumentController_create$Status$201;
export interface Params$DocumentController_create {
  parameter: Parameter$DocumentController_create;
  requestBody: RequestBody$DocumentController_create['multipart/form-data'];
}
export type ResponseContentType$DocumentController_findOneById =
  keyof Response$DocumentController_findOneById$Status$200;
export interface Params$DocumentController_findOneById {
  parameter: Parameter$DocumentController_findOneById;
}
export type ResponseContentType$DocumentController_delete =
  keyof Response$DocumentController_delete$Status$200;
export interface Params$DocumentController_delete {
  parameter: Parameter$DocumentController_delete;
}
export type RequestContentType$DocumentController_updateOneById =
  keyof RequestBody$DocumentController_updateOneById;
export type ResponseContentType$DocumentController_updateOneById =
  keyof Response$DocumentController_updateOneById$Status$200;
export interface Params$DocumentController_updateOneById {
  parameter: Parameter$DocumentController_updateOneById;
  requestBody: RequestBody$DocumentController_updateOneById['multipart/form-data'];
}
export interface Params$CategoryGroupController_findAll {
  parameter: Parameter$CategoryGroupController_findAll;
}
export type RequestContentType$CategoryGroupController_create =
  keyof RequestBody$CategoryGroupController_create;
export type ResponseContentType$CategoryGroupController_create =
  keyof Response$CategoryGroupController_create$Status$201;
export interface Params$CategoryGroupController_create {
  parameter: Parameter$CategoryGroupController_create;
  requestBody: RequestBody$CategoryGroupController_create['application/json'];
}
export type ResponseContentType$CategoryGroupController_delete =
  keyof Response$CategoryGroupController_delete$Status$200;
export interface Params$CategoryGroupController_delete {
  parameter: Parameter$CategoryGroupController_delete;
}
export type RequestContentType$CategoryGroupController_updateOneById =
  keyof RequestBody$CategoryGroupController_updateOneById;
export type ResponseContentType$CategoryGroupController_updateOneById =
  keyof Response$CategoryGroupController_updateOneById$Status$200;
export interface Params$CategoryGroupController_updateOneById {
  parameter: Parameter$CategoryGroupController_updateOneById;
  requestBody: RequestBody$CategoryGroupController_updateOneById['application/json'];
}
export type ResponseContentType$ProjectController_findAll =
  keyof Response$ProjectController_findAll$Status$200;
export interface Params$ProjectController_findAll {
  parameter: Parameter$ProjectController_findAll;
}
export type RequestContentType$ProjectController_create =
  keyof RequestBody$ProjectController_create;
export type ResponseContentType$ProjectController_create =
  keyof Response$ProjectController_create$Status$201;
export interface Params$ProjectController_create {
  parameter: Parameter$ProjectController_create;
  requestBody: RequestBody$ProjectController_create['application/json'];
}
export type ResponseContentType$ProjectController_findOneById =
  keyof Response$ProjectController_findOneById$Status$200;
export interface Params$ProjectController_findOneById {
  parameter: Parameter$ProjectController_findOneById;
}
export type ResponseContentType$ProjectController_delete =
  keyof Response$ProjectController_delete$Status$200;
export interface Params$ProjectController_delete {
  parameter: Parameter$ProjectController_delete;
}
export type RequestContentType$ProjectController_updateOneById =
  keyof RequestBody$ProjectController_updateOneById;
export type ResponseContentType$ProjectController_updateOneById =
  keyof Response$ProjectController_updateOneById$Status$200;
export interface Params$ProjectController_updateOneById {
  parameter: Parameter$ProjectController_updateOneById;
  requestBody: RequestBody$ProjectController_updateOneById['application/json'];
}
export type RequestContentType$FileMappingController_create =
  keyof RequestBody$FileMappingController_create;
export type ResponseContentType$FileMappingController_create =
  keyof Response$FileMappingController_create$Status$201;
export interface Params$FileMappingController_create {
  parameter: Parameter$FileMappingController_create;
  requestBody: RequestBody$FileMappingController_create['multipart/form-data'];
}
export type ResponseContentType$FileMappingController_findOneById =
  keyof Response$FileMappingController_findOneById$Status$200;
export interface Params$FileMappingController_findOneById {
  parameter: Parameter$FileMappingController_findOneById;
}
export type RequestContentType$FileMappingController_updateOneById =
  keyof RequestBody$FileMappingController_updateOneById;
export type ResponseContentType$FileMappingController_updateOneById =
  keyof Response$FileMappingController_updateOneById$Status$200;
export interface Params$FileMappingController_updateOneById {
  parameter: Parameter$FileMappingController_updateOneById;
  requestBody: RequestBody$FileMappingController_updateOneById['multipart/form-data'];
}
export type RequestContentType$FileMappingController_validate =
  keyof RequestBody$FileMappingController_validate;
export type ResponseContentType$FileMappingController_validate =
  keyof Response$FileMappingController_validate$Status$200;
export interface Params$FileMappingController_validate {
  parameter: Parameter$FileMappingController_validate;
  requestBody: RequestBody$FileMappingController_validate['multipart/form-data'];
}
export type ResponseContentType$FileMappingController_import =
  keyof Response$FileMappingController_import$Status$200;
export interface Params$FileMappingController_import {
  parameter: Parameter$FileMappingController_import;
}
export type ResponseContentType$IncorporationController_findOneById =
  keyof Response$IncorporationController_findOneById$Status$200;
export interface Params$IncorporationController_findOneById {
  parameter: Parameter$IncorporationController_findOneById;
}
export type RequestContentType$IncorporationController_create =
  keyof RequestBody$IncorporationController_create;
export type ResponseContentType$IncorporationController_create =
  keyof Response$IncorporationController_create$Status$201;
export interface Params$IncorporationController_create {
  requestBody: RequestBody$IncorporationController_create['application/json'];
}
export type RequestContentType$IncorporationController_updateOneById =
  keyof RequestBody$IncorporationController_updateOneById;
export type ResponseContentType$IncorporationController_updateOneById =
  keyof Response$IncorporationController_updateOneById$Status$200;
export interface Params$IncorporationController_updateOneById {
  parameter: Parameter$IncorporationController_updateOneById;
  requestBody: RequestBody$IncorporationController_updateOneById['application/json'];
}
export type RequestContentType$IncorporationController_updateEmail =
  keyof RequestBody$IncorporationController_updateEmail;
export type ResponseContentType$IncorporationController_updateEmail =
  keyof Response$IncorporationController_updateEmail$Status$200;
export interface Params$IncorporationController_updateEmail {
  parameter: Parameter$IncorporationController_updateEmail;
  requestBody: RequestBody$IncorporationController_updateEmail['application/json'];
}
export type ResponseContentType$IncorporationController_createPaymentIntent =
  keyof Response$IncorporationController_createPaymentIntent$Status$200;
export interface Params$IncorporationController_createPaymentIntent {
  parameter: Parameter$IncorporationController_createPaymentIntent;
}
export type ResponseContentType$IncorporationController_getInvoice =
  keyof Response$IncorporationController_getInvoice$Status$200;
export interface Params$IncorporationController_getInvoice {
  parameter: Parameter$IncorporationController_getInvoice;
}
export type ResponseContentType$IncorporationController_claimSuccessfulPayment =
  keyof Response$IncorporationController_claimSuccessfulPayment$Status$200;
export interface Params$IncorporationController_claimSuccessfulPayment {
  parameter: Parameter$IncorporationController_claimSuccessfulPayment;
}
export type RequestContentType$IncorporationController_confirmEmail =
  keyof RequestBody$IncorporationController_confirmEmail;
export type ResponseContentType$IncorporationController_confirmEmail =
  keyof Response$IncorporationController_confirmEmail$Status$200;
export interface Params$IncorporationController_confirmEmail {
  parameter: Parameter$IncorporationController_confirmEmail;
  requestBody: RequestBody$IncorporationController_confirmEmail['application/json'];
}
export type ResponseContentType$CompanyFileController_findAll =
  keyof Response$CompanyFileController_findAll$Status$200;
export interface Params$CompanyFileController_findAll {
  parameter: Parameter$CompanyFileController_findAll;
}
export type RequestContentType$CompanyFileController_create =
  keyof RequestBody$CompanyFileController_create;
export type ResponseContentType$CompanyFileController_create =
  keyof Response$CompanyFileController_create$Status$201;
export interface Params$CompanyFileController_create {
  parameter: Parameter$CompanyFileController_create;
  requestBody: RequestBody$CompanyFileController_create['multipart/form-data'];
}
export type ResponseContentType$CompanyFileController_delete =
  keyof Response$CompanyFileController_delete$Status$200;
export interface Params$CompanyFileController_delete {
  parameter: Parameter$CompanyFileController_delete;
}
export type RequestContentType$CompanyFileController_updateOneById =
  keyof RequestBody$CompanyFileController_updateOneById;
export type ResponseContentType$CompanyFileController_updateOneById =
  keyof Response$CompanyFileController_updateOneById$Status$200;
export interface Params$CompanyFileController_updateOneById {
  parameter: Parameter$CompanyFileController_updateOneById;
  requestBody: RequestBody$CompanyFileController_updateOneById['multipart/form-data'];
}
export type RequestContentType$OnfidoDataController_create =
  keyof RequestBody$OnfidoDataController_create;
export type ResponseContentType$OnfidoDataController_create =
  keyof Response$OnfidoDataController_create$Status$201;
export interface Params$OnfidoDataController_create {
  requestBody: RequestBody$OnfidoDataController_create['application/json'];
}
export type ResponseContentType$TaxController_findAll =
  keyof Response$TaxController_findAll$Status$200;
export interface Params$TaxController_findAll {
  parameter: Parameter$TaxController_findAll;
}
export type ResponseContentType$TaxController_findOneById =
  keyof Response$TaxController_findOneById$Status$200;
export interface Params$TaxController_findOneById {
  parameter: Parameter$TaxController_findOneById;
}
export type RequestContentType$TaxController_updateOneById =
  keyof RequestBody$TaxController_updateOneById;
export type ResponseContentType$TaxController_updateOneById =
  keyof Response$TaxController_updateOneById$Status$200;
export interface Params$TaxController_updateOneById {
  parameter: Parameter$TaxController_updateOneById;
  requestBody: RequestBody$TaxController_updateOneById['application/json'];
}
export type ResponseContentType$TaxController_markPaid =
  keyof Response$TaxController_markPaid$Status$200;
export interface Params$TaxController_markPaid {
  parameter: Parameter$TaxController_markPaid;
}
export type ResponseContentType$CompanyInfoController_findOneByVatId =
  keyof Response$CompanyInfoController_findOneByVatId$Status$200;
export interface Params$CompanyInfoController_findOneByVatId {
  parameter: Parameter$CompanyInfoController_findOneByVatId;
}
export type ResponseContentType$RuleController_findAll =
  keyof Response$RuleController_findAll$Status$200;
export interface Params$RuleController_findAll {
  parameter: Parameter$RuleController_findAll;
}
export type RequestContentType$RuleController_create =
  keyof RequestBody$RuleController_create;
export type ResponseContentType$RuleController_create =
  keyof Response$RuleController_create$Status$201;
export interface Params$RuleController_create {
  parameter: Parameter$RuleController_create;
  requestBody: RequestBody$RuleController_create['application/json'];
}
export type ResponseContentType$RuleController_delete =
  keyof Response$RuleController_delete$Status$200;
export interface Params$RuleController_delete {
  parameter: Parameter$RuleController_delete;
}
export type ResponseContentType$RuleController_restore =
  keyof Response$RuleController_restore$Status$200;
export interface Params$RuleController_restore {
  parameter: Parameter$RuleController_restore;
}
export type ResponseContentType$TransactionIngestController_findAll =
  keyof Response$TransactionIngestController_findAll$Status$200;
export interface Params$TransactionIngestController_findAll {
  parameter: Parameter$TransactionIngestController_findAll;
}
export type ResponseContentType$TransactionIngestController_delete =
  keyof Response$TransactionIngestController_delete$Status$200;
export interface Params$TransactionIngestController_delete {
  parameter: Parameter$TransactionIngestController_delete;
}
export type ResponseContentType$PaymentMethodController_intialize =
  keyof Response$PaymentMethodController_intialize$Status$201;
export interface Params$PaymentMethodController_intialize {
  parameter: Parameter$PaymentMethodController_intialize;
}
export type RequestContentType$PaymentMethodController_finalize =
  keyof RequestBody$PaymentMethodController_finalize;
export type ResponseContentType$PaymentMethodController_finalize =
  keyof Response$PaymentMethodController_finalize$Status$201;
export interface Params$PaymentMethodController_finalize {
  parameter: Parameter$PaymentMethodController_finalize;
  requestBody: RequestBody$PaymentMethodController_finalize['application/json'];
}
export type ResponseContentType$PaymentMethodController_findOne =
  keyof Response$PaymentMethodController_findOne$Status$200;
export interface Params$PaymentMethodController_findOne {
  parameter: Parameter$PaymentMethodController_findOne;
}
export type ResponseContentType$PaymentMethodController_delete =
  keyof Response$PaymentMethodController_delete$Status$200;
export interface Params$PaymentMethodController_delete {
  parameter: Parameter$PaymentMethodController_delete;
}
export type ResponseContentType$PayslipDocumentController_findAll =
  keyof Response$PayslipDocumentController_findAll$Status$200;
export interface Params$PayslipDocumentController_findAll {
  parameter: Parameter$PayslipDocumentController_findAll;
}
export interface Params$PayslipDocumentController_downloadAll {
  parameter: Parameter$PayslipDocumentController_downloadAll;
}
export type ResponseContentType$PayslipDocumentController_findOneById =
  keyof Response$PayslipDocumentController_findOneById$Status$200;
export interface Params$PayslipDocumentController_findOneById {
  parameter: Parameter$PayslipDocumentController_findOneById;
}
export type ResponseContentType$PayslipDocumentController_findPeriodsAll =
  keyof Response$PayslipDocumentController_findPeriodsAll$Status$200;
export interface Params$PayslipDocumentController_findPeriodsAll {
  parameter: Parameter$PayslipDocumentController_findPeriodsAll;
}
export type ResponseContentType$BillingPeriodController_findAll =
  keyof Response$BillingPeriodController_findAll$Status$200;
export interface Params$BillingPeriodController_findAll {
  parameter: Parameter$BillingPeriodController_findAll;
}
export type ResponseContentType$BillingPeriodController_current =
  keyof Response$BillingPeriodController_current$Status$200;
export interface Params$BillingPeriodController_current {
  parameter: Parameter$BillingPeriodController_current;
}
export type ResponseContentType$InvoiceController_findAll =
  keyof Response$InvoiceController_findAll$Status$200;
export interface Params$InvoiceController_findAll {
  parameter: Parameter$InvoiceController_findAll;
}
export type HttpMethod =
  | 'GET'
  | 'PUT'
  | 'POST'
  | 'DELETE'
  | 'OPTIONS'
  | 'HEAD'
  | 'PATCH'
  | 'TRACE';
export interface ObjectLike {
  [key: string]: any;
}
export interface QueryParameter {
  value: any;
  style?: 'form' | 'spaceDelimited' | 'pipeDelimited' | 'deepObject';
  explode: boolean;
}
export interface QueryParameters {
  [key: string]: QueryParameter;
}
export type SuccessResponses =
  | Response$AuthController_login$Status$200
  | Response$CompanyController_findOneById$Status$200
  | Response$CompanyController_updateOneById$Status$200
  | Response$UserController_getCurrentUser$Status$200
  | Response$UserController_updateCurrentUser$Status$200
  | Response$UserController_updateCurrentUserPassword$Status$200
  | Response$UserController_getUsers$Status$200
  | Response$UserController_removeFromCompany$Status$200
  | Response$UserController_updateUserPermissions$Status$200
  | Response$TaskController_findAll$Status$200
  | Response$ChatMessageController_findAll$Status$200
  | Response$TaskFileController_create$Status$201
  | Response$CompanyInviteController_findAll$Status$200
  | Response$CompanyInviteController_create$Status$201
  | Response$CompanyInviteController_acceptByToken$Status$200
  | Response$CompanyInviteController_delete$Status$200
  | Response$CompanyInviteController_updateOneById$Status$200
  | Response$BankController_getBanks$Status$200
  | Response$BankConnectionsController_create$Status$200
  | Response$BankConnectionsController_findByRef$Status$200
  | Response$EventController_findAll$Status$200
  | Response$AccountsController_getAccounts$Status$200
  | Response$AccountsController_createAccount$Status$201
  | Response$AccountsController_deleteAccount$Status$200
  | Response$AccountsController_findOneById$Status$200
  | Response$AccountsController_updateOneById$Status$200
  | Response$AccountsController_reconnectById$Status$200
  | Response$ContactsController_findAll$Status$200
  | Response$ContactsController_findOneById$Status$200
  | Response$ContactsController_delete$Status$200
  | Response$ContactsController_updateOneById$Status$200
  | Response$ContactsController_create$Status$200
  | Response$CategoriesController_createCategory$Status$200
  | Response$CategoriesController_findAll$Status$200
  | Response$CategoriesController_findOneById$Status$200
  | Response$CategoriesController_delete$Status$200
  | Response$CategoriesController_updateOneById$Status$200
  | Response$TransactionController_findAll$Status$200
  | Response$TransactionController_create$Status$200
  | Response$TransactionController_getStatsByCategory$Status$200
  | Response$TransactionController_getStatsByTime$Status$200
  | Response$TransactionController_findOneById$Status$200
  | Response$TransactionController_updateOneById$Status$200
  | Response$DocumentController_findAll$Status$200
  | Response$DocumentController_create$Status$201
  | Response$DocumentController_findOneById$Status$200
  | Response$DocumentController_delete$Status$200
  | Response$DocumentController_updateOneById$Status$200
  | Response$CategoryGroupController_create$Status$201
  | Response$CategoryGroupController_delete$Status$200
  | Response$CategoryGroupController_updateOneById$Status$200
  | Response$ProjectController_findAll$Status$200
  | Response$ProjectController_create$Status$201
  | Response$ProjectController_findOneById$Status$200
  | Response$ProjectController_delete$Status$200
  | Response$ProjectController_updateOneById$Status$200
  | Response$FileMappingController_create$Status$201
  | Response$FileMappingController_findOneById$Status$200
  | Response$FileMappingController_updateOneById$Status$200
  | Response$FileMappingController_validate$Status$200
  | Response$FileMappingController_import$Status$200
  | Response$IncorporationController_findOneById$Status$200
  | Response$IncorporationController_create$Status$201
  | Response$IncorporationController_updateOneById$Status$200
  | Response$IncorporationController_updateEmail$Status$200
  | Response$IncorporationController_createPaymentIntent$Status$200
  | Response$IncorporationController_getInvoice$Status$200
  | Response$IncorporationController_claimSuccessfulPayment$Status$200
  | Response$IncorporationController_confirmEmail$Status$200
  | Response$CompanyFileController_findAll$Status$200
  | Response$CompanyFileController_create$Status$201
  | Response$CompanyFileController_delete$Status$200
  | Response$CompanyFileController_updateOneById$Status$200
  | Response$OnfidoDataController_create$Status$201
  | Response$TaxController_findAll$Status$200
  | Response$TaxController_findOneById$Status$200
  | Response$TaxController_updateOneById$Status$200
  | Response$TaxController_markPaid$Status$200
  | Response$CompanyInfoController_findOneByVatId$Status$200
  | Response$RuleController_findAll$Status$200
  | Response$RuleController_create$Status$201
  | Response$RuleController_delete$Status$200
  | Response$RuleController_restore$Status$200
  | Response$TransactionIngestController_findAll$Status$200
  | Response$TransactionIngestController_delete$Status$200
  | Response$PaymentMethodController_intialize$Status$201
  | Response$PaymentMethodController_finalize$Status$201
  | Response$PaymentMethodController_findOne$Status$200
  | Response$PaymentMethodController_delete$Status$200
  | Response$PayslipDocumentController_findAll$Status$200
  | Response$PayslipDocumentController_findOneById$Status$200
  | Response$PayslipDocumentController_findPeriodsAll$Status$200
  | Response$BillingPeriodController_findAll$Status$200
  | Response$BillingPeriodController_current$Status$200
  | Response$InvoiceController_findAll$Status$200;
export namespace ErrorResponse {
  export type AuthController_acceptInvite = void;
  export type AuthController_confirmEmail = void;
  export type AuthController_login = void;
  export type AuthController_sendResetPasswordInstructions = void;
  export type AuthController_resetPassword = void;
  export type CompanyController_findOneById = void;
  export type CompanyController_updateOneById = void;
  export type UserController_getCurrentUser = void;
  export type UserController_updateCurrentUser = void;
  export type UserController_updateCurrentUserPassword = void;
  export type UserController_resendCofirmationEmail = void;
  export type UserController_getUsers = void;
  export type UserController_removeFromCompany = void;
  export type UserController_updateUserPermissions = void;
  export type TaskController_findAll = void;
  export type ChatMessageController_findAll = void;
  export type TaskFileController_create = void;
  export type CompanyInviteController_findAll = void;
  export type CompanyInviteController_create = void;
  export type CompanyInviteController_acceptByToken = void;
  export type CompanyInviteController_delete = void;
  export type CompanyInviteController_updateOneById = void;
  export type BankController_getBanks = void;
  export type BankConnectionsController_create = void;
  export type BankConnectionsController_findByRef = void;
  export type BankConnectionsController_callback = void;
  export type EventController_findAll = void;
  export type AccountsController_getAccounts = void;
  export type AccountsController_createAccount = void;
  export type AccountsController_deleteAccount = void;
  export type AccountsController_findOneById = void;
  export type AccountsController_updateOneById = void;
  export type AccountsController_reconnectById = void;
  export type ContactsController_findAll = void;
  export type ContactsController_findOneById = void;
  export type ContactsController_delete = void;
  export type ContactsController_updateOneById = void;
  export type ContactsController_create = void;
  export type CategoriesController_createCategory = void;
  export type CategoriesController_findAll = void;
  export type CategoriesController_findOneById = void;
  export type CategoriesController_delete = void;
  export type CategoriesController_updateOneById = void;
  export type TransactionController_findAll = void;
  export type TransactionController_create = void;
  export type TransactionController_getStatsByCategory = void;
  export type TransactionController_getStatsByTime = void;
  export type TransactionController_findOneById = void;
  export type TransactionController_delete = void;
  export type TransactionController_updateOneById = void;
  export type DocumentController_findAll = void;
  export type DocumentController_create = void;
  export type DocumentController_findOneById = void;
  export type DocumentController_delete = void;
  export type DocumentController_updateOneById = void;
  export type CategoryGroupController_findAll = void;
  export type CategoryGroupController_create = void;
  export type CategoryGroupController_delete = void;
  export type CategoryGroupController_updateOneById = void;
  export type ProjectController_findAll = void;
  export type ProjectController_create = void;
  export type ProjectController_findOneById = void;
  export type ProjectController_delete = void;
  export type ProjectController_updateOneById = void;
  export type FileMappingController_create = void;
  export type FileMappingController_findOneById = void;
  export type FileMappingController_updateOneById = void;
  export type FileMappingController_validate = void;
  export type FileMappingController_import = void;
  export type IncorporationController_findOneById = void;
  export type IncorporationController_create = void;
  export type IncorporationController_updateOneById = void;
  export type IncorporationController_updateEmail = void;
  export type IncorporationController_createPaymentIntent = void;
  export type IncorporationController_getInvoice = void;
  export type IncorporationController_claimSuccessfulPayment = void;
  export type IncorporationController_confirmEmail = void;
  export type CompanyFileController_findAll = void;
  export type CompanyFileController_create = void;
  export type CompanyFileController_delete = void;
  export type CompanyFileController_updateOneById = void;
  export type OnfidoDataController_create = void;
  export type TaxController_findAll = void;
  export type TaxController_findOneById = void;
  export type TaxController_updateOneById = void;
  export type TaxController_markPaid = void;
  export type CompanyInfoController_findOneByVatId = void;
  export type RuleController_findAll = void;
  export type RuleController_create = void;
  export type RuleController_delete = void;
  export type RuleController_restore = void;
  export type TransactionIngestController_findAll = void;
  export type TransactionIngestController_delete = void;
  export type PaymentMethodController_intialize = void;
  export type PaymentMethodController_finalize = void;
  export type PaymentMethodController_findOne = void;
  export type PaymentMethodController_delete = void;
  export type PayslipDocumentController_findAll = void;
  export type PayslipDocumentController_downloadAll = void;
  export type PayslipDocumentController_findOneById = void;
  export type PayslipDocumentController_findPeriodsAll = void;
  export type BillingPeriodController_findAll = void;
  export type BillingPeriodController_current = void;
  export type InvoiceController_findAll = void;
}
export interface Encoding {
  readonly contentType?: string;
  headers?: Record<string, any>;
  readonly style?: 'form' | 'spaceDelimited' | 'pipeDelimited' | 'deepObject';
  readonly explode?: boolean;
  readonly allowReserved?: boolean;
}
export interface RequestArgs {
  readonly httpMethod: HttpMethod;
  readonly url: string;
  headers: ObjectLike | any;
  requestBody?: ObjectLike | any;
  requestBodyEncoding?: Record<string, Encoding>;
  queryParameters?: QueryParameters | undefined;
}
export interface ApiClient<RequestOption> {
  request: <T = SuccessResponses>(
    requestArgs: RequestArgs,
    options?: RequestOption
  ) => Promise<T>;
}
export const createClient = <RequestOption>(
  apiClient: ApiClient<RequestOption>,
  baseUrl: string
) => {
  const _baseUrl = baseUrl.replace(/\/$/, '');
  return {
    /**
     * Accept invite and maybe create a new user
     * Accept invite and maybe create a new user
     * operationId: AuthController_acceptInvite
     * Request URI: /api/auth/accept_invite
     */
    AuthController_acceptInvite: (
      params: Params$AuthController_acceptInvite,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/auth/accept_invite`;
      const headers = {
        'Content-Type': 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Confirm Email
     * Confirm Email
     * operationId: AuthController_confirmEmail
     * Request URI: /api/auth/confirm_email
     */
    AuthController_confirmEmail: (
      params: Params$AuthController_confirmEmail,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/auth/confirm_email`;
      const headers = {};
      const queryParameters: QueryParameters = {
        token: { value: params.parameter.token, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Login user
     * Login user
     * operationId: AuthController_login
     * Request URI: /api/auth/login
     */
    AuthController_login: (
      params: Params$AuthController_login,
      option?: RequestOption
    ): Promise<
      Response$AuthController_login$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/auth/login`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'PUT',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Send reset password instructions
     * Send reset password instructions
     * operationId: AuthController_sendResetPasswordInstructions
     * Request URI: /api/auth/send_reset_password_instructions
     */
    AuthController_sendResetPasswordInstructions: (
      params: Params$AuthController_sendResetPasswordInstructions,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/auth/send_reset_password_instructions`;
      const headers = {
        'Content-Type': 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Update password
     * Update password
     * operationId: AuthController_resetPassword
     * Request URI: /api/auth/update_password
     */
    AuthController_resetPassword: (
      params: Params$AuthController_resetPassword,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/auth/update_password`;
      const headers = {
        'Content-Type': 'application/json',
      };
      const queryParameters: QueryParameters = {
        reset_token: { value: params.parameter.reset_token, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a company
     * Get a company
     * operationId: CompanyController_findOneById
     * Request URI: /api/companies
     */
    CompanyController_findOneById: (
      params: Params$CompanyController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$CompanyController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/companies`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a company
     * Update a company
     * operationId: CompanyController_updateOneById
     * Request URI: /api/companies
     */
    CompanyController_updateOneById: (
      params: Params$CompanyController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$CompanyController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/companies`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get current user
     * Get current user
     * operationId: UserController_getCurrentUser
     * Request URI: /api/users/me
     */
    UserController_getCurrentUser: (
      option?: RequestOption
    ): Promise<
      Response$UserController_getCurrentUser$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/users/me`;
      const headers = {
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update current user
     * Update current user
     * operationId: UserController_updateCurrentUser
     * Request URI: /api/users/me
     */
    UserController_updateCurrentUser: (
      params: Params$UserController_updateCurrentUser,
      option?: RequestOption
    ): Promise<
      Response$UserController_updateCurrentUser$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/users/me`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Update current user
     * Update current user
     * operationId: UserController_updateCurrentUserPassword
     * Request URI: /api/users/me/updatePassword
     */
    UserController_updateCurrentUserPassword: (
      params: Params$UserController_updateCurrentUserPassword,
      option?: RequestOption
    ): Promise<
      Response$UserController_updateCurrentUserPassword$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/users/me/updatePassword`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Resend confirmation email
     * Resend confirmation email
     * operationId: UserController_resendCofirmationEmail
     * Request URI: /api/users/resend_confirmation_email
     */
    UserController_resendCofirmationEmail: (
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/users/resend_confirmation_email`;
      const headers = {};
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of users
     * Get a list of users
     * operationId: UserController_getUsers
     * Request URI: /api/users
     */
    UserController_getUsers: (
      params: Params$UserController_getUsers,
      option?: RequestOption
    ): Promise<
      Response$UserController_getUsers$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/users`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of users
     * Get a list of users
     * operationId: UserController_removeFromCompany
     * Request URI: /api/users/remove_from_company/{id}
     */
    UserController_removeFromCompany: (
      params: Params$UserController_removeFromCompany,
      option?: RequestOption
    ): Promise<
      Response$UserController_removeFromCompany$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/users/remove_from_company/{id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        id: { value: params.parameter.id, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Update user permissions
     * Update user permissions
     * operationId: UserController_updateUserPermissions
     * Request URI: /api/users/{id}/access_rights
     */
    UserController_updateUserPermissions: (
      params: Params$UserController_updateUserPermissions,
      option?: RequestOption
    ): Promise<
      Response$UserController_updateUserPermissions$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/users/${params.parameter.id}/access_rights`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of tasks
     * Get a list of tasks
     * operationId: TaskController_findAll
     * Request URI: /api/tasks
     */
    TaskController_findAll: (
      params: Params$TaskController_findAll,
      option?: RequestOption
    ): Promise<
      Response$TaskController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/tasks`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of chat_messages
     * Get a list of chat_messages
     * operationId: ChatMessageController_findAll
     * Request URI: /api/chat_messages
     */
    ChatMessageController_findAll: (
      option?: RequestOption
    ): Promise<
      Response$ChatMessageController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/chat_messages`;
      const headers = {
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Create a new taskFile
     * Create a new taskFile
     * operationId: TaskFileController_create
     * Request URI: /api/task_files
     */
    TaskFileController_create: (
      params: Params$TaskFileController_create,
      option?: RequestOption
    ): Promise<
      Response$TaskFileController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/task_files`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of company_invites
     * Get a list of company_invites
     * operationId: CompanyInviteController_findAll
     * Request URI: /api/company_invites
     */
    CompanyInviteController_findAll: (
      params: Params$CompanyInviteController_findAll,
      option?: RequestOption
    ): Promise<
      Response$CompanyInviteController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/company_invites`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Create a new company_invite
     * Create a new company_invite
     * operationId: CompanyInviteController_create
     * Request URI: /api/company_invites
     */
    CompanyInviteController_create: (
      params: Params$CompanyInviteController_create,
      option?: RequestOption
    ): Promise<
      Response$CompanyInviteController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/company_invites`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of company invite
     * Get company invite
     * operationId: CompanyInviteController_acceptByToken
     * Request URI: /api/company_invites/{token}
     */
    CompanyInviteController_acceptByToken: (
      params: Params$CompanyInviteController_acceptByToken,
      option?: RequestOption
    ): Promise<
      Response$CompanyInviteController_acceptByToken$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/company_invites/{token}`;
      const headers = {
        Accept: 'application/json',
      };
      const queryParameters: QueryParameters = {
        token: { value: params.parameter.token, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Remove a company_invite by its id
     * Remove a company_invite by its id
     * operationId: CompanyInviteController_delete
     * Request URI: /api/company_invites/{id}
     */
    CompanyInviteController_delete: (
      params: Params$CompanyInviteController_delete,
      option?: RequestOption
    ): Promise<
      Response$CompanyInviteController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/company_invites/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update company invite
     * Update company invite
     * operationId: CompanyInviteController_updateOneById
     * Request URI: /api/company_invites/{id}
     */
    CompanyInviteController_updateOneById: (
      params: Params$CompanyInviteController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$CompanyInviteController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/company_invites/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * List available banks
     * List available banks
     * operationId: BankController_getBanks
     * Request URI: /api/banks
     */
    BankController_getBanks: (
      params: Params$BankController_getBanks,
      option?: RequestOption
    ): Promise<
      Response$BankController_getBanks$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/banks`;
      const headers = {
        Accept: 'application/json',
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        perPage: { value: params.parameter.perPage, explode: false },
        term: { value: params.parameter.term, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Initialize bank connection
     * Initialize bank connection
     * operationId: BankConnectionsController_create
     * Request URI: /api/bank-connections/initialize
     */
    BankConnectionsController_create: (
      params: Params$BankConnectionsController_create,
      option?: RequestOption
    ): Promise<
      Response$BankConnectionsController_create$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/bank-connections/initialize`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Find bank connection by ref
     * Find bank connection by ref
     * operationId: BankConnectionsController_findByRef
     * Request URI: /api/bank-connections
     */
    BankConnectionsController_findByRef: (
      params: Params$BankConnectionsController_findByRef,
      option?: RequestOption
    ): Promise<
      Response$BankConnectionsController_findByRef$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/bank-connections`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        ref: { value: params.parameter.ref, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Finelize bank connection
     * Finelize bank connection
     * operationId: BankConnectionsController_callback
     * Request URI: /api/bank-connections/connect/finalize
     */
    BankConnectionsController_callback: (
      params: Params$BankConnectionsController_callback,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/bank-connections/connect/finalize`;
      const headers = {
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        ref: { value: params.parameter.ref, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a list of events
     * Get a list of events
     * operationId: EventController_findAll
     * Request URI: /api/events
     */
    EventController_findAll: (
      params: Params$EventController_findAll,
      option?: RequestOption
    ): Promise<
      Response$EventController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/events`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        entityType: { value: params.parameter.entityType, explode: false },
        entityId: { value: params.parameter.entityId, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * List all company accounts
     * Get accounts
     * operationId: AccountsController_getAccounts
     * Request URI: /api/accounts
     */
    AccountsController_getAccounts: (
      params: Params$AccountsController_getAccounts,
      option?: RequestOption
    ): Promise<
      Response$AccountsController_getAccounts$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/accounts`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Create new account for selected bank
     * Create new account
     * operationId: AccountsController_createAccount
     * Request URI: /api/accounts
     */
    AccountsController_createAccount: (
      params: Params$AccountsController_createAccount,
      option?: RequestOption
    ): Promise<
      Response$AccountsController_createAccount$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/accounts`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Delete account by account id
     * Delete account
     * operationId: AccountsController_deleteAccount
     * Request URI: /api/accounts
     */
    AccountsController_deleteAccount: (
      params: Params$AccountsController_deleteAccount,
      option?: RequestOption
    ): Promise<
      Response$AccountsController_deleteAccount$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/accounts`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get account by id
     * Get account by id
     * operationId: AccountsController_findOneById
     * Request URI: /api/accounts/{id}
     */
    AccountsController_findOneById: (
      params: Params$AccountsController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$AccountsController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/accounts/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update an account by account id
     * Update account
     * operationId: AccountsController_updateOneById
     * Request URI: /api/accounts/{id}
     */
    AccountsController_updateOneById: (
      params: Params$AccountsController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$AccountsController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/accounts/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * reconnect an account by account id
     * reconnect account
     * operationId: AccountsController_reconnectById
     * Request URI: /api/accounts/{id}/reconnect
     */
    AccountsController_reconnectById: (
      params: Params$AccountsController_reconnectById,
      option?: RequestOption
    ): Promise<
      Response$AccountsController_reconnectById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/accounts/${params.parameter.id}/reconnect`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of contacts
     * Get a list of contacts
     * operationId: ContactsController_findAll
     * Request URI: /api/contacts
     */
    ContactsController_findAll: (
      params: Params$ContactsController_findAll,
      option?: RequestOption
    ): Promise<
      Response$ContactsController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/contacts`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        type: { value: params.parameter.type, explode: false },
        countryCode: { value: params.parameter.countryCode, explode: false },
        term: { value: params.parameter.term, explode: false },
        sortBy: { value: params.parameter.sortBy, explode: false },
        sortDirection: {
          value: params.parameter.sortDirection,
          explode: false,
        },
        onlyWithoutTransactions: {
          value: params.parameter.onlyWithoutTransactions,
          explode: false,
        },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a contact by its id
     * Get a contact by its id
     * operationId: ContactsController_findOneById
     * Request URI: /api/contacts/{id}
     */
    ContactsController_findOneById: (
      params: Params$ContactsController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$ContactsController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/contacts/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Remove a contact by its id
     * Remove a contact by its id
     * operationId: ContactsController_delete
     * Request URI: /api/contacts/{id}
     */
    ContactsController_delete: (
      params: Params$ContactsController_delete,
      option?: RequestOption
    ): Promise<
      Response$ContactsController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/contacts/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a contact by its ID
     * Update a contact by its ID
     * operationId: ContactsController_updateOneById
     * Request URI: /api/contacts/{id}
     */
    ContactsController_updateOneById: (
      params: Params$ContactsController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$ContactsController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/contacts/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Create a contact
     * Create a contact
     * operationId: ContactsController_create
     * Request URI: /api/contacts/create
     */
    ContactsController_create: (
      params: Params$ContactsController_create,
      option?: RequestOption
    ): Promise<
      Response$ContactsController_create$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/contacts/create`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Categories
     * Categories
     * operationId: CategoriesController_createCategory
     * Request URI: /api/categories/create
     */
    CategoriesController_createCategory: (
      params: Params$CategoriesController_createCategory,
      option?: RequestOption
    ): Promise<
      Response$CategoriesController_createCategory$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/categories/create`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of categories
     * Get a list of categories
     * operationId: CategoriesController_findAll
     * Request URI: /api/categories
     */
    CategoriesController_findAll: (
      params: Params$CategoriesController_findAll,
      option?: RequestOption
    ): Promise<
      Response$CategoriesController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/categories`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a category by its id
     * Get a category by its id
     * operationId: CategoriesController_findOneById
     * Request URI: /api/categories/{id}
     */
    CategoriesController_findOneById: (
      params: Params$CategoriesController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$CategoriesController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/categories/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Remove a category by its id
     * Remove a category by its id
     * operationId: CategoriesController_delete
     * Request URI: /api/categories/{id}
     */
    CategoriesController_delete: (
      params: Params$CategoriesController_delete,
      option?: RequestOption
    ): Promise<
      Response$CategoriesController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/categories/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a category by its ID
     * Update a category by its ID
     * operationId: CategoriesController_updateOneById
     * Request URI: /api/categories/{id}
     */
    CategoriesController_updateOneById: (
      params: Params$CategoriesController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$CategoriesController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/categories/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of transactions
     * Get a list of transactions
     * operationId: TransactionController_findAll
     * Request URI: /api/transactions
     */
    TransactionController_findAll: (
      params: Params$TransactionController_findAll,
      option?: RequestOption
    ): Promise<
      Response$TransactionController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/transactions`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        perPage: { value: params.parameter.perPage, explode: false },
        dateFrom: { value: params.parameter.dateFrom, explode: false },
        dateTo: { value: params.parameter.dateTo, explode: false },
        categoryIds: { value: params.parameter.categoryIds, explode: false },
        contactIds: { value: params.parameter.contactIds, explode: false },
        accountIds: { value: params.parameter.accountIds, explode: false },
        projectIds: { value: params.parameter.projectIds, explode: false },
        term: { value: params.parameter.term, explode: false },
        amountFrom: { value: params.parameter.amountFrom, explode: false },
        amountTo: { value: params.parameter.amountTo, explode: false },
        ingestId: { value: params.parameter.ingestId, explode: false },
        sortBy: { value: params.parameter.sortBy, explode: false },
        sortDirection: {
          value: params.parameter.sortDirection,
          explode: false,
        },
        reportType: { value: params.parameter.reportType, explode: false },
        onlyWithoutDocuments: {
          value: params.parameter.onlyWithoutDocuments,
          explode: false,
        },
        conditions: { value: params.parameter.conditions, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Creates a child transaction inside parent transaction
     * Create a child transaction
     * operationId: TransactionController_create
     * Request URI: /api/transactions/create/{id}/child_transactions
     */
    TransactionController_create: (
      params: Params$TransactionController_create,
      option?: RequestOption
    ): Promise<
      Response$TransactionController_create$Status$200['application/json']
    > => {
      const url =
        _baseUrl +
        `/api/transactions/create/${params.parameter.id}/child_transactions`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get transaction stats by category
     * Get transaction stats by category
     * operationId: TransactionController_getStatsByCategory
     * Request URI: /api/transactions/getStatsByCategory
     */
    TransactionController_getStatsByCategory: (
      params: Params$TransactionController_getStatsByCategory,
      option?: RequestOption
    ): Promise<
      Response$TransactionController_getStatsByCategory$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/transactions/getStatsByCategory`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        dateFrom: { value: params.parameter.dateFrom, explode: false },
        dateTo: { value: params.parameter.dateTo, explode: false },
        flowType: { value: params.parameter.flowType, explode: false },
        type: { value: params.parameter.type, explode: false },
        breakDown: { value: params.parameter.breakDown, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get transaction stats by time
     * Get transaction stats by time
     * operationId: TransactionController_getStatsByTime
     * Request URI: /api/transactions/getStatsByTime
     */
    TransactionController_getStatsByTime: (
      params: Params$TransactionController_getStatsByTime,
      option?: RequestOption
    ): Promise<
      Response$TransactionController_getStatsByTime$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/transactions/getStatsByTime`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        dateFrom: { value: params.parameter.dateFrom, explode: false },
        dateTo: { value: params.parameter.dateTo, explode: false },
        type: { value: params.parameter.type, explode: false },
        breakDown: { value: params.parameter.breakDown, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a transaction by its id
     * Get a transaction by its id
     * operationId: TransactionController_findOneById
     * Request URI: /api/transactions/{id}
     */
    TransactionController_findOneById: (
      params: Params$TransactionController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$TransactionController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/transactions/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a transaction by its id
     * Delete transaction by its id
     * operationId: TransactionController_delete
     * Request URI: /api/transactions/{id}
     */
    TransactionController_delete: (
      params: Params$TransactionController_delete,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/transactions/${params.parameter.id}`;
      const headers = {
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a transaction by its ID
     * Update a transaction by its ID
     * operationId: TransactionController_updateOneById
     * Request URI: /api/transactions/{id}
     */
    TransactionController_updateOneById: (
      params: Params$TransactionController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$TransactionController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/transactions/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of documents
     * Get a list of documents
     * operationId: DocumentController_findAll
     * Request URI: /api/documents
     */
    DocumentController_findAll: (
      params: Params$DocumentController_findAll,
      option?: RequestOption
    ): Promise<
      Response$DocumentController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/documents`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        perPage: { value: params.parameter.perPage, explode: false },
        type: { value: params.parameter.type, explode: false },
        term: { value: params.parameter.term, explode: false },
        withUrl: { value: params.parameter.withUrl, explode: false },
        dateFrom: { value: params.parameter.dateFrom, explode: false },
        dateTo: { value: params.parameter.dateTo, explode: false },
        toReview: { value: params.parameter.toReview, explode: false },
        withErrors: { value: params.parameter.withErrors, explode: false },
        onlyWithoutTransactions: {
          value: params.parameter.onlyWithoutTransactions,
          explode: false,
        },
        withMetadataOnly: {
          value: params.parameter.withMetadataOnly,
          explode: false,
        },
        withoutMetadataOnly: {
          value: params.parameter.withoutMetadataOnly,
          explode: false,
        },
        conditions: { value: params.parameter.conditions, explode: false },
        contactIds: { value: params.parameter.contactIds, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Create a new document
     * Create a new document
     * operationId: DocumentController_create
     * Request URI: /api/documents
     */
    DocumentController_create: (
      params: Params$DocumentController_create,
      option?: RequestOption
    ): Promise<
      Response$DocumentController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/documents`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a document by its id
     * Get a document by its id
     * operationId: DocumentController_findOneById
     * Request URI: /api/documents/{id}
     */
    DocumentController_findOneById: (
      params: Params$DocumentController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$DocumentController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/documents/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Remove a document by its id
     * Remove a document by its id
     * operationId: DocumentController_delete
     * Request URI: /api/documents/{id}
     */
    DocumentController_delete: (
      params: Params$DocumentController_delete,
      option?: RequestOption
    ): Promise<
      Response$DocumentController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/documents/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a document by its ID
     * Update a document by its ID
     * operationId: DocumentController_updateOneById
     * Request URI: /api/documents/{id}
     */
    DocumentController_updateOneById: (
      params: Params$DocumentController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$DocumentController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/documents/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of category_groups
     * Get a list of category_groups
     * operationId: CategoryGroupController_findAll
     * Request URI: /api/category_groups
     */
    CategoryGroupController_findAll: (
      params: Params$CategoryGroupController_findAll,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/category_groups`;
      const headers = {
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Create a new category_group
     * Create a new category_group
     * operationId: CategoryGroupController_create
     * Request URI: /api/category_groups
     */
    CategoryGroupController_create: (
      params: Params$CategoryGroupController_create,
      option?: RequestOption
    ): Promise<
      Response$CategoryGroupController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/category_groups`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Remove a category_group by its id
     * Remove a category_group by its id
     * operationId: CategoryGroupController_delete
     * Request URI: /api/category_groups/{id}
     */
    CategoryGroupController_delete: (
      params: Params$CategoryGroupController_delete,
      option?: RequestOption
    ): Promise<
      Response$CategoryGroupController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/category_groups/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a category_group by its ID
     * Update a category_group by its ID
     * operationId: CategoryGroupController_updateOneById
     * Request URI: /api/category_groups/{id}
     */
    CategoryGroupController_updateOneById: (
      params: Params$CategoryGroupController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$CategoryGroupController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/category_groups/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of projects
     * Get a list of projects
     * operationId: ProjectController_findAll
     * Request URI: /api/projects
     */
    ProjectController_findAll: (
      params: Params$ProjectController_findAll,
      option?: RequestOption
    ): Promise<
      Response$ProjectController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/projects`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Create a new project
     * Create a new project
     * operationId: ProjectController_create
     * Request URI: /api/projects
     */
    ProjectController_create: (
      params: Params$ProjectController_create,
      option?: RequestOption
    ): Promise<
      Response$ProjectController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/projects`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a project by its id
     * Get a project by its id
     * operationId: ProjectController_findOneById
     * Request URI: /api/projects/{id}
     */
    ProjectController_findOneById: (
      params: Params$ProjectController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$ProjectController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/projects/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Remove a project by its id
     * Remove a project by its id
     * operationId: ProjectController_delete
     * Request URI: /api/projects/{id}
     */
    ProjectController_delete: (
      params: Params$ProjectController_delete,
      option?: RequestOption
    ): Promise<
      Response$ProjectController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/projects/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a project by its ID
     * Update a project by its ID
     * operationId: ProjectController_updateOneById
     * Request URI: /api/projects/{id}
     */
    ProjectController_updateOneById: (
      params: Params$ProjectController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$ProjectController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/projects/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Create a new file_mappings
     * Create a new file_mappings
     * operationId: FileMappingController_create
     * Request URI: /api/file_mappings
     */
    FileMappingController_create: (
      params: Params$FileMappingController_create,
      option?: RequestOption
    ): Promise<
      Response$FileMappingController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/file_mappings`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a file_mappings by its id
     * Get a file_mappings by its id
     * operationId: FileMappingController_findOneById
     * Request URI: /api/file_mappings/{id}
     */
    FileMappingController_findOneById: (
      params: Params$FileMappingController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$FileMappingController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/file_mappings/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a file_mappings by its ID
     * Update a file_mappings by its ID
     * operationId: FileMappingController_updateOneById
     * Request URI: /api/file_mappings/{id}
     */
    FileMappingController_updateOneById: (
      params: Params$FileMappingController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$FileMappingController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/file_mappings/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Validate file_mappings by its ID
     * Validate file_mappings by its ID
     * operationId: FileMappingController_validate
     * Request URI: /api/file_mappings/{id}/validate
     */
    FileMappingController_validate: (
      params: Params$FileMappingController_validate,
      option?: RequestOption
    ): Promise<
      Response$FileMappingController_validate$Status$200['application/json']
    > => {
      const url =
        _baseUrl + `/api/file_mappings/${params.parameter.id}/validate`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Import file_mappings by its ID
     * Import file_mappings by its ID
     * operationId: FileMappingController_import
     * Request URI: /api/file_mappings/{id}/import
     */
    FileMappingController_import: (
      params: Params$FileMappingController_import,
      option?: RequestOption
    ): Promise<
      Response$FileMappingController_import$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/file_mappings/${params.parameter.id}/import`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a incorporation
     * Get a incorporation
     * operationId: IncorporationController_findOneById
     * Request URI: /api/incorporations
     */
    IncorporationController_findOneById: (
      params: Params$IncorporationController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$IncorporationController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/incorporations`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Create a new incorporation
     * Create a new incorporation
     * operationId: IncorporationController_create
     * Request URI: /api/incorporations
     */
    IncorporationController_create: (
      params: Params$IncorporationController_create,
      option?: RequestOption
    ): Promise<
      Response$IncorporationController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/incorporations`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Update a incorporation
     * Update a incorporation
     * operationId: IncorporationController_updateOneById
     * Request URI: /api/incorporations
     */
    IncorporationController_updateOneById: (
      params: Params$IncorporationController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$IncorporationController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/incorporations`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Update Email
     * Update Email
     * operationId: IncorporationController_updateEmail
     * Request URI: /api/incorporations/update_email
     */
    IncorporationController_updateEmail: (
      params: Params$IncorporationController_updateEmail,
      option?: RequestOption
    ): Promise<
      Response$IncorporationController_updateEmail$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/incorporations/update_email`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Create Payment Intent
     * Create Payment Intent
     * operationId: IncorporationController_createPaymentIntent
     * Request URI: /api/incorporations/create_payment_intent
     */
    IncorporationController_createPaymentIntent: (
      params: Params$IncorporationController_createPaymentIntent,
      option?: RequestOption
    ): Promise<
      Response$IncorporationController_createPaymentIntent$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/incorporations/create_payment_intent`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get invoice
     * Get invoice
     * operationId: IncorporationController_getInvoice
     * Request URI: /api/incorporations/get_invoice
     */
    IncorporationController_getInvoice: (
      params: Params$IncorporationController_getInvoice,
      option?: RequestOption
    ): Promise<
      Response$IncorporationController_getInvoice$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/incorporations/get_invoice`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Create successful Payment Intent
     * Claim successful Payment Intent
     * operationId: IncorporationController_claimSuccessfulPayment
     * Request URI: /api/incorporations/claim_successful_payment
     */
    IncorporationController_claimSuccessfulPayment: (
      params: Params$IncorporationController_claimSuccessfulPayment,
      option?: RequestOption
    ): Promise<
      Response$IncorporationController_claimSuccessfulPayment$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/incorporations/claim_successful_payment`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Confirm Email
     * Confirm Email
     * operationId: IncorporationController_confirmEmail
     * Request URI: /api/incorporations/confirm_email
     */
    IncorporationController_confirmEmail: (
      params: Params$IncorporationController_confirmEmail,
      option?: RequestOption
    ): Promise<
      Response$IncorporationController_confirmEmail$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/incorporations/confirm_email`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of files
     * Get a list of files
     * operationId: CompanyFileController_findAll
     * Request URI: /api/company_files
     */
    CompanyFileController_findAll: (
      params: Params$CompanyFileController_findAll,
      option?: RequestOption
    ): Promise<
      Response$CompanyFileController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/company_files`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        type: { value: params.parameter.type, explode: false },
        personId: { value: params.parameter.personId, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Create a new file
     * Create a new file
     * operationId: CompanyFileController_create
     * Request URI: /api/company_files
     */
    CompanyFileController_create: (
      params: Params$CompanyFileController_create,
      option?: RequestOption
    ): Promise<
      Response$CompanyFileController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/company_files`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Remove a file by its id
     * Remove a file by its id
     * operationId: CompanyFileController_delete
     * Request URI: /api/company_files/{id}
     */
    CompanyFileController_delete: (
      params: Params$CompanyFileController_delete,
      option?: RequestOption
    ): Promise<
      Response$CompanyFileController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/company_files/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a file by its ID
     * Update a file by its ID
     * operationId: CompanyFileController_updateOneById
     * Request URI: /api/company_files/{id}
     */
    CompanyFileController_updateOneById: (
      params: Params$CompanyFileController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$CompanyFileController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/company_files/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Create a new onfido_data
     * Create a new onfido_data
     * operationId: OnfidoDataController_create
     * Request URI: /api/onfido_datas
     */
    OnfidoDataController_create: (
      params: Params$OnfidoDataController_create,
      option?: RequestOption
    ): Promise<
      Response$OnfidoDataController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/onfido_datas`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get a list of taxes
     * Get a list of taxes
     * operationId: TaxController_findAll
     * Request URI: /api/taxes
     */
    TaxController_findAll: (
      params: Params$TaxController_findAll,
      option?: RequestOption
    ): Promise<
      Response$TaxController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/taxes`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a tax by its id
     * Get a tax by its id
     * operationId: TaxController_findOneById
     * Request URI: /api/taxes/{id}
     */
    TaxController_findOneById: (
      params: Params$TaxController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$TaxController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/taxes/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Update a tax by its ID
     * Update a tax by its ID
     * operationId: TaxController_updateOneById
     * Request URI: /api/taxes/{id}
     */
    TaxController_updateOneById: (
      params: Params$TaxController_updateOneById,
      option?: RequestOption
    ): Promise<
      Response$TaxController_updateOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/taxes/${params.parameter.id}`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Tax mark paid
     * Tax mark paid
     * operationId: TaxController_markPaid
     * Request URI: /api/taxes/{id}/paid
     */
    TaxController_markPaid: (
      params: Params$TaxController_markPaid,
      option?: RequestOption
    ): Promise<
      Response$TaxController_markPaid$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/taxes/${params.parameter.id}/paid`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a company_info by its id
     * Get a company_info by its id
     * operationId: CompanyInfoController_findOneByVatId
     * Request URI: /api/company_info
     */
    CompanyInfoController_findOneByVatId: (
      params: Params$CompanyInfoController_findOneByVatId,
      option?: RequestOption
    ): Promise<
      Response$CompanyInfoController_findOneByVatId$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/company_info`;
      const headers = {
        Accept: 'application/json',
      };
      const queryParameters: QueryParameters = {
        vatId: { value: params.parameter.vatId, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a list of rules
     * Get a list of rules
     * operationId: RuleController_findAll
     * Request URI: /api/rules
     */
    RuleController_findAll: (
      params: Params$RuleController_findAll,
      option?: RequestOption
    ): Promise<
      Response$RuleController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/rules`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        onlyArchived: { value: params.parameter.onlyArchived, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Create a new rule
     * Create a new rule
     * operationId: RuleController_create
     * Request URI: /api/rules
     */
    RuleController_create: (
      params: Params$RuleController_create,
      option?: RequestOption
    ): Promise<
      Response$RuleController_create$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/rules`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Remove a rule by its id
     * Remove a rule by its id
     * operationId: RuleController_delete
     * Request URI: /api/rules/{id}
     */
    RuleController_delete: (
      params: Params$RuleController_delete,
      option?: RequestOption
    ): Promise<
      Response$RuleController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/rules/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Restore archived rule by its id
     * Restore archived rule by its id
     * operationId: RuleController_restore
     * Request URI: /api/rules/{id}
     */
    RuleController_restore: (
      params: Params$RuleController_restore,
      option?: RequestOption
    ): Promise<
      Response$RuleController_restore$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/rules/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'PATCH',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of ingests
     * Get a list of ingests
     * operationId: TransactionIngestController_findAll
     * Request URI: /api/transaction_ingests
     */
    TransactionIngestController_findAll: (
      params: Params$TransactionIngestController_findAll,
      option?: RequestOption
    ): Promise<
      Response$TransactionIngestController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/transaction_ingests`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Delete aningest
     * Delete an ingest
     * operationId: TransactionIngestController_delete
     * Request URI: /api/transaction_ingests/{id}
     */
    TransactionIngestController_delete: (
      params: Params$TransactionIngestController_delete,
      option?: RequestOption
    ): Promise<
      Response$TransactionIngestController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/transaction_ingests/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Initialize stripe connnection
     * operationId: PaymentMethodController_intialize
     * Request URI: /api/payment_methods/intialize
     */
    PaymentMethodController_intialize: (
      params: Params$PaymentMethodController_intialize,
      option?: RequestOption
    ): Promise<
      Response$PaymentMethodController_intialize$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/payment_methods/intialize`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Finalize stripe connnection and get payment method
     * operationId: PaymentMethodController_finalize
     * Request URI: /api/payment_methods/finalize
     */
    PaymentMethodController_finalize: (
      params: Params$PaymentMethodController_finalize,
      option?: RequestOption
    ): Promise<
      Response$PaymentMethodController_finalize$Status$201['application/json']
    > => {
      const url = _baseUrl + `/api/payment_methods/finalize`;
      const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'POST',
          url,
          headers,
          requestBody: params.requestBody,
        },
        option
      );
    },
    /**
     * Get current payment method
     * operationId: PaymentMethodController_findOne
     * Request URI: /api/payment_methods/current
     */
    PaymentMethodController_findOne: (
      params: Params$PaymentMethodController_findOne,
      option?: RequestOption
    ): Promise<
      Response$PaymentMethodController_findOne$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/payment_methods/current`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Remove current payment method
     * operationId: PaymentMethodController_delete
     * Request URI: /api/payment_methods/current
     */
    PaymentMethodController_delete: (
      params: Params$PaymentMethodController_delete,
      option?: RequestOption
    ): Promise<
      Response$PaymentMethodController_delete$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/payment_methods/current`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'DELETE',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Retrieve a list of payslip documents
     * operationId: PayslipDocumentController_findAll
     * Request URI: /api/payslip_documents
     */
    PayslipDocumentController_findAll: (
      params: Params$PayslipDocumentController_findAll,
      option?: RequestOption
    ): Promise<
      Response$PayslipDocumentController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/payslip_documents`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        perPage: { value: params.parameter.perPage, explode: false },
        issueDate: { value: params.parameter.issueDate, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Download archive of all filtered documents
     * operationId: PayslipDocumentController_downloadAll
     * Request URI: /api/payslip_documents/download-all
     */
    PayslipDocumentController_downloadAll: (
      params: Params$PayslipDocumentController_downloadAll,
      option?: RequestOption
    ): Promise<void> => {
      const url = _baseUrl + `/api/payslip_documents/download-all`;
      const headers = {
        companyId: params.parameter.companyId,
      };
      const queryParameters: QueryParameters = {
        page: { value: params.parameter.page, explode: false },
        perPage: { value: params.parameter.perPage, explode: false },
        issueDate: { value: params.parameter.issueDate, explode: false },
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
          queryParameters: queryParameters,
        },
        option
      );
    },
    /**
     * Get a payslip document by its id
     * operationId: PayslipDocumentController_findOneById
     * Request URI: /api/payslip_documents/{id}
     */
    PayslipDocumentController_findOneById: (
      params: Params$PayslipDocumentController_findOneById,
      option?: RequestOption
    ): Promise<
      Response$PayslipDocumentController_findOneById$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/payslip_documents/${params.parameter.id}`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of periods of all payslip documents
     * operationId: PayslipDocumentController_findPeriodsAll
     * Request URI: /api/payslip_documents/periods/all
     */
    PayslipDocumentController_findPeriodsAll: (
      params: Params$PayslipDocumentController_findPeriodsAll,
      option?: RequestOption
    ): Promise<
      Response$PayslipDocumentController_findPeriodsAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/payslip_documents/periods/all`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of billing_periods
     * Get a list of billing_periods
     * operationId: BillingPeriodController_findAll
     * Request URI: /api/billing/billing_periods
     */
    BillingPeriodController_findAll: (
      params: Params$BillingPeriodController_findAll,
      option?: RequestOption
    ): Promise<
      Response$BillingPeriodController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/billing/billing_periods`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Current billing period
     * operationId: BillingPeriodController_current
     * Request URI: /api/billing/billing_periods/current
     */
    BillingPeriodController_current: (
      params: Params$BillingPeriodController_current,
      option?: RequestOption
    ): Promise<
      Response$BillingPeriodController_current$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/billing/billing_periods/current`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
    /**
     * Get a list of invoices
     * Get a list of invoices
     * operationId: InvoiceController_findAll
     * Request URI: /api/billing/invoices
     */
    InvoiceController_findAll: (
      params: Params$InvoiceController_findAll,
      option?: RequestOption
    ): Promise<
      Response$InvoiceController_findAll$Status$200['application/json']
    > => {
      const url = _baseUrl + `/api/billing/invoices`;
      const headers = {
        Accept: 'application/json',
        companyId: params.parameter.companyId,
      };
      return apiClient.request(
        {
          httpMethod: 'GET',
          url,
          headers,
        },
        option
      );
    },
  };
};
type ClientFunction<RequestOption> = typeof createClient<RequestOption>;
export type Client<RequestOption> = ReturnType<ClientFunction<RequestOption>>;
