import { Flex } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { usePayslipDocumentController_findAll } from '@api-client/generated/PayslipDocumentController/findAll';
import { usePayslipDocumentController_findOneById } from '@api-client/generated/PayslipDocumentController/findOneById';
import { usePayslipDocumentController_findPeriodsAll } from '@api-client/generated/PayslipDocumentController/findPeriodsAll';
import { Schemas } from '@api-client/generated/types';
import { IconDownload } from '@assets';
import { Scrollbar } from '@components';
import {
  FilterConstructor,
  PageMeta,
  PayslipsCard,
  PayslipsEmpty,
  PayslipsList,
} from '@entities';
import { useAccount } from '@hooks';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';
import { Button } from '@ui-kit/Button/Button';
import { downloadFileBlob, getSearchParams } from '@utils';

import * as S from './styled';

type PayslipDocument = Schemas.PayslipDocument;
type FilterOptions = Record<string, unknown>;

const Payslips: FC = () => {
  const { search, pathname } = useLocation();
  const { id: payslipId } = useParams();
  const navigate = useNavigate();

  const { companyId } = useAccount();

  const searchParams = getSearchParams<FilterOptions>(search);

  const [filterOptions, setFilterOptions] =
    useState<FilterOptions>(searchParams);

  const { metadata, incrementPage, hasNextPage, plainData, appendData } =
    usePagination<PayslipDocument>(JSON.stringify(filterOptions));

  const { isFetching: isLoadingPeriods, data: periods = [] } =
    usePayslipDocumentController_findPeriodsAll({
      params: {
        companyId: companyId!,
      },
      config: {
        refetchOnWindowFocus: false,
      },
    });

  const { isFetching: isLoadingDocuments, data: documents } =
    usePayslipDocumentController_findAll({
      params: {
        companyId: companyId!,
        page: metadata.currentPage,
        ...filterOptions,
      },
      config: {
        refetchOnWindowFocus: false,
      },
    });

  const {
    data: selectedDocument,
    isFetching: isLoadingDocument,
    refetch: refetchById,
  } = usePayslipDocumentController_findOneById({
    params: {
      companyId: companyId!,
      id: payslipId!,
    },
    config: {
      enabled: !!payslipId,
      refetchOnWindowFocus: false,
    },
  });

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading: isLoadingDocuments,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  useEffect(() => {
    if (documents) {
      appendData(documents);

      if (!documents.records.length) {
        navigate('/payslips');
      }
    }
  }, [appendData, documents, navigate]);

  useEffect(() => {
    if (selectedDocument && search) {
      navigate(`${pathname.replace(selectedDocument.id, '')}${search}`);
    }
  }, [selectedDocument, search, pathname, navigate]);

  useEffect(() => {
    if (payslipId) {
      refetchById();
    }
  }, [payslipId, refetchById]);

  if (!documents?.metadata.totalRecords && !isLoadingDocuments) {
    return <PayslipsEmpty />;
  }

  return (
    <Flex gap={24} justify="center">
      <PageMeta title={t('payslips.title')()} />

      <S.Container align="flex-start" gap={30}>
        <S.List>
          <Flex gap={24} vertical>
            <S.Header align="center" justify="space-between">
              <S.Title>{t('payslips.title')()}</S.Title>
            </S.Header>

            <FilterConstructor<PayslipDocument>
              controls={{
                left: [
                  {
                    type: 'select',
                    formName: 'issueDate',
                    params: {
                      defaultValue: null,
                      placeholder: t('payslips.filter.issueDate.placeholder')(),
                      options: [
                        { label: t('common.dropdown.allData')(), value: null },
                        ...periods.map((period) => ({
                          label: dayjs(period).format('MMMM YYYY'),
                          value: period,
                        })),
                      ],
                      style: {
                        width: '174px',
                      },
                      loading: isLoadingPeriods,
                    },
                  },
                  {
                    type: 'custom-component',
                    component: (
                      <Button
                        variant="link"
                        icon={<IconDownload />}
                        size="small"
                        onClick={() =>
                          downloadFileBlob({
                            method: `payslip_documents/download-all${search}`,
                            name: 'payslip-documents.zip',
                            companyId: companyId!,
                          })
                        }
                      >
                        {t('payslips.buttonDownloadAll')()}
                      </Button>
                    ),
                  },
                ],
              }}
              onChange={setFilterOptions}
              withSearchParams
            />

            <Scrollbar
              height="calc(100vh - 310px)"
              ref={rootRef}
              withoutPadding
            >
              <PayslipsList
                selectedId={payslipId!}
                dataSource={plainData}
                isLoading={isLoadingDocuments}
              />

              {hasNextPage && <div ref={sentryRef} />}
            </Scrollbar>
          </Flex>
        </S.List>

        <PayslipsCard
          selectedDocument={selectedDocument}
          isLoading={isLoadingDocument}
        />
      </S.Container>
    </Flex>
  );
};

export default Payslips;
