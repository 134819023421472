import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Scrollbar } from '@components';
import { TransactionsTable } from '@entities';
import TransactionsTableFilterForm from '@entities/transactions/TransactionsTable/TransactionsTableFilterForm';

import * as S from './styled';

const ContactsTransactions: FC = () => {
  const { id: contactId } = useParams();
  const navigate = useNavigate();

  return (
    <Scrollbar height="calc(100vh - 385px)">
      <S.Container>
        <TransactionsTable
          hiddenColumns={['contact']}
          onAfterModalClose={() => navigate(`/contacts/${contactId}`)}
          // TODO: get rid of double
          queryParams={{
            contactIds: [contactId!]
          }}
          filterForm={({onFormUpdate, total}) => (
            <TransactionsTableFilterForm
              total={total}
              isDarkTheme
              hiddenFormFields={new Set(['contactIds'])}
              onFormUpdate={onFormUpdate}
              queryParams={{
                contactIds: [contactId!]
              }}
            />
          )}
        />
      </S.Container>
    </Scrollbar>
  );
};

export default ContactsTransactions;