import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconEyeOff } from '@assets';
import { FileViewer } from '@entities';

import * as S from './styled';

type Document = Schemas.Document;
type TaskFile = Schemas.TaskFile;
type CompanyFile = Schemas.CompanyFile;
type PayslipDocument = Schemas.PayslipDocument;

type FilePreviewProps = {
  file?: Document | TaskFile | CompanyFile | PayslipDocument;
  pageWidth?: number;
  width?: string;
  height?: string;
  withoutContainer?: boolean;
};

const FilePreview: FC<FilePreviewProps> = ({
  file,
  pageWidth,
  width,
  height,
  withoutContainer = false,
}) => {
  const isTypeImage = file?.mimeType.includes('image');
  const isTypePDF = file?.mimeType.includes('pdf');

  if (!file) {
    return withoutContainer ? (
      <S.PreviewContent height={height} withoutContainer={withoutContainer} />
    ) : (
      <S.Preview>
        <S.PreviewContent height={height} withoutContainer={withoutContainer} />
      </S.Preview>
    );
  }

  const previewContent = (
    <S.PreviewContent height={height} withoutContainer={withoutContainer}>
      {isTypePDF && (
        <FileViewer
          selectedFile={file}
          pageWidth={pageWidth}
          width={width || '437px'}
          height={height || '622px'}
        />
      )}

      {isTypeImage && <img src={file?.url} />}

      {!isTypePDF && !isTypeImage && (
        <S.Empty>
          <S.EmptyIcon>
            <IconEyeOff width={32} height={32} />
          </S.EmptyIcon>

          <S.EmptyTitle>{t('common.preview.title')()}</S.EmptyTitle>

          <S.EmptyDescription>
            {t('common.preview.description')()}
          </S.EmptyDescription>
        </S.Empty>
      )}
    </S.PreviewContent>
  );

  return withoutContainer ? (
    previewContent
  ) : (
    <S.Preview>{previewContent}</S.Preview>
  );
};

export default FilePreview;
