import {
  Button,
  Col,
  Flex,
  Form,
  type ModalProps,
  Row,
  Tooltip,
  message,
} from 'antd';
import { FC, useEffect, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconInfo } from '@assets';
import { DeafSelect } from '@components';
import { SelectVatType } from '@entities';
import { countries } from '@utils/countries/countries';

import InvoiceFormCard from '../../form/InvoiceFormCard';
import InvoiceFormControl from '../../form/InvoiceFormControl';
import InvoiceModalWrapper from '../InvoiceModalWrapper';
import { getTranslatesVatType } from './helpers';
import * as S from './styled';

type CompanyDto = Schemas.CompanyDto;

type InvoiceModalFromDetailsProps = {
  name: string;
  details: Schemas.CompanyDetails;
  onSubmit: (values: CompanyDto) => void;
  onCancel: VoidFunction;
} & ModalProps;

const InvoiceModalFromDetails: FC<InvoiceModalFromDetailsProps> = ({
  open,
  name,
  details,
  onSubmit,
  onCancel,
}) => {
  const [form] = Form.useForm();

  const [isSelectTypeVisible, setIsSelectTypeVisible] = useState(false);
  const [selectedType, setSelectedType] = useState<string>();

  useEffect(() => {
    if (details) {
      form.setFieldsValue({ name, details });

      if (details.vatType) {
        setSelectedType(details.vatType);
      }
    } else {
      form.setFieldsValue({ name });
    }
  }, [form, name, details]);

  const handleSubmit = (values: CompanyDto) => {
    if (!selectedType) {
      return message.error(
        t('invoiceGenerator.data.fieldVatType.errorMessage')()
      );
    }

    onSubmit({
      ...values,
      details: {
        ...details,
        ...values.details,
        vatType: selectedType!,
      },
    } as CompanyDto);
  };

  return (
    <InvoiceModalWrapper
      open={open}
      title={
        <Flex gap={8} vertical>
          {t('invoiceGenerator.data.title')()}

          <S.Description>
            {t('invoiceGenerator.data.description')()}
          </S.Description>
        </Flex>
      }
      onCancel={onCancel}
    >
      <SelectVatType
        value={selectedType}
        open={isSelectTypeVisible}
        onCancel={() => setIsSelectTypeVisible(false)}
        onChange={setSelectedType}
      />

      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          address: {
            countryCode: 'LU',
          },
        }}
      >
        <InvoiceFormCard>
          <Row gutter={[12, 0]}>
            <InvoiceFormControl
              type="input"
              span={12}
              form={{
                label: t('invoiceGenerator.data.fieldFirstName.label')(),
                name: ['details', 'contactPerson', 'firstName'],
                rules: [{ required: true }],
              }}
            />

            <InvoiceFormControl
              type="input"
              span={12}
              form={{
                label: t('invoiceGenerator.data.fieldLastName.label')(),
                name: ['details', 'contactPerson', 'lastName'],
                rules: [{ required: true }],
              }}
            />
          </Row>

          <InvoiceFormControl
            type="input"
            form={{
              label: t('invoiceGenerator.data.fieldCompanyName.label')(),
              name: 'name',
              rules: [{ required: true }],
            }}
          />
        </InvoiceFormCard>

        <InvoiceFormCard>
          <Row gutter={[12, 0]}>
            <InvoiceFormControl
              type="input"
              span={12}
              form={{
                label: t('invoiceGenerator.data.fieldVatId.label')(),
                name: ['details', 'vatId'],
                rules: [{ required: true }],
              }}
              control={{
                placeholder: t(
                  'invoiceGenerator.data.fieldVatId.placeholder'
                )(),
              }}
            />

            <Col span={12}>
              <Form.Item
                label={t('invoiceGenerator.data.fieldVatType.label')()}
              >
                <DeafSelect
                  placeholder={t(
                    'invoiceGenerator.data.fieldVatType.placeholder'
                  )()}
                  size="large"
                  value={
                    selectedType && getTranslatesVatType(selectedType).title
                  }
                  onClick={() => setIsSelectTypeVisible(true)}
                />
              </Form.Item>
            </Col>

            <InvoiceFormControl
              type="input"
              span={8}
              form={{
                label: (
                  <Flex align="center" gap={6}>
                    {t('invoiceGenerator.data.fieldTaxNumber.label')()}

                    <Tooltip
                      title={t('invoiceGenerator.data.fieldTaxNumber.info')()}
                      placement="bottom"
                    >
                      <IconInfo />
                    </Tooltip>
                  </Flex>
                ),
                name: ['details', 'taxNumber'],
                rules: [
                  {
                    required: true,
                    message: t(
                      'invoiceGenerator.data.fieldTaxNumber.errorMessage'
                    )(),
                  },
                ],
              }}
              control={{
                placeholder: t(
                  'invoiceGenerator.data.fieldTaxNumber.placeholder'
                )(),
              }}
            />

            <InvoiceFormControl
              type="input"
              span={8}
              form={{
                label: t('invoiceGenerator.data.fieldPermitNumber.label')(),
                name: ['details', 'businessLicenseNumber'],
                rules: [{ required: true }],
              }}
              control={{
                placeholder: t(
                  'invoiceGenerator.data.fieldPermitNumber.placeholder'
                )(),
              }}
            />

            <InvoiceFormControl
              type="input"
              span={8}
              form={{
                label: (
                  <Flex align="center" gap={6}>
                    {t('invoiceGenerator.data.fieldRegistrationNumber.label')()}

                    <Tooltip
                      title={t(
                        'invoiceGenerator.data.fieldRegistrationNumber.info'
                      )()}
                      placement="bottom"
                    >
                      <IconInfo />
                    </Tooltip>
                  </Flex>
                ),
                name: ['details', 'rscNumber'],
                rules: [
                  {
                    required: true,
                    message: t(
                      'invoiceGenerator.data.fieldRegistrationNumber.errorMessage'
                    )(),
                  },
                ],
              }}
              control={{
                placeholder: t(
                  'invoiceGenerator.data.fieldRegistrationNumber.placeholder'
                )(),
              }}
            />
          </Row>
        </InvoiceFormCard>

        <InvoiceFormCard>
          <Row gutter={[12, 0]}>
            <InvoiceFormControl
              type="input"
              span={10}
              form={{
                label: t('invoiceGenerator.data.fieldStreet.label')(),
                name: ['details', 'address', 'street'],
                rules: [{ required: true }],
              }}
            />

            <InvoiceFormControl
              type="input"
              span={7}
              form={{
                label: t('invoiceGenerator.data.fieldNumber.label')(),
                name: ['details', 'address', 'number'],
                rules: [{ required: true }],
              }}
            />

            <InvoiceFormControl
              type="input"
              span={7}
              form={{
                label: t('invoiceGenerator.data.fieldPostalCode.label')(),
                name: ['details', 'address', 'postalCode'],
                rules: [{ required: true }],
              }}
            />

            <InvoiceFormControl
              type="select"
              span={10}
              form={{
                label: t('invoiceGenerator.data.fieldCountry.label')(),
                name: ['details', 'address', 'countryCode'],
                rules: [{ required: true }],
              }}
              control={{
                options: countries,
              }}
            />

            <InvoiceFormControl
              type="input"
              span={14}
              form={{
                label: t('invoiceGenerator.data.fieldCity.label')(),
                name: ['details', 'address', 'city'],
                rules: [{ required: true }],
              }}
            />
          </Row>
        </InvoiceFormCard>

        <S.Submit>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={onCancel}>
                {t('invoiceGenerator.buttonCancel')()}
              </Button>

              <Button type="primary" htmlType="submit">
                {t('invoiceGenerator.buttonSave')()}
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      </Form>
    </InvoiceModalWrapper>
  );
};

export default InvoiceModalFromDetails;
