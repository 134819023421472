import styled from '@emotion/styled';

export const Title = styled.div`
  font-size: 24px;
`;

export const Description = styled.div`
  font-size: 16px;
`;

export const Submit = styled.div`
  margin-top: 24px;
`;
