import { Button, Popover, type PopoverProps } from 'antd';
import { FC, useId, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Schemas } from '@api-client/generated/types';
import { Scrollbar } from '@components';
import { DEFAULT_TIMEOUT_FOR_SEARCH } from '@constants';
import { useModalManager } from '@context/ModalManager/useModalManager';
import { ContactAction, ContactsSimpleList, SearchControl } from '@entities';
import { usePaginatedContactsList } from '@hooks';

import * as S from './styled';

type Contact = Schemas.Contact;

type ContactsPopupListProps = {
  width?: number;
  selectedId?: string | null;
  onSelect: (contact: Contact) => void;
} & PopoverProps;

const ContactsPopupList: FC<ContactsPopupListProps> = ({
  width,
  children,
  selectedId,
  onSelect,
  ...rest
}) => {
  const contactCreate = useId();

  const [open, setOpen] = useState(false);
  const modalManager = useModalManager();

  const isContactCreateVisible = modalManager.isOpen(contactCreate);

  const [searchValue, setSearchValue] = useState('');

  const { rootRef, plainData, isFetching, hasNextPage, sentryRef } =
    usePaginatedContactsList({ term: searchValue, enabled: open || rest.open });

  const handleSearch = useDebouncedCallback((value) => {
    setSearchValue(value);
  }, DEFAULT_TIMEOUT_FOR_SEARCH);

  const handleAddNewContact = () => {
    modalManager.replace(contactCreate);
    setOpen(false);
    rest.onOpenChange?.(false);
    setSearchValue('');
  };

  const handleSelectContact = (contact: Contact) => {
    onSelect(contact);

    setOpen(false);
    setSearchValue('');
  };

  const handleChangePopup = (status: boolean) => {
    setOpen(status);

    if (!status) {
      setSearchValue('');
    }
  };

  return (
    <>
      <ContactAction
        open={isContactCreateVisible}
        onCancel={() => modalManager.pop(contactCreate)}
        onAfterAction={handleSelectContact}
      />

      <Popover
        open={open}
        content={
          <S.Content width={width} gap={4} vertical>
            <S.Inner>
              <SearchControl
                onChange={(e) => handleSearch(e.target.value)}
                fitWidth
              />
            </S.Inner>

            <S.Contacts>
              <Scrollbar ref={rootRef} height={276} withoutPadding>
                <ContactsSimpleList
                  selectedId={selectedId}
                  dataSource={plainData}
                  loading={isFetching}
                  onSelect={handleSelectContact}
                />

                {hasNextPage && <div ref={sentryRef} />}
              </Scrollbar>
            </S.Contacts>

            <S.Inner>
              <Button onClick={handleAddNewContact} size="small" block>
                {t('contactsPage.popupContacts.buttonAdd')()}
              </Button>
            </S.Inner>
          </S.Content>
        }
        trigger="click"
        placement="bottom"
        arrow={false}
        onOpenChange={handleChangePopup}
        {...rest}
      >
        {children}
      </Popover>
    </>
  );
};

export default ContactsPopupList;
