import { FC, ReactNode } from 'react';

import * as S from './styled';

type PaymentDocumentInvoiceInfoProps = {
  title: string;
  description: string;
  content?: ReactNode;
};

const PaymentDocumentInvoiceInfo: FC<PaymentDocumentInvoiceInfoProps> = ({
  title,
  description,
  content,
}) => (
  <S.Container align="center" justify="center" gap={6} vertical>
    <S.Title>{title}</S.Title>
    <S.Description>{description}</S.Description>
    {content}
  </S.Container>
);

export default PaymentDocumentInvoiceInfo;
