import { Flex, type FlexProps } from 'antd';

import styled from '@emotion/styled';

export const Container = styled(Flex)<FlexProps>`
  width: 342px;
  height: 100%;
  padding: 0 16px;
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const Description = styled.div`
  max-width: 80%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text400};
  margin-bottom: 8px;
  text-align: center;
`;
