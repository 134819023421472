export const blue = {
  blue200: '#e5ebff',
  blue300: '#0157ff',
  blue400: '#42619d',
  blue500: '#022c7e',
}

export const grey = {
  darkgrey: '#e2e7ee',
  grey100: '#f2f5f9',
  grey200: '#d0def1',
  grey300: '#b3bfd0',
  grey400: '#819bc0',
  grey500: '#3f577a',
}

export const green = {
  green300: '#d3ffda',
  green400: '#00bb40',
  green500: '#098d36',
}

export const yellow = {
  yellow200: '#fffbed',
  yellow300: '#fff3cc',
  yellow400: '#ffe48a',
  yellow500: '#d48c00',
}

export const red = {
  red200: '#ffe9e9',
  red300: '#ffd5d5',
  red400: '#ffb2b2',
  red500: '#ec1717',
  red600: '#a60606',
}

export const colorScheme = {
  black: '#081d46',
  white: '#fdfdfd',
  ...blue,
  ...grey,
  ...green,
  ...yellow,
  ...red
};
