import { useEffect, useState } from "react";

interface UseStorageOptions {
	storage: Storage;
}

const defaultOptions: UseStorageOptions = {
	storage: window.localStorage
}

export function useStorage<T>(key: string, defaultValue: T, options = defaultOptions) {
	const [value, setValue] = useState(() => {
		let currentValue: T;

		try {
			const fromStorage = options.storage.getItem(key);
			currentValue = fromStorage ? JSON.parse(fromStorage) : defaultValue;
		} catch (err) {
			console.warn(err);
			currentValue = defaultValue;
		}

		return currentValue;
	});

	useEffect(() => {
		options.storage.setItem(key, JSON.stringify(value))
	}, [key, value]) // eslint-disable-line

	return [value, setValue] as const;
}