import PhoneInput, { type PhoneInputProps } from 'antd-phone-input';

import styled from '@emotion/styled';

export const InputPhoneNumber = styled(PhoneInput)<PhoneInputProps>`
  .ui-select {
    min-width: auto;
  }

  .ui-input-group-addon {
    background-color: ${({ theme }) => theme.colors.white};
  }
`;
