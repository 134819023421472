import { PAYMENT_DOCUMENT_VALID_EXTENSIONS } from "@constants";
import { message, Upload } from "antd";

export const checkDocumentFormatBeforeUpload = (file: File, documentType: 'income' | 'expenses') => {
    const extension = `.${file.name.split('.').pop()}`.toLowerCase();

    if (!PAYMENT_DOCUMENT_VALID_EXTENSIONS.includes(extension)) {
        const translateParams = {
            formats: PAYMENT_DOCUMENT_VALID_EXTENSIONS.join(', '),
            extension,
        };

        const errorKey = documentType === 'income'
            ? 'income.errorExtensionsUpload'
            : 'expenses.errorExtensionsUpload';

        message.error(t(errorKey, translateParams)());

        return Upload.LIST_IGNORE;
    }

    return true;
};
