import { Dropdown, Modal, Table, TableProps, message } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { Schemas } from '@api-client/generated/types';
import { IconMoreVertical } from '@assets';
import { Card } from '@components';
import { TeamMembersAccessModal } from '@entities';
import { useAccount } from '@hooks';
import {
  useCreateCompanyInvite,
  useDeleteCompanyInvite,
  useGetCompanyInvitesList,
  useGetUsersList,
  useRemoveUserFromCompanyById,
  useUpdateCompanyInvite,
  useUpdateUserPermissions,
} from '@hooks-api';
import { colors } from '@theme';
import { GTMEventName, sendGTMEvent } from '@utils';

import * as S from './styled';

type User = Schemas.User;
type CompanyInvite = Schemas.CompanyInvite;

type TeamMembersProps = {
  isAccessModalOpen: boolean;
  onOpenAccessModal: (value: boolean) => void;
};

const isUser = (obj: User | CompanyInvite): obj is User =>
  (obj as Schemas.User).fullName !== undefined;

const TeamMembers: FC<TeamMembersProps> = ({
  onOpenAccessModal,
  isAccessModalOpen,
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const { companyId, account } = useAccount();

  const { data: users, isPending: usersLoading } = useGetUsersList({
    params: {
      companyId: companyId!,
    },
  });

  const { data: invites, isPending: invitesLoading } = useGetCompanyInvitesList(
    {
      params: {
        companyId: companyId!,
      },
    }
  );

  const [usersList, setUsersList] = useState<User[]>([]);
  const [invitesList, setInvitesList] = useState<CompanyInvite[]>([]);
  const [editableEntity, setEditableEntity] = useState<
    User | CompanyInvite | null
  >(null);

  const { mutate: removeUserFromCompany, isPending: removeUserLoading } =
    useRemoveUserFromCompanyById();
  const { mutate: updateUserPermissions, isPending: updateUserLoading } =
    useUpdateUserPermissions();
  const { mutate: updateUserInvite } = useUpdateCompanyInvite();
  const { mutate: inviteUser, isPending: inviteUserLoading } =
    useCreateCompanyInvite();
  const { mutate: deleteInvite } = useDeleteCompanyInvite();

  useEffect(() => {
    if (users) {
      setUsersList(users);
    }
    if (invites) {
      setInvitesList(invites);
    }
  }, [users, invites]);

  const columns: TableProps<User | CompanyInvite>['columns'] = [
    {
      key: 'fullName',
      dataIndex: 'fullName',
      title: (
        <S.ColumnTitle isname="true">
          {t('settings.teamMembers.users.name')()}
        </S.ColumnTitle>
      ),
      render: (text, record) => (
        <S.UserName isinvite={text ? text?.toString() : ''} gap={2} vertical>
          <S.Name>{text || record.email}</S.Name>
          <S.Email>
            {text ? record?.email : t('settings.teamMembers.pending')()}
          </S.Email>
        </S.UserName>
      ),
    },
    {
      key: 'transactions',
      dataIndex: 'transactions',
      title: (
        <S.ColumnTitle isbordered="true">
          {t('settings.teamMembers.users.transactions')()}
        </S.ColumnTitle>
      ),
      render: (_, record) => (
        <S.CheckWrapper>
          <S.Check isdone={record?.accessRights?.transactions ? 'true' : ''} />
        </S.CheckWrapper>
      ),
    },
    {
      key: 'companyData',
      dataIndex: 'companyData',
      title: (
        <S.ColumnTitle isbordered="true">
          {t('settings.teamMembers.users.companyData')()}
        </S.ColumnTitle>
      ),
      render: (_, record) => (
        <S.CheckWrapper>
          <S.Check isdone={record?.accessRights?.companyData ? 'true' : ''} />
        </S.CheckWrapper>
      ),
    },
    {
      key: 'userManagement',
      dataIndex: 'userManagement',
      title: (
        <S.ColumnTitle isbordered="true">
          {t('settings.teamMembers.users.userManagement')()}
        </S.ColumnTitle>
      ),
      render: (_, record) => (
        <S.CheckWrapper>
          <S.Check
            isdone={record?.accessRights?.userManagement ? 'true' : ''}
          />
        </S.CheckWrapper>
      ),
    },
    {
      key: 'reports',
      dataIndex: 'reports',
      title: (
        <S.ColumnTitle isbordered="true">
          {t('settings.teamMembers.users.reports')()}
        </S.ColumnTitle>
      ),
      render: (_, record) => (
        <S.CheckWrapper>
          <S.Check isdone={record?.accessRights?.reports ? 'true' : ''} />
        </S.CheckWrapper>
      ),
    },
    {
      key: 'payroll',
      dataIndex: 'payroll',
      title: (
        <S.ColumnTitle isbordered="true">
          {t('settings.teamMembers.users.payroll')()}
        </S.ColumnTitle>
      ),
      render: (_, record) => (
        <S.CheckWrapper>
          <S.Check isdone={record?.accessRights?.payroll ? 'true' : ''} />
        </S.CheckWrapper>
      ),
    },
    {
      key: 'incomesExpences',
      dataIndex: 'incomesExpences',
      title: (
        <S.ColumnTitle isbordered="true">
          {t('settings.teamMembers.users.incomesExpences')()}
        </S.ColumnTitle>
      ),
      render: (_, record) => (
        <S.CheckWrapper>
          <S.Check
            isdone={record?.accessRights?.incomesExpences ? 'true' : ''}
          />
        </S.CheckWrapper>
      ),
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      title: '',
      render: (_, record) => {
        if (account?.id !== record.id) {
          return (
            <Dropdown
              data-testid={`dropdown-${record.email}`}
              menu={{
                items: [
                  {
                    key: 'edit',
                    onClick: () => {
                      setEditableEntity(record);
                      onOpenAccessModal(true);
                    },
                    label: (
                      <S.DropdownItem data-testid="edit-button">
                        {t('chat.actions.edit')()}
                      </S.DropdownItem>
                    ),
                  },
                  {
                    key: 'remove',
                    label: (
                      <S.DropdownItem
                        onClick={() => {
                          handleDeleteEntity(record);
                        }}
                      >
                        {t('chat.actions.remove')()}
                      </S.DropdownItem>
                    ),
                  },
                ],
              }}
              placement="bottomRight"
              trigger={['click']}
            >
              <S.More
                data-testid={
                  record.email !== account?.email
                    ? `dropdown-${record.email}`
                    : ''
                }
              >
                <IconMoreVertical />
              </S.More>
            </Dropdown>
          );
        }
      },
    },
  ];

  const handleDeleteEntity = (entity: User | CompanyInvite) => {
    const deleteFuntion = isUser(entity) ? removeUserFromCompany : deleteInvite;

    const onSuccess = () => {
      if (isUser(entity)) {
        setUsersList((prev) => prev.filter((user) => user.id !== entity.id));
      } else {
        setInvitesList((prev) =>
          prev.filter((invite) => invite.id !== entity.id)
        );
      }
      setEditableEntity(null);
      onOpenAccessModal(false);
      message.success(t('settings.teamMembers.users.deletedUserMessage')());
    };

    modal.confirm({
      title: (
        <Trans
          i18nKey={t('settings.teamMembers.modal.removeUserTitle', {
            name: isUser(entity) ? entity.fullName : entity.email,
          })()}
          components={[<S.Boldest />]}
        />
      ),
      content: t('settings.teamMembers.modal.removeUserDescription')(),
      icon: null,
      okText: t('settings.teamMembers.modal.deleteButton')(),
      okButtonProps: { style: { background: colors.error } },
      width: 504,
      onOk: () => {
        deleteFuntion(
          {
            parameter: {
              companyId: companyId!,
              id: entity.id,
            },
          },
          { onSuccess }
        );
      },
      autoFocusButton: null,
    });
  };

  const handleSubmit = (
    values: Schemas.AccessRights | Schemas.CompanyInviteDto
  ) => {
    if (editableEntity) {
      const onSuccess = () => {
        setEditableEntity(null);
        onOpenAccessModal(false);

        if (isUser(editableEntity)) {
          setUsersList((prev) =>
            prev.map((entity: User) =>
              entity.id === editableEntity?.id
                ? {
                    ...entity,
                    accessRights: values as Schemas.AccessRights,
                  }
                : entity
            )
          );
        } else {
          setInvitesList((prev) =>
            prev.map((entity: CompanyInvite) =>
              entity.id === editableEntity?.id
                ? {
                    ...entity,
                    accessRights: values as Schemas.AccessRights,
                  }
                : entity
            )
          );
        }
      };

      const updateFunction = isUser(editableEntity)
        ? updateUserPermissions
        : updateUserInvite;
      updateFunction(
        {
          parameter: {
            companyId: companyId!,
            id: editableEntity?.id,
          },
          requestBody: {
            accessRights: values as Schemas.AccessRights,
          },
        },
        {
          onSuccess,
        }
      );
    } else {
      inviteUser(
        {
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            email: (values as Schemas.CompanyInviteDto).email,
            accessRights: (values as Schemas.CompanyInviteDto).accessRights,
          },
        },
        {
          onSuccess: (res) => {
            sendGTMEvent(GTMEventName.InvitedUser);

            setInvitesList((prev) => [...prev, res]);
            onOpenAccessModal(false);
          },
        }
      );
    }
  };

  return (
    <Card>
      {contextHolder}

      <S.Users>
        <Table
          rowKey={({ id }) => id}
          dataSource={[...usersList, ...invitesList]}
          columns={columns}
          pagination={false}
          loading={
            usersLoading ||
            invitesLoading ||
            removeUserLoading ||
            updateUserLoading ||
            inviteUserLoading
          }
        />
      </S.Users>

      {isAccessModalOpen && (
        <TeamMembersAccessModal
          open={isAccessModalOpen}
          editableEntity={editableEntity}
          onChangeEditableEntity={setEditableEntity}
          onDeleteEditableEntity={handleDeleteEntity}
          onSubmit={handleSubmit}
          onClose={() => onOpenAccessModal(false)}
        />
      )}
    </Card>
  );
};

export default TeamMembers;
