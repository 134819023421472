import { type PhoneInputProps, type PhoneNumber } from 'antd-phone-input';
import { FC } from 'react';

import * as S from './styled';

type InputPhoneNumberProps = {
  onChange?: (value: string, phoneNumber: PhoneNumber) => void;
} & Omit<PhoneInputProps, 'onChange'>;

const InputPhoneNumber: FC<InputPhoneNumberProps> = ({ onChange, ...rest }) => (
  <S.InputPhoneNumber
    size="large"
    searchPlaceholder={t('common.searchInput.placeholder')()}
    inputMode="tel"
    enableArrow
    enableSearch
    {...rest}
    onChange={(phoneNumber) =>
      onChange?.(
        `+${phoneNumber.countryCode}${phoneNumber.areaCode}${phoneNumber.phoneNumber}`,
        phoneNumber
      )
    }
  />
);

export default InputPhoneNumber;
